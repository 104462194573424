export const openAccountDataLayer = (
    user_id: number,
    account_id: string,
    mode: "live" | "demo",
) => {
    if (typeof window === "undefined") { return; }

    window.dataLayer.push({
        event: "open_account",
        event_params: {
            account_id,
            mode,
        },
        user_properties: {
            user_id,
        },
    });
};
