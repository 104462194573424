import React, { PropsWithChildren, ReactNode } from "react";
import { TypographyTypes } from "./typography.types";
import {
    StyledH1,
    StyledH2,
    StyledH3,
    StyledH4,
    StyledH5,
    StyledH6,
    StyledHuge,
    StyledLarge,
    StyledBase,
    StyledSmall,
    StyledButton,
    StyledBannerTitle,
    StyledAccent,
    StyledDefault,
} from "./typography.styled";

export interface ITypographyProps extends PropsWithChildren {
    variant: TypographyTypes;
    className?: string;
}

export const Typography: React.FC<ITypographyProps> = ({
    variant,
    className,
    children,
}) => {
    const typography: Record<TypographyTypes, ReactNode> = {
        h1: <StyledH1 className={className}>{children}</StyledH1>,
        h2: <StyledH2 className={className}>{children}</StyledH2>,
        h3: <StyledH3 className={className}>{children}</StyledH3>,
        h4: <StyledH4 className={className}>{children}</StyledH4>,
        h5: <StyledH5 className={className}>{children}</StyledH5>,
        h6: <StyledH6 className={className}>{children}</StyledH6>,
        huge: <StyledHuge className={className}>{children}</StyledHuge>,
        large: <StyledLarge className={className}>{children}</StyledLarge>,
        base: <StyledBase className={className}>{children}</StyledBase>,
        small: <StyledSmall className={className}>{children}</StyledSmall>,
        button: <StyledButton className={className}>{children}</StyledButton>,
        bannerTitle: (
            <StyledBannerTitle className={className}>
                {children}
            </StyledBannerTitle>
        ),
        accent: <StyledAccent className={className}>{children}</StyledAccent>,
        default: (
            <StyledDefault className={className}>{children}</StyledDefault>
        ),
    };
    return <>{typography[variant]}</>;
};
