export enum TypographyTypes {
    "H1" = "h1",
    "H2" = "h2",
    "H3" = "h3",
    "H4" = "h4",
    "H5" = "h5",
    "H6" = "h6",
    "HUGE" = "huge",
    "LARGE" = "large",
    "BASE" = "base",
    "SMALL" = "small",
    "BUTTON" = "button",
    "BANNER_TITLE" = "bannerTitle",
    "ACCENT" = "accent",
    "DEFAULT" = "default",
}
