const locales = [
  "ar",
  "en",
  "es",
  "fr",
  "hi",
  "id",
  "ja",
  "ms",
  "pt",
  "th",
  "tl",
  "ur",
  "vi",
  "zh-Hans",
];

/** @type {import('next').NextConfig["i18n"]} */
const i18n = {
  defaultLocale: "en",
  locales,
};

/** @type {import('next-i18next').UserConfig} */
const i18next = {
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  // debug: process.env.NODE_ENV === 'development',
  // keySeparator: "#:::$",
  // nsSeparator: "#::::$",
  returnEmptyString: false,
  returnNull: false,
  ns: ["common", "questions", "contest", "banners", "benefits"],
  fallbackLng: {
      default: ["en"],
  },
};

module.exports = { i18n, ...i18next };
