import styled from "styled-components";
import { Icon } from "components/atoms/icon/icon.component";
import { spacing } from "helpers/conversion-style";
import { Typography } from "components/atoms/typography/typography.component";
import { Modals } from "../modals.component";

export const StyledAccountInfoModals = styled(Modals)`
    max-width: 550px;
    padding-bottom: ${spacing(16)};
`;

export const StyledContent = styled.div`
    padding: 0 ${spacing(16)} ${spacing(32)};
    text-align: center;
`;

export const StyledListInfo = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
`;

export const StyledText = styled(Typography)`
    margin-bottom: 24px;
`;

export const StyledTitle = styled(Typography)`
    margin-bottom: 8px;
    color: ${({ theme }) => theme.text?.muted};
`;

export const StyledCopyBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
`;

export const StyledButtonIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    stroke: ${({ theme }) => theme.text?.muted};
`;

export const StyledButtonReturn = styled.button`
    border: none;
    background: none;
    padding: ${spacing(12)} ${spacing(16)};
    width: 100%;
    display: grid;
    grid-template-columns: 24px 1fr;
    column-gap: 16px;
    text-align: left;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.text?.default};
`;
