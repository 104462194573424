interceptBrowserUnhandledErrors();
/* import { telemetryTracer } from "lib/telemetry"; */
import type { AppProps } from "next/app";
import React, { useEffect } from "react";
import CompositeProvider from "components/providers/composite.provider";
import { interceptBrowserUnhandledErrors } from "lib/logger/unhandled-errors-interceptor/unhandled-errors.interceptor";
import { setAnalyticsCollection } from "helpers/wtn/firebaseAnalytics";
import { UseCheckUtm } from "hooks/use-check-utm/use-check-utm";
import { useRouter } from "next/router";
import { signOut } from "next-auth/react";
import { getUserIdDataLayer } from "helpers/dataLayer/userId";
import "public/fonts/generalSansVariable/style.css";
import "public/fonts/unbounded/style.css";

enum EnvironmentType {
    BROWSER = "web_browser",
    APP = "mobile_app",
}

/*
if (typeof window !== "undefined") {
    void telemetryTracer();
}
*/

const App = (props: AppProps) => {
    const { route, push } = useRouter();

    UseCheckUtm();

    const addEnvironment = (environment: EnvironmentType) => {
        if (typeof window?.dataLayer === "undefined") {
            return;
        }

        const dataLayer: any = window.dataLayer;
        const env = dataLayer.find((el: any) => el.environment);

        if (env && env.environment === environment) {
            return;
        }

        if (env && env.environment !== environment) {
            window.dataLayer = dataLayer.filter((el: any) => el.environment);
        }

        window.dataLayer.push({
            environment,
        });
    };

    const checkDevice = async () => {
        try {
            await window.WTN.deviceInfo();
            addEnvironment(EnvironmentType.APP);
        } catch (error: unknown) {
            addEnvironment(EnvironmentType.BROWSER);
        }
    };

    useEffect(() => {
        if (props.pageProps?.session?.user) {
            const userId = props.pageProps.session.user.client.id || 0;
            getUserIdDataLayer(userId);
        }
    }, [props.pageProps]);

    useEffect(() => {
        void checkDevice();
    }, [route]);

    useEffect(() => {
        setAnalyticsCollection();
    }, [route]);

    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === "logout") {
                void signOut({ redirect: false });
                setTimeout(() => {
                    void signOut({ redirect: false });
                    push("/login").catch((err) => console.error(err));
                }, 500);
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    return (
        <CompositeProvider {...props}>
            <props.Component {...props.pageProps} />
        </CompositeProvider>
    );
};
export default App;
