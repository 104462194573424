import styled from "styled-components";
import { Modals } from "../modals.component";
import { StyledTitleBox } from "../modals.styled";
import { Icon } from "components/atoms/icon/icon.component";
import { media } from "lib/styled-components/variables";
import { spacing } from "helpers/conversion-style";

export const StyledStatusBenefitsModals = styled(Modals)`
    max-width: 600px;
    border-radius: 27px;
    bottom: 20px;
    margin: auto;
    overflow: hidden;
    padding: ${spacing(52)} ${spacing(30)};
    ${StyledTitleBox} {
        display: none;
    }
    ${media.mMax`
        padding: ${spacing(32)} ${spacing(20)};
    `}
`;

export const StyledStatusBox = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${spacing(36)};
    text-align: center;
    ${media.mMax`
        row-gap: ${spacing(32)};
    `}
`;

export const StyledStatusPending = styled(Icon)`
    fill: ${({ theme }) => theme.gray?.[600]};
    width: 94px;
    height: 94px;
    margin: 0 auto;
`;

export const StyledStatusError = styled(Icon)`
    fill: ${({ theme }) => theme.red?.[600]};
    width: 94px;
    height: 94px;
    margin: 0 auto;
`;

export const StyledButtonList = styled.div`
    margin-top: ${spacing(40)};
    display: flex;
    flex-direction: column;
    row-gap: ${spacing(36)};
    ${media.mMax`
        row-gap: ${spacing(32)};
        margin-top: ${spacing(32)};
    `}
`;
