import React, { useState, useEffect } from "react";
import { useSession } from "next-auth/react";
import { useLanguage } from "components/providers/language/language.provider";
import { useRouter } from "next/router";
import { getProfileClient, IProfile } from "lib/hooks/profile/getProfile";
import { ButtonIconTypes } from "components/atoms/button-icon/button-icon.types";
import { useTranslation } from "next-i18next";
import { ButtonIcon } from "components/atoms/button-icon/button-icon.component";
import { useCheckDevice } from "hooks/use-check-device/use-check-device";
import {
    StyledProfileMenuModals,
} from "./profile-menu.styled";

export interface IProps {
    closeModal: () => void;
}

export const ProfileMenuModals: React.FC<IProps> = ({ closeModal }) => {
    const [profileClient, setProfileClient] = useState<IProfile>();
    const [__] = useTranslation();
    const language = useLanguage();
    const router = useRouter();
    const { mobile } = useCheckDevice();

    const { data: session } = useSession();

    const user: any = session?.user;

    useEffect(() => {
        if (user && !profileClient) {
            void getProfileClient(user.accessToken, language.currentLocale).then((data) => setProfileClient(data));
        }
    }, [user, profileClient]);

    const handlerClick = async (href: string) => {
        closeModal();
        await router.push(href);
    };

    const menuList: {
        text: string;
        icon: ButtonIconTypes;
        onClick: () => void;
        hide?: boolean;
    }[] = [
            {
                text: profileClient?.twoFactorAuthEnabled ? __("profile.disable-twoFA") : __("profile.enable-twoFA"),
                icon: ButtonIconTypes.AUTHENTICATION,
                onClick: () => handlerClick("/profile/2-factor-authentication"),
            },
            {
                text: __("profile.change-email"),
                icon: ButtonIconTypes.EMAIL,
                onClick: () => handlerClick("/profile/change-email"),
            },
            {
                text: __("profile.change-password"),
                icon: ButtonIconTypes.PASSWORD,
                onClick: () => handlerClick("/profile/change-password"),
            },
            // {
            //     text: __("profile.change-phone-number"),
            //     icon: ButtonIconTypes.PHONE,
            //     onClick: () => handlerClick("/profile/change-phone"),
            // },
            {
                text: __("profile.change-language"),
                icon: ButtonIconTypes.LANGUAGE,
                onClick: () => handlerClick("/profile/change-communication-language"),
            },
            {
                text: __("profile.notification-settings"),
                icon: ButtonIconTypes.SETTINGS,
                onClick: () => handlerClick("/profile/email-notification-settings"),
            },
            {
                text: __("profile.notification-settings-mobile"),
                icon: ButtonIconTypes.PUSH ,
                onClick: () => handlerClick("/profile/push-notification-settings"),
                hide: !mobile,
            },
            {
                text: __("profile.theme"),
                icon: ButtonIconTypes.THEME,
                onClick: () => handlerClick("/profile/theme"),
            },
        ];

    return (
        <StyledProfileMenuModals
            closeModal={closeModal}
        >
            <>
                {menuList.map((item) => (
                    <ButtonIcon
                        key={item.icon.replace(/ /g, "")}
                        text={item.text}
                        icon={item.icon}
                        onClick={item.onClick}
                        hide={item.hide}
                    />
                ))}
            </>
        </StyledProfileMenuModals>
    );
};
