import React from "react";
import { TypographyTypes } from "../typography/typography.types";
import { LabelsItemTypes } from "./labels-item.types";
import { StyledLabelsItem } from "./labels-item.styled";

export interface IProps extends React.PropsWithChildren {
    className?: string;
    type: LabelsItemTypes;
}

export const LabelsItem: React.FC<IProps> = ({ className, children, type }) => {
    return (
        <StyledLabelsItem
            className={className}
            variant={TypographyTypes.SMALL}
            type={type}
        >
            {children}
        </StyledLabelsItem>
    );
};
