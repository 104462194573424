export enum ButtonIconTypes {
    AUTHENTICATION = "authentication",
    EMAIL = "email",
    PASSWORD = "password",
    PHONE = "phone",
    LANGUAGE = "language",
    SETTINGS = "settings",
    THEME = "theme",
    PUSH = "push",
    ECONOMIC_CALENDAR = "economic-calendar",
    HELP_CENTER = "help-center",
    DOWNLOADS = "downloads",
    PAYMENT_DETAILS = "payment-details",
    CONTEST = "contest",
    SIGN_OUT = "sign-out",
    PARTNERSHIP = "partnership",
    CONNECT = "connect",
}
