import React, { useState } from "react";
import { useTranslation } from "next-i18next";
import { getAmountWithCurrencySymbol } from "helpers/amount-symbol";
import { Typography } from "../typography/typography.component";
import { TypographyTypes } from "../typography/typography.types";
import { IData } from "lib/hooks/cashbackBreakdown";
import { IconTypes } from "../icon/icon.types";
import {
    StyledRecentItem,
    StyledRecentItemHead,
    StyledButton,
    StyledButtonIcon,
    StyledHeadTitle,
    StyledTextMuted,
    StyledRecentContent,
    StyledInfoItem,
    StyledInfoLine,
} from "./recent-item.styled";

interface IProps {
    data: IData[];
    index?: number;
}

export const RecentItem: React.FC<IProps> = ({ data, index }) => {
    const [__] = useTranslation();
    const [ view, setView ] = useState<boolean>(false);

    const searchValue = (text: string) => {
        const value = data.filter((item) => item.key.includes(text))[0].value;
        if(value) {
            return value;
        } else {
            return "";
        }
    };

    const config = {
        symbol: searchValue("_symbol_"),
        currency: searchValue("_currency_"),
        amount: +searchValue("_amount_"),
        lots: searchValue("_lots_"),
        ticket: searchValue("_ticket_"),
        account: searchValue("_account_"),
        closetime: searchValue("_closetime_").replace(/ /g,", ").replace(/-/g,"."),
    };

    return (
        <StyledRecentItem $animation={index}>
            <StyledRecentItemHead>
                <div>
                    <StyledHeadTitle>
                        <Typography variant={TypographyTypes.LARGE}>{config.symbol}</Typography>
                        <Typography variant={TypographyTypes.LARGE}>
                            {getAmountWithCurrencySymbol( config.currency, config.amount)}
                        </Typography>
                    </StyledHeadTitle>
                    <StyledTextMuted variant={TypographyTypes.SMALL}>
                      { __("modals.partnership.lots-amount", { amount: config?.lots || 0 }) }
                    </StyledTextMuted>
                </div>
                <StyledButton view={view} onClick={() => setView((prev) => !prev)}>
                    <StyledButtonIcon icon={IconTypes.ARROW}/>
                </StyledButton>
            </StyledRecentItemHead>
            <StyledRecentContent view={view}>
                <StyledInfoItem>
                    <StyledTextMuted variant={TypographyTypes.BASE}>{__("modals.partnership.source")}</StyledTextMuted>
                    <StyledInfoLine />
                    <Typography variant={TypographyTypes.BASE}>{config.ticket}</Typography>
                </StyledInfoItem>
                <StyledInfoItem>
                    <StyledTextMuted variant={TypographyTypes.BASE}>{__("modals.partnership.account")}</StyledTextMuted>
                    <StyledInfoLine />
                    <Typography variant={TypographyTypes.BASE}>{config.account}</Typography>
                </StyledInfoItem>
                <StyledInfoItem>
                    <StyledTextMuted variant={TypographyTypes.BASE}>{__("modals.partnership.close-time")}</StyledTextMuted>
                    <StyledInfoLine />
                    <Typography variant={TypographyTypes.BASE}>{config.closetime}</Typography>
                </StyledInfoItem>
            </StyledRecentContent>
        </StyledRecentItem>
    );
};
