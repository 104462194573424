import React, { InputHTMLAttributes } from "react";
import { RadioTypes } from "./radio.types";
import { SortLevelTypes } from "components/organisms/modals/sort/sort.types";
import { useTranslation } from "next-i18next";
import { IconTypes, IPaymentIcon } from "components/atoms/icon/icon.types";
import {
    StyledRadioBoxWithdrawal,
    StyledRadioLabelWithdrawal,
    StyledRadioBox,
    StyledRadio,
    StyledRadioLabel,
    StyledProviderRadioLabel,
    StyledLabelPayment,
    StyledLabelButton,
    StyledLabelDeposit,
    StyledIcon,
    StyledNameBox,
    StyledLabelDepositName,
    StyledLabelDepositBox,
    StyledLabelDepositInfo,
    StyledLabelDepositBonus,
    StyledLabelCircle,
    StyledPaymentLogo,
    StyledLabelDate,
    StyledAbbr,
    StyledProviderRadioBox,
    StyledProviderDesc,
    StyledProviderImage,
} from "./radio.styled";
import { useTheme } from "components/providers/theme/theme.hooks";
import { ThemeVariant } from "components/providers/theme/theme.constants";

export interface IRadio extends InputHTMLAttributes<HTMLInputElement> {
    id: string;
    text: string;
    variant?: RadioTypes;
    full?: boolean;
    icon?: IconTypes;
    level?: SortLevelTypes;
    iconPayment?: IPaymentIcon;
    providerLogo?: string | null | undefined;
    currency?: string;
    accountId?: string;
    bonus?: boolean;
}

export const getAbbr = (name: string): string => {
    if (!name) {
        return "";
    }
    return (
        name
            ?.split(" ")
            ?.map((el) => el?.slice(0, 1)?.toUpperCase())
            ?.filter((el) => /^[a-zA-Z]+$/.test(el))
            ?.join("")
            ?.slice(0, 2)
            ?.trim() || ""
    );
};

export const Radio = React.forwardRef<HTMLInputElement, IRadio>(
    (
        {
            id,
            text,
            full,
            variant = RadioTypes.DEFAULT,
            icon,
            currency,
            accountId,
            bonus,
            iconPayment,
            providerLogo,
            level,
            ...props
        },
        ref,
    ) => {
        let component!: JSX.Element;
        const [__] = useTranslation();
        const theme = useTheme();

        switch (variant) {
            case RadioTypes.DEFAULT:
                component = (
                    <StyledRadioBox>
                        <StyledRadio
                            ref={ref}
                            type="radio"
                            data-testid="test-radio"
                            id={id}
                            {...props}
                        />
                        <StyledRadioLabel htmlFor={id}>{text}</StyledRadioLabel>
                    </StyledRadioBox>
                );
                break;
            case RadioTypes.PROVIDER:
                component = (
                    <StyledProviderRadioBox>
                        <StyledRadio
                            ref={ref}
                            type="radio"
                            $dark={theme.themeVariant === ThemeVariant.Dark}
                            data-testid="test-radio"
                            id={id}
                            {...props}
                        />
                        <StyledProviderRadioLabel htmlFor={id}>
                            <StyledProviderDesc>{text}</StyledProviderDesc>
                            {providerLogo ? (
                                <StyledProviderImage
                                    src={providerLogo}
                                    alt={text}
                                />
                            ) : (
                                <StyledAbbr>{getAbbr(text)}</StyledAbbr>
                            )}
                        </StyledProviderRadioLabel>
                    </StyledProviderRadioBox>
                );
                break;
            case RadioTypes.WITHDRAWAL:
                component = (
                    <StyledRadioBoxWithdrawal>
                        <StyledRadio
                            ref={ref}
                            type="radio"
                            data-testid="test-radio"
                            id={id}
                            {...props}
                        />
                        <StyledRadioLabelWithdrawal htmlFor={id}>
                            {text}
                        </StyledRadioLabelWithdrawal>
                    </StyledRadioBoxWithdrawal>
                );
                break;
            case RadioTypes.PAYMENT:
                component = (
                    <div>
                        <StyledRadio
                            ref={ref}
                            type="radio"
                            data-testid="test-radio"
                            id={id}
                            {...props}
                        />
                        <StyledLabelPayment htmlFor={id} $full={full}>
                            {text}
                        </StyledLabelPayment>
                    </div>
                );
                break;
            case RadioTypes.DATE:
                component = (
                    <div>
                        <StyledRadio
                            ref={ref}
                            type="radio"
                            data-testid="test-radio"
                            id={id}
                            {...props}
                        />
                        <StyledLabelDate htmlFor={id} $full={full}>
                            {iconPayment ? (
                                <StyledPaymentLogo
                                    width={iconPayment.width}
                                    height={iconPayment.height}
                                >
                                    <use
                                        xlinkHref={`/svg/payment/payment-sprite.svg#${iconPayment.name}`}
                                    />
                                </StyledPaymentLogo>
                            ) : (
                                text
                            )}
                        </StyledLabelDate>
                    </div>
                );
                break;
            case RadioTypes.BUTTON:
                component = (
                    <div>
                        <StyledRadio
                            ref={ref}
                            type="radio"
                            data-testid="test-radio"
                            id={id}
                            level={level}
                            {...props}
                        />
                        <StyledLabelButton htmlFor={id}>
                            {text}
                            <StyledIcon
                                icon={icon ? icon : IconTypes.CHECKBOX_INVERSE}
                            />
                        </StyledLabelButton>
                    </div>
                );
                break;
            case RadioTypes.DEPOSIT:
                component = (
                    <div>
                        <StyledRadio
                            ref={ref}
                            type="radio"
                            data-testid="test-radio"
                            id={id}
                            {...props}
                        />
                        <StyledLabelDeposit htmlFor={id}>
                            <StyledLabelCircle />
                            <StyledLabelDepositBox>
                                <StyledNameBox>
                                    <StyledLabelDepositName>
                                        {text}
                                    </StyledLabelDepositName>
                                    {bonus && (
                                        <StyledLabelDepositBonus>
                                            {__("components.bonus")}
                                        </StyledLabelDepositBonus>
                                    )}
                                </StyledNameBox>
                                <span>
                                    <StyledLabelDepositInfo>
                                        {accountId}
                                    </StyledLabelDepositInfo>
                                    <StyledLabelDepositInfo>
                                        {currency}
                                    </StyledLabelDepositInfo>
                                </span>
                            </StyledLabelDepositBox>
                        </StyledLabelDeposit>
                    </div>
                );
                break;
        }
        return component;
    },
);

Radio.displayName = "Radio";
