import styled from "styled-components";
import { Modals } from "../modals.component";
import { StyledTitleBox } from "../modals.styled";
import { Typography } from "components/atoms/typography/typography.component";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { media } from "lib/styled-components/variables";

export const StyledIncompleteModalTransaction = styled(Modals)`
    right: 0;
    left: unset;
    bottom: 0;
    max-width: 475px;
    margin: 0;
    height: 100svh;
    max-height: unset;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow-y: auto;
    ${StyledTitleBox} {
        padding-bottom: 20px;
    }
    ${media.smMax`
        height: auto;
        max-height: 95svh;
        max-width: 100vw;
        width: 100vw;
        border-radius: 24px 24px 0 0;
    `}
`;

export const StyledIncompleteModalTransactionBody = styled.div`
    padding: 0 28px 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    row-gap: 20px;
    justify-content: space-between;
`;

export const StyledIncompleteModalTransactionIcon = styled.svg`
    border: 54px solid ${({ theme }) => theme.purple[100]};
    border-radius: 50%;
    box-sizing: content-box;
    ${media.smMax`
        width: 38px;
        height: 38px;
        border-width: 23px;
    `}
`;

export const StyledIncompleteModalTransactionTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
`;

export const StyledIncompleteModalTransactionTitle = styled(Typography)``;

export const StyledIncompleteModalTransactionDescription = styled(Typography)`
    color: ${({ theme }) => theme.gray[1000]};
`;

export const StyledIncompleteModalTransactionDetailsList = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    margin-top: 24px;
`;

export const StyledIncompleteModalTransactionDetailsListItem = styled.li`
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: end;
    gap: 4px;
    padding-top: 14px;
    color: ${({ theme }) => theme.gray[1000]};
    span:nth-child(2) {
        border-bottom: 1px dashed ${({ theme }) => theme.gray[900]};
    }
    h3 {
        justify-self: end;
        color: ${({ theme }) => theme.text.default};
    }
    :not(:last-of-type) {
        margin-bottom: 8px;
    }
`;

export const StyledIncompleteModalTransactionButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
    margin-top: 20px;
`;

export const StyledIncompleteModalTransactionButton = styled(ButtonLink)`
    ${media.smMax`
        p {
            font-size: 18px;
        }
    `}
`;

export const StyledPaymentNameWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    img {
        height: 24px !important;
        width: auto !important;
    }
`;

export const StyledIncompleteModalTransactionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
    justify-content: space-between;
`;
