import styled from "styled-components";
import { spacing } from "helpers/conversion-style";
import { Typography } from "components/atoms/typography/typography.component";

export const StyledInfoBox = styled.div`
    border: 1px solid ${({ theme }) => theme.gray?.[200]};
    border-radius: ${spacing(16)};
    padding: ${spacing(12)} ${spacing(8)};
    display: flex;
    flex-direction: column;
    gap: ${spacing(16)};
`;

export const StyledInfoItem = styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: ${spacing(8)};
`;

export const StyledLine = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.gray?.[100]};
    flex: 1;
`;

export const StyledInfoItemName = styled(Typography)`
    color: ${({ theme }) => theme.text?.muted};
`;
