import React, { useState, useEffect } from "react";
import Image from "next/image";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { Typography } from "components/atoms/typography/typography.component";
import { IconTypes } from "components/atoms/icon/icon.types";
import {
    StyledNotification,
    StyledNotificationClose,
    StyledCloseIcon,
    StyledNotificationIcon,
    StyledNotificationTitle,
} from "./notification-modals.styled";

export interface IProps {
    closeModal: () => void;
    text: string;
    title?: string;
    icon?: string;
    error: boolean;
}

export const NotificationModals: React.FC<IProps> = ({ closeModal, text, icon, error, title }) => {
    const [active, setActive] = useState<boolean>(Boolean(text));

    useEffect(() => {
        setActive(true);
        const setActiveTimeOut = setTimeout(() => {
            setActive(false);
        }, 3500);
        const closeModalTimeOut = setTimeout(() => {
            if (closeModal && typeof closeModal === "function") {
                closeModal();
            }
        }, 4000);
        return () => {
            clearTimeout(setActiveTimeOut);
            clearTimeout(closeModalTimeOut);
        };
    }, [closeModal]);

    return (
        <StyledNotification
            $active={active}
            $error={error}
            isOverlay={false}
        >
            {icon &&
                <StyledNotificationTitle>
                    <StyledNotificationIcon>
                        <Image
                            src={icon}
                            alt="Icon notification"
                            layout="fill"
                        />
                    </StyledNotificationIcon>
                    <Typography variant={TypographyTypes.LARGE}>{title}</Typography>
                </StyledNotificationTitle>
            }
            <Typography variant={TypographyTypes.BASE}>{text}</Typography>
            <StyledNotificationClose onClick={closeModal} data-testid="close-button">
                <StyledCloseIcon icon={IconTypes.CLOSE} $error={error} />
            </StyledNotificationClose>
        </StyledNotification>
    );
};
