import React from "react";
import { StyledPreviewModals, StyledPreviewContent } from "./preview.styled";

export interface IProps {
    closeModal: () => void;
    link: string;
    title: string;
}

export const PreviewModals: React.FC<IProps> = ({
    closeModal,
    link,
    title,
}) => {
    return (
        <StyledPreviewModals
            closeModal={closeModal}
            title={title}
        >
            <StyledPreviewContent>
              <iframe
                src={link}
                title="dummy report"
                frameBorder={0}
              />
            </StyledPreviewContent>
        </StyledPreviewModals>
    );
};
