import styled from "styled-components";
import { Icon } from "components/atoms/icon/icon.component";
import { spacing } from "helpers/conversion-style";

export const StyledInfoButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: ${spacing(24)};
  height: ${spacing(24)};
`;

export const StyledInfoIcon = styled(Icon)`
  width: ${spacing(24)};
  height: ${spacing(24)};
  fill: ${({ theme }) => theme.text?.muted};
`;
