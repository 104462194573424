import styled from "styled-components";
import { Modals } from "../modals.component";

export const StyledSumSubModals = styled(Modals)`
    max-width: 100%;
    height: 100%;
    z-index: 10;
`;

export const StyledSumSubBox = styled.div`
    iframe {
        width: 100%;
        height: calc(100vh - 100px) !important;
    }
    .sumsub-body {
        width: 100%;
    }
`;
