import styled from "styled-components";
import { Typography } from "components/atoms/typography/typography.component";
import { spacing } from "helpers/conversion-style";
import { StyledInputWrapper } from "components/atoms/form/input/input.styled";
import { Input } from "components/atoms/form/input/input.component";
import { StyledTitleBox } from "../modals.styled";
import { Modals } from "../modals.component";

export const StyledDaterangeModals = styled(Modals)`
    max-width: 550px;
    display: grid;
    grid-template-rows: 72px 1fr;
    overflow: hidden;
    ${StyledTitleBox} {
        display: none;
    }
`;

export const StyledDaterangeHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing(16)};
`;

export const StyledDaterangeContent = styled.div`
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
`;

export const StyledButtonBox = styled.div`
    padding: ${spacing(16)};
`;

export const StyledFilterList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: ${spacing(12)} ${spacing(16)};
`;

export const StyledInputDate = styled(Input)`
    width: 100%;
    flex-grow: 1;
    text-align: left;
    padding-top: ${spacing(24)};
    justify-content: start;
    align-items: center;
    display: flex;
`;

export const StyledDateCustom = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: ${spacing(12)} ${spacing(16)};
    ${StyledInputWrapper} {
        width: 100%;
        display: flex;
        &:first-child {
            ${StyledInputDate} {
                border-radius: 18px 0 0 18px;
                border-right: none;
            }
        }
        &:last-child {
            ${StyledInputDate} {
                border-radius: 0 18px 18px 0;
                border-left: none;
            }
        }
    }
`;

export const StyledFilter = styled.div`
    padding: 0 ${spacing(16)};
`;

export const StyledFilterItem = styled.div`
    padding: ${spacing(12)} 0;
`;

export const StyledText = styled(Typography)`
    margin-bottom: ${spacing(8)};
    color: ${({ theme }) => theme.text?.muted };
`;

export const StyledListItem = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${spacing(12)};
`;
