import styled from "styled-components";
import { Typography } from "components/atoms/typography/typography.component";
import { spacing } from "helpers/conversion-style";
import { StyledTitleBox } from "../modals.styled";
import { Modals } from "../modals.component";

export const StyledPartnershipInfoModals = styled(Modals)`
    max-width: 550px;
    display: grid;
    grid-template-rows: 128px 1fr;
    overflow: hidden;
    ${StyledTitleBox} {
        display: none;
    }
    &:before {
        content: "";
        width: 90px;
        height: 5px;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        background-color: ${({ theme }) => theme.base?.white};
        position: absolute;
        z-index: 2;
        border-radius: ${spacing(6)};
    }
`;

export const StyledModalsTitleBox = styled.div`
    min-height: 128px;
    padding: 0;
    display: grid;
    grid-template-columns: 205px 1fr;
    align-items: end;
    background: linear-gradient(98deg, #E2FAEA 2.44%, #55E685 102.5%);
`;

export const StyledTitleImage = styled.div`
    position: relative;
    width: 204px;
    height: 128px;
`;

export const StyledModalsTitle = styled(Typography)<{$dark: boolean}>`
    color: ${({ theme, $dark }) => $dark ? "#000000" :theme.text?.default};
    font-size: 22px;
    padding: ${spacing(16)} ${spacing(16)} ${spacing(16)} 0;
`;

export const StyledModalsContentBox = styled.div`
    overflow: hidden;
    overflow-y: auto;
    padding: ${spacing(16)} ${spacing(16)} ${spacing(106)};
    display: flex;
    flex-direction: column;
    row-gap: ${spacing(24)};
    position: relative;
`;

export const StyledButtonBox = styled.div`
    height: 90px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.bg?.defaultSemiTransparent};
    padding: ${spacing(8)} ${spacing(16)};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledList = styled.ul`
    margin: 0;
    padding-left: 20px;
`;

export const StyledTextItemBox = styled.div<{$indentation?: boolean}>`
    display: flex;
    flex-direction: column;
    row-gap: ${({$indentation}) => $indentation ? spacing(16) : 0};
`;
