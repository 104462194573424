import styled from "styled-components";
import { spacing } from "helpers/conversion-style";
import { Typography } from "components/atoms/typography/typography.component";
import { Icon } from "components/atoms/icon/icon.component";
import { Modals } from "../modals.component";

interface IProps {
    active?: boolean;
}

export const StyledLanguageModals = styled(Modals)`
    max-width: 550px;
    display: flex;
    flex-direction: column;
`;

export const StyledLanguageList = styled.ul`
    margin: 0;
    padding: 0 ${spacing(4)} ${spacing(16)};
    list-style: none;
    overflow-y: auto;
    flex: 1;
`;

export const StyledLanguageItem = styled.li<IProps>`
    padding: ${spacing(16)} ${spacing(12)};
    color: ${({theme, active}) => active ? theme.green?.dark : theme.text?.default};
    background-color: ${({theme, active}) => active ? theme.green?.[800] : "transparent"};
    transition: all 0.3s ease-in-out;
    border-radius: 16px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 24px;
    align-items: center;
    gap: 8px;
`;

export const StyledLanguageText = styled(Typography)`
    min-height: 24px;
    display: flex;
    align-items: center;
`;

export const StyledLanguageIcon = styled(Icon)<IProps>`
    width: 24px;
    min-width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
    opacity: ${({active}) => active ? 1 : 0};
    transform: scale(${({active}) => active ? 1 : 0});
    fill: ${({theme}) => theme.green?.dark};
`;
