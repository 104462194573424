import { IDevice } from "hooks/use-check-device/use-check-device";
import { setDeviceUser } from "lib/hooks/customer-io/setDeviceUser";
import { deleteDeviceUser } from "lib/hooks/customer-io/deleteDeviceUser";

export const setNotificationsWTN = (id: string | number, deviceInfo: IDevice | undefined) => {
    window.WTN.Firebase.Messaging.getFCMToken({
        callback(data: any) {
            if(deviceInfo) {
                return setDeviceUser(`${id}`, deviceInfo, data.token);
            }
        },
    });
};

export const removeNotificationsWTN = (id: string) => {
    window.WTN.Firebase.Messaging.getFCMToken({
        callback(data: any) {
            return deleteDeviceUser(id, data.token);
        },
    });
};
