import styled from "styled-components";
import { Typography } from "components/atoms/typography/typography.component";
import { StyledTitleBox } from "../modals.styled";
import { spacing } from "helpers/conversion-style";
import { Modals } from "../modals.component";

export const StyledDisclaimerModals = styled(Modals)`
    max-width: 500px;
    overflow: hidden;
    ${StyledTitleBox} {
        display: none;
    }
    &:before {
        content: "";
        width: 90px;
        height: 5px;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        background-color: ${({ theme }) => theme.base?.white};
        position: absolute;
        z-index: 2;
        border-radius: ${spacing(6)};
    }
`;

export const StyledTopBox = styled.div`
    min-height: 128px;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 140px;
    align-items: end;
    background: linear-gradient(98deg, #E2FAEA 2.44%, #55E685 102.5%);
`;

export const StyledTitle = styled(Typography)<{$dark: boolean}>`
    color: ${({ theme, $dark }) => $dark ? "#000000" :theme.text?.default};
    font-size: 22px;
    padding: 0 0 ${spacing(16)} ${spacing(16)};
`;

export const StyledText = styled(Typography)`
    &:not(:last-child) {
        margin-bottom: 16px;
    }
`;

export const StyledContentBox = styled.div`
    padding: ${spacing(24)};
`;

export const StyledButtonBox = styled.div`
    padding: 0 ${spacing(16)} ${spacing(16)};
`;

export const StyledImageBox = styled.div`
    position: relative;
    width: 140px;
    height: 115px;
`;
