import styled, { css } from "styled-components";
import { VariantCheckbox, ColorCheckbox } from "./checkbox.types";
import {
    StylesToSmall,
    StylesToLarge,
    StylesToBase,
} from "../../typography/typography.styled";

interface IProps {
    variant: VariantCheckbox;
    color?: ColorCheckbox;
}

const DefaultCheckboxCSS = css`
    width: 38px;
    min-width: 38px;
    height: 24px;
    border-radius: 12px;
    &:before {
        width: 16px;
        height: 16px;
    }
    &:active:before {
        width: 22px;
    }
`;

const BigCheckboxCSS = css`
    width: 52px;
    min-width: 52px;
    height: 32px;
    border-radius: 18px;
    &:before {
        width: 24px;
        height: 24px;
    }
    &:active:before {
        width: 32px;
    }
`;

const CircleCheckboxCSS = css`
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    &:before {
        width: 16px;
        height: 16px;
        background-color: ${({ theme }) => theme.bg?.page};
    }
`;

const CheckCheckboxCSS = css`
    background: none;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    border-radius: 6px;
    border: 3px solid ${({ theme }) => theme.gray?.[200]};
    &:before {
        background-color: inherit;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url("/svg/check.svg");
        width: 18px;
        height: 18px;
        right: auto;
        bottom: auto;
        box-shadow: none;
        border-radius: 0;
        opacity: 0;
    }
`;

const labelItemCSS = css<IProps>`
    display: flex;
    align-items: center;
    gap: ${({ variant }) => {
        switch (variant) {
            case VariantCheckbox.BIG:
                return "8px";
            case VariantCheckbox.DEFAULT:
                return "4px";
            case VariantCheckbox.CIRCLE:
                return "4px";
            case VariantCheckbox.CHECK:
                return "8px";
        }
    }};
`;

const switcherCSS = css<IProps>`
    cursor: pointer;
    background-color: ${({ theme }) => theme.gray?.[300]};
    transition: 0.3s;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 4px;
        right: 4px;
        bottom: 0;
        margin: auto 0;
        box-shadow: 0 0 8px 0 ${({ theme }) => theme.checkbox?.default};
        background-color: ${({ theme }) => theme.base?.white};
        border-radius: 16px;
        transition: 0.3s;
        transform: translateX(0);
    }
    ${({ variant }) => {
        switch (variant) {
            case VariantCheckbox.BIG:
                return BigCheckboxCSS;
            case VariantCheckbox.DEFAULT:
                return DefaultCheckboxCSS;
            case VariantCheckbox.CIRCLE:
                return CircleCheckboxCSS;
            case VariantCheckbox.CHECK:
                return CheckCheckboxCSS;
        }
    }};
`;

export const StyledCheckboxItem = styled.div<IProps>`
    ${labelItemCSS};
`;

export const StyledCheckboxButtonLabel = styled.label<IProps>`
    ${switcherCSS};
`;

export const StyledSwitcher = styled.div<IProps>`
    ${switcherCSS};
`;

export const StyledLabelFull = styled.label<IProps>`
    ${labelItemCSS};
    cursor: pointer;
`;

export const StyledCheckboxButton = styled.input<IProps>`
    display: none;
    &:checked {
        + ${StyledLabelFull} {
            ${StyledSwitcher} {
                background-color: ${({ theme }) => theme.green?.[800]};
                border-color: ${({ theme }) => theme.green?.[800]};
                &:before {
                    ${({ variant }) => {
                        switch (variant) {
                            case VariantCheckbox.CHECK:
                            return css`
                                opacity: 1;
                            `;
                            default:
                                return css`
                                left: calc(100% - 4px);
                                background-color: ${({ theme }) => theme.components?.success_bg};
                                box-shadow: 0 0 8px ${({ theme }) => theme.checkbox?.checked};
                                transform: translateX(-100%);
                            `;
                        }
                    }};
                }
            }
        }
        + ${StyledCheckboxButtonLabel} {
            background-color: ${({ theme }) => theme.green?.[800]};
            border-color: ${({ theme }) => theme.green?.[800]};
            &:before {
                ${({ variant }) => {
                    switch (variant) {
                        case VariantCheckbox.CHECK:
                        return css`
                            opacity: 1;
                        `;
                        default:
                            return css`
                            left: calc(100% - 4px);
                            background-color: ${({ theme }) => theme.components?.success_bg};
                            box-shadow: 0 0 8px ${({ theme }) => theme.checkbox?.checked};
                            transform: translateX(-100%);
                        `;
                    }
                }};
            }
        }
    }
    &:disabled {
        + ${StyledLabelFull} {
            ${StyledSwitcher} {
                background-color: ${({ theme }) => theme.gray?.[200]};
                &:before {
                    left: calc(100% - 4px);
                    background-color: ${({ theme }) => theme.gray?.[300]};
                    box-shadow: 0 0 8px ${({ theme }) => theme.checkbox?.default};
                } 
            }
        }
        + ${StyledCheckboxButtonLabel} {
            background-color: ${({ theme }) => theme.gray?.[200]};
            &:before {
                left: calc(100% - 4px);
                background-color: ${({ theme }) => theme.gray?.[300]};
                box-shadow: 0 0 8px ${({ theme }) => theme.checkbox?.default};
            }
        }
    }
`;

export const StyledCheckboxText = styled.p<IProps>`
    ${({ variant }) => {
        switch (variant) {
            case VariantCheckbox.BIG:
                return StylesToLarge;
            case VariantCheckbox.DEFAULT:
                return StylesToSmall;
            case VariantCheckbox.CHECK:
                return StylesToBase;
        }
    }};
    color: ${({ theme, color }) => theme.text && theme.text[color || "muted"]};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
