import React, { ReactNode } from "react";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { CopyButton } from "components/atoms/copy-button/copy-button.component";
import { InfoButton } from "components/atoms/info-button/info-button.component";
import {
    StyledInfoBox,
    StyledInfoItem,
    StyledInfoItemName,
    StyledLine,
} from "./info-box.styled";

export interface IInfo {
    title: string;
    text: string | undefined;
    isCopy?: boolean;
    copyText?: string | undefined;
    isInfo?: boolean;
    infoOnClick?: () => void;
}

export interface IProps {
    className?: string;
    list: IInfo[];
    header?: JSX.Element | string;
}

export const InfoBox: React.FC<IProps> = ({ className, list, header }) => {
    return (
        <StyledInfoBox className={className}>
            {header ? header : null}
            {
                list.map((item) => (
                    item.text &&
                    <StyledInfoItem key={item.title.replace(/ /g, "")}>
                        <StyledInfoItemName variant={TypographyTypes.BASE}>
                            {item.title}
                        </StyledInfoItemName>
                        {(item.isInfo && item.infoOnClick) && (
                            <InfoButton onClick={item.infoOnClick} />
                        )}
                        <StyledLine />
                        <Typography variant={TypographyTypes.BASE}>
                            {item.text}
                        </Typography>
                        {(item.isCopy && item.copyText) && (<CopyButton data={item.copyText} />)}
                    </StyledInfoItem>
                ))
            }
        </StyledInfoBox>
    );
};
