import styled from "styled-components";
import { Modals } from "../modals.component";

export const StyledReferralsModals = styled(Modals)`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 15;
    border-radius: 0;
    max-height: 100%;
    display: flex;
    flex-direction: column;
`;
