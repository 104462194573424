import React, { useState, useEffect } from "react";
import { Preloader } from "components/atoms/preloader/preloader.component";
import { PreloaderHeightType } from "./preloader-box.type";
import {
    StyledPreloaderBox,
    StyledChildrenBox,
} from "./preloader-box.styled";

interface IProps extends React.PropsWithChildren {
    visibility: boolean;
    height?: PreloaderHeightType;
}

export const PreloaderBox: React.FC<IProps> = ({
    visibility,
    children,
    height =  PreloaderHeightType.DEFAULT,
}) => {
    const [ hideContent, setHideContent ] = useState<boolean>(true);
    const [ animation, setAnimation ] = useState<boolean>(false);

    useEffect(() => {
        setAnimation(visibility);
        setTimeout(() => setHideContent(!visibility), 500);
    }, [visibility]);

    return (
        <>
            {hideContent ?
                <StyledPreloaderBox $animation={animation} $height={height}>
                    <Preloader/>
                </StyledPreloaderBox>
                :
                <StyledChildrenBox $animation={animation} $height={height}>
                    {children}
                </StyledChildrenBox>
            }
        </>
    );
};
