import { COOKIES_ENDLESS_OPTIONS, COOKIES_NAMES } from "lib/configuration";
import { useRouter } from "next/router";
import React from "react";
import { useCallback, useMemo, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { ThemePropsType, ThemesProps, ThemeVariant } from "./theme.constants";

export interface ITheme {
    themeVariant: ThemeVariant;
    themeProps: ThemePropsType;
    setThemeVariant: (theme: ThemeVariant, reload?: boolean) => void;
}

export const ThemeContext = React.createContext<ITheme>({
    themeVariant: ThemeVariant.Default,
    themeProps: ThemesProps[ThemeVariant.Default],
    setThemeVariant: () => void 0,
});

const ThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { themeVariant, themeProps, setThemeVariant } = useThemeState();

    return (
        <ThemeContext.Provider
            value={{ themeVariant, themeProps, setThemeVariant }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;

const useThemeState = () => {
    const [cookies, setCookie] = useCookies([COOKIES_NAMES.theme]);
    const [themeVariant, setThemeVariantState] = useState(() => {
        const cookieTheme = cookies[COOKIES_NAMES.theme];

        return (
            Object.values(ThemeVariant).find(
                (theme) => theme === cookieTheme,
            ) || ThemeVariant.Default
        );
    });
    const themeProps = useMemo(() => ThemesProps[themeVariant], [themeVariant]);
    const { reload: locationReload } = useRouter();
    const setThemeVariant = useCallback(
        (newTheme: ThemeVariant, reload?: boolean) => {
            setCookie(COOKIES_NAMES.theme, newTheme, COOKIES_ENDLESS_OPTIONS);
            if (reload) {
                locationReload();
            } else {
                setThemeVariantState(newTheme);
            }
        },
        [locationReload, setCookie],
    );

    useEffect(() => {
        window.WTN?.statusBar({
            style: themeVariant === ThemeVariant.Default ? "dark" : "light",
            color: themeProps.bg?.page,
            overlay: false,
        });
    }, [ themeVariant, themeProps ]);

    return { themeVariant, themeProps, setThemeVariant };
};
