import { IDataFilter } from "components/organisms/modals/partnership-filter/partnership-filter.component";
import { ReferralsTypes } from "components/organisms/modals/partnership-referrals/partnership-referrals.types";
import { IRowsReferrals, IData } from "lib/hooks/ib/referrals";

const dataValue = (data: IData[], name: string) =>
                data.filter((k) => k.key.toLowerCase().includes(name.toLowerCase()))[0].value;

export const searchData = (data: IRowsReferrals[], text: string, ) => {
    return data.filter((item) => {
        const key = dataValue(item.data, "firstName");
            if(key.toLowerCase().includes(text.toLowerCase())) {
                return item;
            }
    });
};

export const rangeData = (data: IRowsReferrals[], value: number[], name: string) => {
    return data.filter((item) => {
        const key = +dataValue(item.data, name);
        if(key >= value[0] && value[1] >= key) {
            return item;
        }
    });
};

export const stateData = (data: IRowsReferrals[], value: ReferralsTypes) => {
    return data.filter((item) => {
        const key = +dataValue(item.data, "isVerified");
        switch(value) {
            case ReferralsTypes.ALL: return item;
            case ReferralsTypes.VERIFIED: {
                if(+key === 1) {
                    return item;
                }
                break;
            };
            case ReferralsTypes.UNVERIFIED: {
                if(+key === 0) {
                    return item;
                }
                break;
            };
        }
    });
};

export const referralsData = (data: IRowsReferrals[], value: ReferralsTypes[]) => {
    return data.filter((item) => {
        const key: any = dataValue(item.data, "level");
        if(value.includes(ReferralsTypes.ALL)) {
            return item;
        } else if (value.includes(key)) {
            return item;
        }
    });
};

export const filterData = (data: IRowsReferrals[], filter: IDataFilter) => {
    const balance = rangeData(data, filter.balance, "balance");
    const commission = rangeData(balance, filter.commission, "commission");
    const deposits = rangeData(commission, filter.deposits, "deposits");
    const lots = rangeData(deposits, filter.lots, "totalTradedLots");
    const closed = rangeData(lots, filter.closed, "referrals_pl_closed");
    const state = stateData(closed, filter.state);
    const referrals = referralsData(state, filter.referral);
    return referrals;
};
