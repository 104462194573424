import React, { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "next-i18next";
import { Radio } from "components/atoms/form/radio/radio.component";
import { RadioTypes } from "components/atoms/form/radio/radio.types";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes, ButtonSize } from "components/atoms/button-link/button-link.types";
import { IconTypes } from "components/atoms/icon/icon.types";
import { DaterangeType } from "./daterange.types";
import { IDate, IDateFilterContext } from "helpers/use-date-filter";
import { StyledButtonClose, StyledButtonIcon } from "../modals.styled";
import {
    StyledDaterangeModals,
    StyledButtonBox,
    StyledFilterList,
    StyledDateCustom,
    StyledInputDate,
    StyledDaterangeHead,
    StyledDaterangeContent,
} from "./daterange-modals.styled";

interface IFilter {
    text: string;
    id: DaterangeType;
}

interface IProps extends IDateFilterContext {
    closeModal: () => void;
}

export const DaterangeModals: React.FC<IProps> = ({
    closeModal,
    type,
    dateFilter,
    changeDate,
    changeType,
}) => {
    const [__] = useTranslation();
    const [dateModal, setDateModal] = useState<IDate>({
        start: "",
        end: "",
    });
    const [typeModal, setTypeModal] = useState<DaterangeType>(type);

    useEffect(() => {
        setDateModal({
            start: dateFilter.start,
            end: dateFilter.end,
        });
    }, []);

    const listFilter: IFilter[] = [
        {
            text: __("common.date.yesterday"),
            id: DaterangeType.YESTERDAY,
        },
        {
            text: __("common.date.previous-week"),
            id: DaterangeType.PREVIOUS_WEEK,
        },
        {
            text: __("common.date.this-month"),
            id: DaterangeType.THIS_MONTH,
        },
        {
            text: __("common.date.previous-month"),
            id: DaterangeType.PREVIOUS_MONTH,
        },
        {
            text: __("common.date.past-quarter"),
            id: DaterangeType.PAST_QUARTER,
        },
        {
            text: __("common.date.past-year"),
            id: DaterangeType.PAST_YEAR,
        },
        {
            text: __("common.date.all-time"),
            id: DaterangeType.ALL_TIME,
        },
        {
            text: __("common.date.custom"),
            id: DaterangeType.CUSTOM,
        },
    ];

    const handlerResetFilter = () => {
        setTypeModal(DaterangeType.YESTERDAY);
    };

    const handlerResult = () => {
        changeType(typeModal);
        changeDate(dateModal.start, dateModal.end);
        closeModal();
    };

    return (
        <StyledDaterangeModals closeModal={closeModal}>
            <StyledDaterangeHead>
                <ButtonLink
                    variant={ButtonTypes.SECONDARY}
                    text={__("modals.daterange.reset-filter")}
                    size={ButtonSize.MINI}
                    onClick={handlerResetFilter}
                />
                <StyledButtonClose onClick={closeModal}>
                    <StyledButtonIcon icon={IconTypes.CLOSE} />
                </StyledButtonClose>
            </StyledDaterangeHead>
            <StyledDaterangeContent>
                <StyledFilterList>
                    {
                        listFilter.map((item) => (
                            <Radio
                                key={item.id}
                                id={item.id}
                                type="radio"
                                variant={RadioTypes.PAYMENT}
                                name="filter"
                                text={item.text}
                                checked={item.id === typeModal}
                                onChange={() => setTypeModal(item.id)}
                            />
                        ))
                    }
                </StyledFilterList>
                {typeModal === DaterangeType.CUSTOM &&
                    <StyledDateCustom>
                        <StyledInputDate
                            labelText={__("modals.daterange.start-date")}
                            type="date"
                            value={dateModal.start}
                            onChange={
                                (event: ChangeEvent<HTMLInputElement>) =>
                                    setDateModal((prev) => (
                                        {
                                            ...prev,
                                            start: event.target.value
                                        }
                                    ))
                            }
                        />
                        <StyledInputDate
                            labelText={__("modals.daterange.end-date")}
                            type="date"
                            value={dateModal.end}
                            onChange={
                                (event: ChangeEvent<HTMLInputElement>) =>
                                    setDateModal((prev) => (
                                        {
                                            ...prev,
                                            end: event.target.value
                                        }
                                    ))
                            }
                        />
                    </StyledDateCustom>
                }
                <StyledButtonBox>
                    <ButtonLink
                        variant={ButtonTypes.PRIMARY}
                        text={__("modals.daterange.show-results")}
                        onClick={handlerResult}
                    />
                </StyledButtonBox>
            </StyledDaterangeContent>
        </StyledDaterangeModals>
    );
};
