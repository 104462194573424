import styled, { css } from "styled-components";
import { spacing } from "helpers/conversion-style";
import { typographyStyle } from "lib/tokens/ui.styled";
import {
    conversionSize,
    checkedFontWeight,
    conversionLetterSpacing,
} from "helpers/conversion-style";

export const StyledContainer = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding: 0 ${spacing(16)};
`;

export const StylesToInput = css`
    letter-spacing: ${conversionLetterSpacing(typographyStyle.letterSpacing.default.value)};
    font-size: ${conversionSize(typographyStyle.fontSizes.mobile.base.value)};
    font-weight: ${checkedFontWeight(typographyStyle.fontWeights.base.value)};
    line-height: ${typographyStyle.lineHeights.body.value};
`;
