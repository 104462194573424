import { FC } from "react";
import {
    StyledNewWalletModal,
    StyledNewWalletModalContent,
    StyledNewWalletModalIcon,
    StyledNewWalletModalTitle,
    StyledNewWalletModalTitleWrapper,
} from "./new-wallet.styled";
import { IModals } from "../modals.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { useTranslation } from "next-i18next";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";

interface INewWalletModalProps extends Pick<IModals, "closeModal"> {}

export const NewWalletModal: FC<INewWalletModalProps> = ({ closeModal }) => {
    const [__] = useTranslation("common");
    const [topTitle, bottomTitle] = __("modals.main-wallet.title").split(
        "{sep}",
    );

    return (
        <StyledNewWalletModal closeModal={closeModal} withoutCloseButton>
            <StyledNewWalletModalContent>
                <StyledNewWalletModalIcon
                    viewBox="0 0 128 129"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="27"
                        y="15.5"
                        width="87"
                        height="90"
                        rx="16"
                        fill="#00DA48"
                    />
                    <rect
                        x="15"
                        y="22.5"
                        width="92"
                        height="92"
                        rx="16"
                        fill="#8F65F2"
                    />
                    <rect
                        x="31"
                        y="38.5"
                        width="60"
                        height="60"
                        rx="30"
                        fill="#8050F1"
                    />
                    <path
                        d="M53.4694 56.2994C52.72 56.2994 50.7992 55.9517 49.7017 57.1006C48.5861 58.2686 48.7598 60.5226 48.7598 61.3356V76.1519C48.7598 77.3714 49.2064 78.5409 50.0013 79.4032C50.7962 80.2656 51.8743 80.75 52.9985 80.75H69.0113C70.1355 80.75 71.2136 80.2656 72.0085 79.4032C72.8034 78.5409 73.25 77.3714 73.25 76.1519V66.9556C73.25 65.7361 72.8034 64.5665 72.0085 63.7042C71.2136 62.8419 70.1355 62.3575 69.0113 62.3575H51.5856C51.3358 62.3575 51.0962 62.2498 50.9195 62.0582C50.7429 61.8666 50.6437 61.6067 50.6437 61.3356C50.6437 61.0646 50.7429 60.8047 50.9195 60.6131C51.0962 60.4215 51.3358 60.3138 51.5856 60.3138H62.9384C64.043 60.3138 64.9384 59.4112 64.9384 58.3066C64.9384 57.2021 64.043 56.2994 62.9384 56.2994H53.4694ZM65.7146 70.5319H68.5403C68.7902 70.5319 69.0297 70.6396 69.2064 70.8312C69.383 71.0228 69.4823 71.2827 69.4823 71.5537C69.4823 71.8247 69.383 72.0846 69.2064 72.2763C69.0297 72.4679 68.7902 72.5755 68.5403 72.5755H65.7146C65.4647 72.5755 65.2252 72.4679 65.0485 72.2763C64.8719 72.0846 64.7726 71.8247 64.7726 71.5537C64.7726 71.2827 64.8719 71.0228 65.0485 70.8312C65.2252 70.6396 65.4647 70.5319 65.7146 70.5319Z"
                        fill="#D5C2FF"
                    />
                </StyledNewWalletModalIcon>
                <StyledNewWalletModalTitleWrapper>
                    <StyledNewWalletModalTitle variant={TypographyTypes.H1}>
                        {topTitle}
                    </StyledNewWalletModalTitle>
                    <StyledNewWalletModalTitle variant={TypographyTypes.H1}>
                        {bottomTitle}
                    </StyledNewWalletModalTitle>
                </StyledNewWalletModalTitleWrapper>
                <StyledNewWalletModalTitle variant={TypographyTypes.LARGE}>
                    {__("modals.main-wallet.description")}
                </StyledNewWalletModalTitle>
                <ButtonLink
                    onClick={closeModal}
                    variant={ButtonTypes.PRIMARY}
                    text={__("modals.main-wallet.button")}
                ></ButtonLink>
            </StyledNewWalletModalContent>
        </StyledNewWalletModal>
    );
};
