import { useState, useCallback } from "react";
import { SortTypes, SortLevelTypes } from "components/organisms/modals/sort/sort.types";

export const useSort = ( defaultSort?: SortTypes ) => {
    const [ sort, setSort ] = useState<SortTypes | undefined>(defaultSort);
    const [ sortLevel, setLevel ] = useState<SortLevelTypes>(SortLevelTypes.NONE);

    const changeSort = useCallback(
        (type: SortTypes) => {
            if(type === sort) {
                switch(sortLevel) {
                    case SortLevelTypes.NONE: setLevel(SortLevelTypes.ASC); break;
                    case SortLevelTypes.ASC: setLevel(SortLevelTypes.DESC); break;
                    case SortLevelTypes.DESC: {
                        setLevel(SortLevelTypes.NONE);
                        setSort(undefined);
                    }; break;
                }
            } else {
                setSort(type);
                setLevel(SortLevelTypes.ASC);
            }
        },
        [sort, sortLevel],
    );

    return {
        sort,
        sortLevel,
        changeSort,
    };
};
