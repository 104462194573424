export enum DaterangeType {
    YESTERDAY = "yesterday",
    PREVIOUS_WEEK = "previous-week",
    THIS_MONTH = "this-month",
    PREVIOUS_MONTH = "previous-month",
    PAST_QUARTER = "past-quarter",
    PAST_YEAR = "past-year",
    ALL_TIME = "all-time",
    CUSTOM = "custom",
};
