import React from "react";
import { useTheme } from "components/providers/theme/theme.hooks";
import { StyledPreloader } from "./preloader.styled";

export const Preloader: React.FC = () => {
    const theme = useTheme();

    return (
        <StyledPreloader viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="48" cy="48" r="45" strokeWidth="6" stroke={theme.themeProps.gray?.[100]} />
            <mask id="path-2-inside-1_7158_2371" fill="white">
                <path d="M48 96C35.2696 96 23.0606 90.9429 14.0589 81.9411C5.05713 72.9394 -3.54136e-07 60.7304 0 48C3.54136e-07 35.2696 5.05713 23.0606 14.0589 14.0589C23.0606 5.05713 35.2696 -1.51808e-07 48 0V5.76C36.7973 5.76 26.0533 10.2103 18.1318 18.1318C10.2103 26.0533 5.76 36.7973 5.76 48C5.76 59.2027 10.2103 69.9466 18.1318 77.8682C26.0533 85.7897 36.7973 90.24 48 90.24L48 96Z"/>
            </mask>
            <path d="M48 96C35.2696 96 23.0606 90.9429 14.0589 81.9411C5.05713 72.9394 -3.54136e-07 60.7304 0 48C3.54136e-07 35.2696 5.05713 23.0606 14.0589 14.0589C23.0606 5.05713 35.2696 -1.51808e-07 48 0V5.76C36.7973 5.76 26.0533 10.2103 18.1318 18.1318C10.2103 26.0533 5.76 36.7973 5.76 48C5.76 59.2027 10.2103 69.9466 18.1318 77.8682C26.0533 85.7897 36.7973 90.24 48 90.24L48 96Z" stroke="url(#paint0_linear_7158_2371)" strokeWidth="12" mask="url(#path-2-inside-1_7158_2371)"/>
            <defs>
                <linearGradient id="paint0_linear_7158_2371" x1="17.5" y1="13.5" x2="48" y2="92" gradientUnits="userSpaceOnUse">
                    <stop stopColor={theme.themeProps.green?.[800]}/>
                    <stop offset="1" stopColor={theme.themeProps.green?.[800]} stopOpacity="0"/>
                </linearGradient>
            </defs>
        </StyledPreloader>
    );
};
