import React, { useState, ChangeEvent, useMemo, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Typography } from "../typography/typography.component";
import { TypographyTypes } from "../typography/typography.types";
import { decode } from "html-entities";
import { symbol } from "currency-symbol";
import { debounce } from "lodash";
import {
    StyledRangeBox,
    StyledRange,
    StyledInputBox,
    StyledNumber,
    StyledLabel,
} from "./range.styled";

export interface IRange {
    value: number[];
    currency?: string;
    max?: number;
    min?: number;
    step?: number;
    onChange: (value: number | number[]) => void;
}

export const changeRangeFilter = (value: number | number[]) => {
    if(Array.isArray(value)) {
        return value;
    } else {
        return [0, value];
    }
};

export const RangeSlider: React.FC<IRange> = ({
    value,
    min = 0,
    max = 2,
    step = 1,
    onChange,
    currency,
}) => {
    const [ valueNumber, setValueNumber ] = useState<any[]>(value);

    const amountStr = (amount: number) => Math.abs(amount).toLocaleString("en-US", { minimumFractionDigits: 2 });
    const checkValue = (text: string) => text.replace(/[^.\d]+/g,"").replace(/^([^.]*\.)|\./g, "$1");

    const checkValueMin = (val: string, current: number) => {
        const num = Math.trunc( +val * 100 ) / 100;
        if(num > current) {
            onChange([current, current]);
            setValueNumber([current, current]);
        } else {
            onChange([num, current]);
            setValueNumber([num, current]);
        }
    };
    const debouncedEventMin = useMemo(() => debounce(checkValueMin, 500), [onChange]);

    const checkValueMax = (val: string, current: number) => {
        const num = Math.trunc( +val * 100 ) / 100;
        if(num > max) {
            onChange([current, max]);
            setValueNumber([current, max]);
        } else if (current > num) {
            onChange([current, current]);
            setValueNumber([current, current]);
        } else {
            onChange([current, num]);
            setValueNumber([current, num]);
        }
    };
    const debouncedEventMax = useMemo(() => debounce(checkValueMax, 500), [onChange]);

    const changeValueMin = (text: string) => {
        const num = checkValue(text);
        setValueNumber((prev) => ([num, prev[1]]));
        debouncedEventMin(num, valueNumber[1]);
    };

    const changeValueMax = (text: string) => {
        const num = checkValue(text);
        setValueNumber((prev) => ([prev[0], num]));
        debouncedEventMax(num, valueNumber[0]);
    };

    const debouncedChange = useMemo(() => debounce(onChange, 500), [onChange]);
    const onChangeRange = (num: number | number[]) => {
        debouncedChange(num);
        setValueNumber(changeRangeFilter(num));
    };

    useEffect(() => {
        setValueNumber(value);
    }, [value]);

    return (
        <StyledRangeBox>
            <StyledInputBox>
                <StyledNumber
                    id="number-min"
                    value={valueNumber[0]}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => changeValueMin((event.target.value))}
                />
                <StyledLabel htmlFor="number-min">
                    {0 > valueNumber[0] && "-"}
                    {currency &&
                        <Typography variant={TypographyTypes.BASE}>
                            {decode(symbol(currency))}
                        </Typography>
                    }
                    {amountStr(valueNumber[0])}
                </StyledLabel>
            </StyledInputBox>
            <StyledInputBox>
                <StyledNumber
                    $right
                    id="number-max"
                    value={valueNumber[1]}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => changeValueMax((event.target.value))}
                />
                <StyledLabel htmlFor="number-max" $right>
                    {0 > valueNumber[1] && "-"}
                    {currency &&
                        <Typography variant={TypographyTypes.BASE}>
                            {decode(symbol(currency))}
                        </Typography>
                    }
                    {amountStr(valueNumber[1])}
                </StyledLabel>
            </StyledInputBox>
            <StyledRange>
                <Slider
                    min={min}
                    max={max}
                    value={valueNumber}
                    defaultValue={valueNumber}
                    onChange={onChangeRange}
                    step={step}
                    allowCross={false}
                    range
                />
            </StyledRange>
        </StyledRangeBox>
    );
};
