import React, { FC } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { IconTypes } from "components/atoms/icon/icon.types";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { InfoBox, IInfo } from "components/molecules/info-box/info-box.component";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes, ButtonSize } from "components/atoms/button-link/button-link.types";
import { LabelsItem } from "components/atoms/labels-item/labels-item.component";
import { LabelsItemTypes } from "components/atoms/labels-item/labels-item.types";
import { IAccount } from "lib/hooks/accountUser";
import { openAccountDataLayer } from "helpers/dataLayer/open-account";
import {
  StyledInfoHeader,
  StyledTitle,
  StyledDesc,
  StyledInfoBox,
  StyledMenuModal,
  StyledTopBox,
  StyledIconBox,
  StyledAccountIcon,
  StyledButtonClose,
  StyledButtonIcon,
  StyledMenuBox,
  StyledButtonBox,
  StyledBonuxBox,
} from "./modal.styled";

export interface AccountDetailsModalProps {
  closeModal: () => void;
  data: IAccount | undefined;
  user_id: number;
}

export const AccountDetailsModal: FC<AccountDetailsModalProps> = ({ closeModal, data, user_id }) => {
  const [__] = useTranslation();
  const { push } = useRouter();

  if (!data) {
    return null;
  }

  const { loginSid, login, currency, leverage } = data;

  const goToDetails = () => {
    push(`/account/${loginSid}`).then(() => {
      if (closeModal) {
        closeModal();
      }
    }).catch((err) => console.error(err));
  };

  const goToDeposit = () => {
    push("/deposit").then(() => {
      if (closeModal) {
        closeModal();
      }
    }).catch((err) => console.error(err));
  };

  const goToMt5 = () => {
    push(`/mt5-web-trader/${loginSid}`).then(() => {
      if (closeModal) {
        closeModal();
      }
    }).catch((err) => console.error(err));
  };

  const server = "mt5.amega.global";
  const isLiveAccount = Boolean(data?.type?.category === "live");

  if(isLiveAccount) {
    openAccountDataLayer(user_id, loginSid, "live");
  } else {
    openAccountDataLayer(user_id, loginSid, "demo");
  }

  const titleAccount = (type: string | undefined) => {
    const accountTypeTitle = type ? type.toLowerCase() : "";
    switch(true) {
          case accountTypeTitle.includes("one account"): {
            return {
                title: __("components.one-account"),
                icon: IconTypes.ONE_STANDARD,
            };
        };
        case accountTypeTitle.toLocaleLowerCase().includes("promo"): {
          return {
              title: __("components.promo"),
              icon: IconTypes.ACCOUNT_STANDARD,
          };
        };
        case accountTypeTitle.includes("contest"): {
            return {
                title: __("components.demo-contest"),
                icon: IconTypes.DEMO_CONTEST,
            };
        };
        case accountTypeTitle.includes("standard"): {
            return {
                title: __("components.standard-account"),
                icon: IconTypes.ACCOUNT_STANDARD,
            };
        };
        case accountTypeTitle.includes("test"): {
            return {
                title: __("components.test-account"),
                icon: IconTypes.TRY_OUT_DEMO,
            };
        };
        case accountTypeTitle.includes("raw"): {
            return {
                title: __("components.raw-account"),
                icon: IconTypes.ACCOUNT_RAW,
            };
        };
        case accountTypeTitle.includes("islamic"): {
            return {
                title: __("components.islamic-account"),
                icon: IconTypes.ACCOUNT_ISLAMIC,
            };
        };
        case accountTypeTitle.includes("demo"): {
            return {
                title: __("components.demo-account"),
                icon: IconTypes.TRY_OUT_DEMO,
            };
        };
        default: return {
            title: __("components.standard-account"),
            icon: IconTypes.ACCOUNT_STANDARD,
        };
    }
  };
  const accountDetails = titleAccount(data?.type.title);
  const isBonus = data ? data?.type?.title.toLocaleLowerCase().includes("bonus") : false;

  const infoList: IInfo[] = [{
    title: __("account.details.modals.details.server"),
    text: server,
    isCopy: true,
    copyText: server,
  }, {
    title: __("account.details.modals.details.login"),
    text: login,
    isCopy: true,
    copyText: login,
  }, {
    title: __("account.details.modals.details.password"),
    text: "**********",
  }, {
    title: __("account.details.modals.details.leverage"),
    text: leverage ? (`1:${leverage}`) : "",
  }, {
    title: __("account.details.modals.details.currency"),
    text: currency,
  }];

  const headerInfoBox = (
    <StyledInfoHeader>
      <StyledTitle variant={TypographyTypes.H4}>{__("account.details.modals.details.account-details")}</StyledTitle>
      <ButtonLink
        variant={ButtonTypes.SECONDARY}
        size={ButtonSize.MINI}
        text={__("account.details.modals.details.view-account")}
        onClick={goToDetails}
      />
    </StyledInfoHeader>
  );

  return (
    <StyledMenuModal closeModal={closeModal}>
      <StyledTopBox>
        <StyledIconBox>
          <StyledAccountIcon
            icon={accountDetails.icon}
            $type={accountDetails.icon}
          />
          <StyledBonuxBox>
            <Typography variant={TypographyTypes.H3}>{accountDetails.title}</Typography>
            {isBonus && (
              <LabelsItem type={LabelsItemTypes.BONUS}>{__("components.bonus")}</LabelsItem>
            )}
          </StyledBonuxBox>
        </StyledIconBox>
        <StyledDesc variant={TypographyTypes.LARGE}>
          {__("account.details.modals.details.description")}
        </StyledDesc>
        <StyledButtonClose onClick={closeModal}>
          <StyledButtonIcon icon={IconTypes.CLOSE} />
        </StyledButtonClose>
      </StyledTopBox>
      <StyledMenuBox>
        <StyledInfoBox>
          <InfoBox list={infoList} header={headerInfoBox} />
        </StyledInfoBox>
      </StyledMenuBox>
      <StyledButtonBox>
        <ButtonLink
          variant={ButtonTypes.PRIMARY}
          text={isLiveAccount ? __("account.details.modals.details.fund-account") : __("account.details.modals.details.start-trading")}
          onClick={isLiveAccount ? goToDeposit : goToMt5}
        />
      </StyledButtonBox>
    </StyledMenuModal>
  );
};
