import darkTheme from "./design-tokens-client-area/theme/dark.json";
import lightTheme from "./design-tokens-client-area/theme/light.json";
import color from "./design-tokens-client-area/colors.json";
import typography from "./design-tokens-client-area/type.json";

/* Basic style */
export const typographyStyle = typography;
export const lightColor = lightTheme;
export const darkColor = darkTheme;

/* Additional style */
export const listColor = color.core;
