import styled, { css, keyframes } from "styled-components";
import { Modals } from "../modals.component";
import { StyledTitleBox } from "../modals.styled";
import { Icon } from "components/atoms/icon/icon.component";
import { spacing } from "helpers/conversion-style";

interface IProps {
    $active: boolean;
    $error: boolean;
}

const fadeIn = keyframes`
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(-100px);
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    0% {
        transform: translateY(-100px);
        opacity: 1;
    }

    100% {
        transform: translateY(-50px);
        opacity: 0;
    }
`;

const lineMove = keyframes`
    0% {
        right: 0;
    }

    100% {
        right: 100%;
    }
`;

export const StyledNotification = styled(Modals)<IProps>`
    max-width: 328px;
    padding: ${spacing(16)} ${spacing(48)} ${spacing(16)} ${spacing(16)};
    background-color: ${({theme, $error}) => $error ? theme.red?.[300] : theme.green?.[300]};
    border: 1px solid ${({theme, $error}) => $error ? theme.red?.[300] : theme.gray?.[200]};
    filter: drop-shadow(0 4px 8px ${({theme, $error}) => $error ? theme.popup?.error : theme.popup?.success});
    color: ${({theme, $error}) => $error ? theme.red?.[1100] : theme.green?.[1100]};
    border-radius: 16px;
    top: auto;
    opacity: 0;
    bottom: 0;
    margin: auto;
    overflow: hidden;
    ${({$active}) => $active ? css`
        animation: 0.5s ${fadeIn} linear forwards;
        animation-delay: 0.5s;
    `
    : css`
        animation: 0.5s ${fadeOut} linear forwards;
    `
    }
    gap: 4px;
    ${StyledTitleBox} {
        display: none;
    }
    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 100%;
        height: 4px;
        background-color: ${({theme, $error}) => $error ? theme.red?.[800] : theme.green?.[800]};
        animation: 3s ${lineMove} linear forwards;
        animation-delay: 0.5s;
    }
`;

export const StyledNotificationTitle = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 48px 1fr;
    gap: 12px;
    padding-bottom: ${spacing(32)};
`;

export const StyledNotificationIcon = styled.div`
    position: relative;
    width: 48px;
    height: 48px;
`;

export const StyledNotificationClose = styled.button`
    cursor: pointer;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledCloseIcon = styled(Icon)<{$error: boolean}>`
    width: 24px;
    min-width: 24px;
    height: 24px;
    stroke: ${({theme, $error}) => $error ? theme.red?.[1100] : theme.green?.[1100]};
`;
