import { Level, levels } from "pino";
import { AbstractTransport } from "../transport.class";

export class MultiTransport extends AbstractTransport {
    readonly enabled: boolean;
    private transports: AbstractTransport[];
    constructor(...transports: AbstractTransport[]) {
        super({ level: Math.min(...transports.map((t) => t.levelValue)) });

        this.transports = transports.filter((transport) => transport.enabled);
        this.enabled = !!this.transports.length;
    }

    sendMerged(mergedLogEvent: Record<string, unknown>, level: Level): void {
        this.transports
            .filter(
                (transport) =>
                    transport.levelValue <=
                    (levels.values[level] || Number.MAX_SAFE_INTEGER),
            )
            .forEach((transport) =>
                transport.sendMerged(mergedLogEvent, level),
            );
    }
}
