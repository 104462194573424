import { levels } from 'pino';
import { mapKeys, set, fromPairs } from 'lodash';

const defaultSerializerRules = [
    {
        field: "context",
        toType: "string",
    },
    {
        field: "hostname",
        toType: "string",
    },
    {
        field: "msg",
        toType: "string",
    },
    {
        field: "type",
        toType: "string",
    },
    {
        field: "userId",
        toType: "string",
    },
    {
        field: "level",
        serializer: normalizeLevel,
    },
    {
        field: "pid",
        toType: "number",
    },
    {
        field: "time",
        toType: "number",
    },
    {
        field: "@timestamp",
        toType: "dateTimeString",
    },
    {
        field: "req",
        serializer: (object) => {
            if ("url" in object && "headers" in object && "method" in object) {
                return object;
            }
            return undefined;
        },
    },
    { field: "res", toType: "as-is" },
    {
        field: "responseTime",
        toType: "number",
    },
    {
        field: "revision",
        toType: "string",
    },
    {
        field: "clientPayload",
        toType: "as-is",
    },
    {
        field: /(Num(ber)?|Amount)$/,
        toType: "number",
    },
    {
        field: "BASE_ENV",
        toType: "as-is",
    },
    {
        field: /(Str(ing)?|Name|NAME|SHA|Sha|_ENV|PATH|Path|_CPU|VERSION)$/,
        toType: "string",
    },
    {
        field: /(G|U)UIDS?$/,
        toType: "as-is",
    },
    { field: "location", toType: "as-is" },
];
function normalizeLevel(level) {
    if (typeof level !== "number") {
        return level;
    }
    return levels.labels[level] || String(level);
}

const elasticsearchDefaultSerializerFactory = ({ rules = [], restKey = "_rest", restStringify = true, keyMap, } = {}) => (object) => {
    keyMap = Object.assign({ time: "@timestamp" }, keyMap);
    rules = [...defaultSerializerRules, ...rules];
    return applyRules(mapKeys(object, (_value, key) => {
        return (keyMap === null || keyMap === void 0 ? void 0 : keyMap[key]) || key;
    }), rules, restKey, restStringify);
};
function applyRules(object, rules, restKey, restStringify) {
    const newObject = Object.keys(object).reduce((acc, key) => {
        const rule = rules.find((r) => typeof r.field === "string" ? key === r.field : r.field.test(key));
        const value = object[key];
        if (rule) {
            acc[key] = (() => {
                if ("toType" in rule) {
                    switch (rule.toType) {
                        case "string":
                            return toString(value);
                        case "number":
                            return toNumber(value);
                        case "as-is":
                            if (restKey && value[restKey]) {
                                set(acc, `${restKey}.${key}.${restKey}`, value[restKey]);
                                return Object.assign(Object.assign({}, value), { [restKey]: undefined });
                            }
                            return value;
                        case "dateTimeString":
                            return new Date(value).toISOString();
                    }
                }
                else if ("serializer" in rule) {
                    return rule.serializer(value);
                }
            })();
        }
        else if (restKey) {
            if (isLogError(value)) {
                if (!acc.err) {
                    const { name, message, msg } = value;
                    acc.err = { name, message, msg };
                }
            }
            set(acc, `${restKey}.${key}`, value);
        }
        return acc;
    }, {});
    if (restKey && newObject[restKey] && restStringify) {
        try {
            newObject[restKey] = JSON.stringify(newObject[restKey], null, 2);
        }
        catch (ex) {
            console.error("Cannot stringify restKey", ex);
            delete newObject[restKey];
        }
    }
    return newObject;
}
function toNumber(value) {
    return Number(value);
}
function toString(value) {
    if (typeof value === "string") {
        return value;
    }
    try {
        return JSON.stringify(value);
    }
    catch (error) {
        console.error("elasticsearchDefaultSerializer cannot stringify value", error);
        return String(value);
    }
}
function isLogError(value) {
    return (value &&
        (value instanceof Error ||
            (value.stack && (value.message || value.msg))));
}

const redactSecrets = (object, censor = "***", depth = 3) => {
    return fromPairs(Object.entries(object).map(([key, value]) => {
        if (isSecret(key) ||
            (typeof value === "string" && isSecret(value, VALUES))) {
            return [key, censor];
        }
        if (depth > 0 &&
            value &&
            typeof value === "object" &&
            Object.prototype.hasOwnProperty.call(object, key)) {
            return [key, redactSecrets(value, censor, depth - 1)];
        }
        return [key, value];
    }));
};
const KEYS = [
    /passw(or)?d/i,
    /^pwd?$/i,
    /^pass$/i,
    /secret/i,
    /token/i,
    /api[-._]?key/i,
    /session[-._]?id/i,
    /^connect\.sid$/,
    /(set-?)?cookies?/i,
    /authorization/i,
    /(acc?ess?|session|consumer|factor|totp|fa|auth)[\s-_]?(key|code)/i,
    /captcha/i,
];
const VALUES = KEYS.map((regExp) => {
    return new RegExp(regExp.source.replace(/^\^/, "\"").replace(/\$$/, "\""), regExp.flags);
});
const isSecret = (key, regExps = KEYS) => regExps.some((regExp) => regExp.test(key));

var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["EMAIL_IS_ALREADY_TAKEN"] = 1] = "EMAIL_IS_ALREADY_TAKEN";
    ErrorCode[ErrorCode["NO_ATTEMPTS_LEFT"] = 2] = "NO_ATTEMPTS_LEFT";
    ErrorCode[ErrorCode["TEMPORARY_CODE_INVALID"] = 3] = "TEMPORARY_CODE_INVALID";
    ErrorCode[ErrorCode["TOTP_TOKEN_INVALID"] = 4] = "TOTP_TOKEN_INVALID";
    ErrorCode[ErrorCode["TOTP_TOKEN_REQUIRED"] = 5] = "TOTP_TOKEN_REQUIRED";
    ErrorCode[ErrorCode["UNKNOWN_PLACE_CONFIRMATION_CODE_REQUIRED"] = 6] = "UNKNOWN_PLACE_CONFIRMATION_CODE_REQUIRED";
})(ErrorCode || (ErrorCode = {}));

function isAxiosError(error) {
    return error && error.isAxiosError;
}

export { ErrorCode, defaultSerializerRules, elasticsearchDefaultSerializerFactory, isAxiosError, isLogError, redactSecrets };
