import { symbol } from "currency-symbol";
import { decode } from "html-entities";

export const getAmountWithCurrencySymbol = (
    currency: string | undefined,
    value: number | undefined,
): string => {
    const sanitizedCurrency = currency || "";
    const htmlSymbol = symbol(sanitizedCurrency);
    const currencySymbol = decode(htmlSymbol);
    const amount = value || 0;
    const amountSign = (amount < 0) ? "-" : "";
    const amountStr = Math.abs(amount).toLocaleString("en-US", { minimumFractionDigits: 2 });
    return `${amountSign}${currencySymbol || sanitizedCurrency} ${amountStr}`;
};
