import styled from "styled-components";
import { Typography } from "components/atoms/typography/typography.component";
import { spacing } from "helpers/conversion-style";
import { StyledTitleBox } from "../modals.styled";
import { Modals } from "../modals.component";

export const StyledDeleteModals = styled(Modals)`
    max-width: 550px;
    padding: ${spacing(32)} ${spacing(16)} ${spacing(16)};
    ${StyledTitleBox} {
        display: none;
    }
`;

export const StyledButtonList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StyledDeleteText = styled(Typography)`
    padding-bottom: ${spacing(32)};
`;
