export enum SortTypes {
    DATE = "_closetime_",
    SYMBOL = "_symbol_",
    LOTS = "_lots_",
    AMOUNT = "_amount_",
    BALANCE = "balance",
    COMMISSION = "commission",
    DEPOSIT = "deposit",
    WITHDRAWALS = "withdrawals",
    TOTAL_TRADED_LOTS = "totalTradedLots",
    PL_CLOSED = "referrals_pl_closed",
    VERIFIED = "isVerified",
    ID = "id",
    CREATION_DATE = "date",
    TYPE = "type",
    STATUS = "status",
    PAYMENT_SYSTEM = "payment",
};

export enum SortLevelTypes {
    ASC = "asc",
    DESC = "desc",
    NONE = "none",
};
