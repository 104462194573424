import styled, { css } from "styled-components";
import { Icon } from "components/atoms/icon/icon.component";
import { SortLevelTypes } from "components/organisms/modals/sort/sort.types";
import { StylesToLarge, StylesToBase, StylesToHuge, StylesToSmall } from "components/atoms/typography/typography.styled";
import { spacing } from "helpers/conversion-style";

export const StyledProviderImage = styled.img`
    position: relative;
    width: 50px;
    max-width: 100%;
    height: auto;
    border-radius: 50%;
    overflow: hidden;
`;

export const StyledProviderDesc = styled.div`
    ${StylesToLarge};

    display: flex;
    align-items: center;
    gap: 8px;

    &:before {
        content: "";
        min-width: 28px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 4px solid ${({theme}) => theme.gray?.[300]};
        transition: 0.3s ease-in-out;
    }
`;

export const StyledProviderRadioLabel = styled.label`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 8px 16px;
    gap: 8px;
    color: ${({ theme }) => theme.text?.default};
    cursor: pointer;
    transition: 0.3s ease-in-out;
`;

export const StyledAbbr = styled.div`
    ${StylesToSmall};

    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.purple?.[100]};
    color: ${({theme}) => theme.purple?.[800]};
`;

export const StyledProviderRadioBox = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
`;

export const StyledRadioBoxWithdrawal = styled.div`
    position: relative;
    padding: ${spacing(4)};
    border-radius: 36px;
    background-color: ${({theme}) => theme.gray?.[100]};
    & + & {
      margin-top: 4px;
    }
`;

export const StyledRadioLabelWithdrawal = styled.label`
    ${StylesToBase};
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: ${({theme}) => theme.text?.default};
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:before {
        content: "";
        min-width: 20px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 4px solid ${({theme}) => theme.gray?.[400]};
        transition: 0.3s ease-in-out;
        background-color: ${({theme}) => theme.bg.page};
    }
`;

export const StyledRadioBox = styled.div`
    position: relative;
    padding: ${spacing(12)} 0;
`;

export const StyledRadioLabel = styled.label`
    ${StylesToLarge};
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: ${({theme}) => theme.text?.default};
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:before {
        content: "";
        min-width: 32px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 4px solid ${({theme}) => theme.gray?.[300]};
        transition: 0.3s ease-in-out;
    }
`;

export const StyledLabelPayment = styled.label<{$full?: boolean}>`
    ${StylesToLarge};
    display: ${({$full}) => $full ? "flex" : "inline-flex"};
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    padding: ${spacing(12)} ${spacing(16)};
    background-color: ${({theme}) => theme.gray?.[100]};
    transition: 0.3s ease-in-out;
    color: ${({theme}) => theme.text?.default};
    cursor: pointer;
    white-space: pre;
`;

export const StyledLabelDate = styled.label<{$full?: boolean}>`
    ${StylesToSmall};
    display: ${({$full}) => $full ? "flex" : "inline-flex"};
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    padding: ${spacing(4)} ${spacing(8)};
    border: 1px solid ${({theme}) => theme.gray?.[100]};
    background-color: ${({theme}) => theme.gray?.[100]};
    transition: 0.3s ease-in-out;
    color: ${({theme}) => theme.text?.default};
    cursor: pointer;
`;

export const StyledPaymentLogo = styled.svg`
    fill: ${({theme}) => theme.gray?.[800]};
    stroke: ${({theme}) => theme.gray?.[800]};
`;

export const StyledLabelButton = styled.label`
    ${StylesToBase};
    padding: ${spacing(8)} ${spacing(20)};
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    background-color: inherit;
    transition: 0.3s ease-in-out;
    color: ${({theme}) => theme.text?.default};
    cursor: pointer;
`;

export const StyledLabelDeposit = styled.label`
    ${StylesToBase};
    padding: ${spacing(16)};
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    column-gap: 16px;
    cursor: pointer;
`;

export const StyledLabelCircle = styled.span`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 8px solid ${({theme}) => theme.gray?.[300]};
    box-sizing: border-box;
    transition: 0.3s ease-in-out;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${({theme}) => theme.bg.page};
        box-shadow: 0 0 8px 0 ${({theme}) => theme.checkbox?.default};
        transition: 0.3s ease-in-out;
    }
`;

export const StyledNameBox = styled.span`
    display: flex;
    align-items: center;
    column-gap: ${spacing(8)};
`;

export const StyledLabelDepositName = styled.span`
    ${StylesToHuge};
    color: ${({theme}) => theme.text?.default};
`;

export const StyledLabelDepositBonus = styled.span`
    ${StylesToSmall};
    padding: ${spacing(4)} ${spacing(8)};
    border-radius: 12px;
    background-color: ${({ theme }) => theme.green?.[100]};
    border: 1px solid ${({ theme }) => theme.green?.[200]};
    color: ${({ theme }) => theme.green?.[900]};
`;

export const StyledLabelDepositBox = styled.span`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
`;

export const StyledLabelDepositInfo = styled.span`
    color: ${({ theme }) => theme.text?.muted};
    position: relative;
    &:not(:last-child) {
        margin-right: ${spacing(12)};
        &::before {
            content: "";
            right: -7px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            width: 3%;
            height: 3px;
            background-color: ${({ theme }) => theme.text?.muted};
            border-radius: 50%;
        }
    }
`;

export const StyledIcon = styled(Icon)`
    width: 24px;
    min-width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
    fill: ${({theme}) => theme.green?.dark};
    stroke: ${({theme}) => theme.green?.dark};
    opacity: 0;
    transform: scale(0);
`;

export const StyledRadio = styled.input<{level?: SortLevelTypes, $dark?: boolean}>`
    display: none;
    &:checked {
        + ${StyledRadioLabelWithdrawal} {
            &:before {
                background-color: ${({theme}) => theme.green?.[100]};
                border-color: ${({theme}) => theme.green?.[900]};
            }
        }

        + ${StyledProviderRadioLabel} {
            background-color: ${({theme, $dark }) => $dark ? theme.green?.[1100] : theme.green?.[100]};

            ${StyledProviderDesc} {
              &:before {
                  background-color: ${({theme, $dark}) => $dark ? theme.green?.[1100] : theme.green?.[100]};
                  border-color: ${({theme}) => theme.green?.[800]};
              }
            }
        }

        + ${StyledRadioLabel} {
            &:before {
                background-color: ${({theme}) => theme.green?.[200]};
                border-color: ${({theme}) => theme.green?.[800]};
            }
        }
        + ${StyledLabelPayment} {
            background-color: ${({theme}) => theme.components?.success_bg};
            color: ${({theme}) => theme.components?.success_text};
            ${StyledPaymentLogo} {
                fill: ${({theme}) => theme.components?.success_text};
                stroke: ${({theme}) => theme.components?.success_text};
            }
        }
        + ${StyledLabelDate} {
            background-color: ${({theme}) => theme.components?.success_bg};
            color: ${({theme}) => theme.components?.success_text};
            border-color: ${({theme}) => theme.components?.success_text};
        }
        + ${StyledLabelButton} {
            background-color: ${({theme}) => theme.green?.[800]};
            color: ${({theme}) => theme.green?.[1500]};
            ${StyledIcon} {
                opacity: 1;
                ${({ level }) => {
                    switch (level) {
                        case SortLevelTypes.ASC:
                            return css`
                                transform: scale(1, -1);
                            `;
                        case SortLevelTypes.DESC:
                            return css`
                                transform: scale(1);
                            `;
                    }
                }}
            }
        }
        + ${StyledLabelDeposit} {
            ${StyledLabelCircle} {
                border-color: ${({theme}) => theme.green?.[800]};
                &:before {
                    background-color: ${({theme}) => theme.green?.[100]};
                    box-shadow: 0 0 8px 0 ${({theme}) => theme.checkbox?.checked};
                }
            }
        }
    }
`;

