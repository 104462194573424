import React from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import {
    StyledConnectModals,
    StyledConnectImage,
    StyledConnectTitle,
    StyledConnectText,
} from "./connect-info-modals.styled";

export interface IProps {
    closeModal: () => void;
}

export const ConnectInfoModals: React.FC<IProps> = ({
    closeModal,
}) => {
    const [__] = useTranslation();

    return (
        <StyledConnectModals closeModal={closeModal}>
            <StyledConnectImage>
                <Image
                    src="/svg/error.svg"
                    alt={"Icon connect"}
                    layout="fill"
                />
            </StyledConnectImage>
            <StyledConnectTitle variant={TypographyTypes.H1}>{__("connect.modals.title")}</StyledConnectTitle>
            <StyledConnectText variant={TypographyTypes.LARGE}>{__("connect.modals.text")}</StyledConnectText>
            <ButtonLink
                variant={ButtonTypes.PRIMARY}
                onClick={closeModal}
                text={__("auth.login.signin")}
            />
        </StyledConnectModals>
    );
};
