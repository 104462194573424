import styled, { css } from "styled-components";
import { CustomLink } from "components/atoms/custom-link/custom-link.component";
import { Icon } from "components/atoms/icon/icon.component";
import { spacing } from "helpers/conversion-style";
import { StyledContainer } from "styles/basic.styled";

const ButtonCSS = css`
    width: 48px;
    height: 48px;
    background-color: ${({ theme }) => theme.gray?.[100] };
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const StyledTitleBox = styled.div`
    padding: ${spacing(16)} 0;
`;

export const StyledTitleContainer = styled(StyledContainer)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledTitleLink = styled(CustomLink)`
    ${ButtonCSS}
`;

export const StyledTitleButton = styled.button`
    ${ButtonCSS}
`;

export const StyledButtonIcon = styled(Icon)`
    width: 24px;
    min-width: 24px;
    height: 24px;
    stroke: ${({ theme }) => theme.text?.default };
`;
