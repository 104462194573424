import { Buffer } from "buffer";
import { logRequest, logResponse, logError } from "helpers/loggerData";

export const deleteDeviceUser = async (
    id: string,
    token: string,
): Promise<any | undefined> => {

    const logData = {
      desc: "Delete Info Device User",
      method: "POST",
      url: "/track/api/v2/entity",
    };
    const { url, method } = logData;

    try {
        const body = {
            type: "person",
            identifiers: {
                id,
            },
            action: "delete_device",
            device: {
                token,
            },
        };

        logRequest(logData, body);

        const siteApi = `${process.env.TRACK_SITE_ID}:${process.env.TRACK_API_KEY}`;
        const secret = new Buffer(siteApi).toString("base64");

        const response = await fetch(url,
            {
                method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Basic ${secret}`,
                },
                body: JSON.stringify(body),
            },
        );

        logResponse(logData, response);

        if ( response.ok ) {
            const json = await response.json();
            return json;
        }
    } catch (error: unknown) {
        console.error(error);
        logError(logData, error);
    }
};
