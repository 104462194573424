import React, { FC } from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { IconTypes } from "components/atoms/icon/icon.types";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import {
    StyledMenuModal,
    StyledTopBox,
    StyledIconBox,
    StyledAccountIcon,
    StyledButtonClose,
    StyledButtonIcon,
    StyledMenuBox,
    StyledMenuItem,
    StyledMenuIcon,
    StyledMenuLink,
} from "./menu-modal.styled";

interface IModalMenu {
    icon: IconTypes;
    desc: string;
    href: string;
}

export interface AccountDetailsMenuModalProps {
    closeModal: () => void;
    icon: IconTypes;
    title: string;
    sid: string;
}

export const AccountDetailsMenuModal: FC<AccountDetailsMenuModalProps> = ({
    closeModal,
    icon,
    title,
    sid,
}) => {
    const [__] = useTranslation();

    const { defaultLocale, locale } = useRouter() || {};
    const localeUrl =
        defaultLocale && locale && defaultLocale !== locale ? `/${locale}` : "";

    const modalMenu: IModalMenu[] = [
        // {
        //   icon: IconTypes.TRANSFER_MONEY,
        //   desc: __("account.details.transfer-money"),
        //   href: `${localeUrl}/transfer`,
        // },
        {
            icon: IconTypes.CHANGE_TRADING,
            desc: __("account.details.change-trading-password"),
            href: `${localeUrl}/change-trading-password/${sid}`,
        },
        {
            icon: IconTypes.CHANGE_LEVERAGE,
            desc: __("account.details.change-leverage"),
            href: `${localeUrl}/account/change-leverage/${sid}`,
        },
    ];

    return (
        <StyledMenuModal closeModal={closeModal}>
            <StyledTopBox>
                <StyledIconBox>
                    <StyledAccountIcon icon={icon} $type={icon} />
                    <Typography variant={TypographyTypes.H3}>
                        {title}
                    </Typography>
                </StyledIconBox>
                <StyledButtonClose onClick={closeModal}>
                    <StyledButtonIcon icon={IconTypes.CLOSE} />
                </StyledButtonClose>
            </StyledTopBox>
            <StyledMenuBox>
                {modalMenu.map((item, i) => (
                    <StyledMenuItem key={`${item.icon}-${i}`}>
                        <StyledMenuLink href={item.href}>
                            <StyledMenuIcon icon={item.icon} />
                            <Typography variant={TypographyTypes.BASE}>
                                {item.desc}
                            </Typography>
                        </StyledMenuLink>
                    </StyledMenuItem>
                ))}
            </StyledMenuBox>
        </StyledMenuModal>
    );
};
