import styled from "styled-components";
import { Icon } from "components/atoms/icon/icon.component";
import { spacing } from "helpers/conversion-style";
import { CSVLink } from "react-csv";
import { Modals } from "../modals.component";

export const StyledCSVModals = styled(Modals)`
    max-width: 550px;
    padding-bottom: ${spacing(16)};
`;

export const StyledCSVButton = styled(CSVLink)`
    padding: ${spacing(12)} 0;
    background: none;
    border: none;
    cursor: pointer;
    display: grid;
    align-items: center;
    grid-template-columns: 24px 1fr;
    gap: 16px;
    color: ${({ theme }) => theme.text?.default };
`;

export const StyledCSVIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.text?.default };
`;
