import React, { FC } from "react";
import { useTranslation } from "next-i18next";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";
import {
    StyledAccountDetailsModals,
    StyledTopBox,
    StyledContentBox,
    StyledButtonBox,
    StyledTitle,
    StyledText,
} from "./server-info-modal.styled";

export interface AccountDetailsServerInfoModalProps {
    closeModal: () => void;
}

export const AccountDetailsServerInfoModal: FC<AccountDetailsServerInfoModalProps> = ({ closeModal }) => {
    const [__] = useTranslation();

    return (
        <StyledAccountDetailsModals closeModal={closeModal}>
            <StyledTopBox>
                <StyledTitle variant={TypographyTypes.H2}>
                    {__("account.details.modals.info.title")}
                </StyledTitle>
            </StyledTopBox>
            <StyledContentBox>
                <StyledText variant={TypographyTypes.BASE}>
                    {__("account.details.modals.info.desc")}
                </StyledText>
            </StyledContentBox>
            <StyledButtonBox>
                <ButtonLink
                    variant={ButtonTypes.SECONDARY}
                    text={__("components.close")}
                    onClick={closeModal}
                />
            </StyledButtonBox>
        </StyledAccountDetailsModals>
    );
};
