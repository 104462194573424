import { appWithTranslation, i18n } from "next-i18next";
import { AppProps } from "next/app";
import { useEffect, useMemo } from "react";
import { I18NextContext } from "./i18next.context";

const I18NextProvider: React.FC<React.PropsWithChildren<AppProps>> = (
    props,
) => {
    const dir = useDir(props);

    useEffect(() => {
        if (dir && document.documentElement.dir !== dir) {
            document.documentElement.dir = dir;
        } else if (!dir && document.documentElement.dir) {
            document.documentElement.removeAttribute("dir");
        }
    }, [dir]);

    const value = useMemo(
        () => ({
            dir,
        }),
        [dir],
    );

    return (
        <I18NextContext.Provider value={value}>
            {props.children}
        </I18NextContext.Provider>
    );
};

export default appWithTranslation(I18NextProvider);

function useDir({ pageProps, router }: Omit<AppProps, "Component">) {
    const dir = useMemo(() => {
        return pageProps._nextI18Next ? i18n?.dir(router.locale) : undefined;
    }, [pageProps._nextI18Next, router.locale]);
    return dir;
}
