import { isLogError, redactSecrets } from "@local/shared";
import { pick } from "lodash";
import { LogEvent } from "pino";
import { v4 } from "uuid";

export function mergeLogEvent(
    { level, bindings, messages, ts }: LogEvent,
    additionalBindings?: Record<string, any>,
) {
    const [mergeObject, msg] = [
        ...messages,
        ...bindings,
        additionalBindings,
        { time: ts, level: level.label },
    ].reduce(
        (memo, value) => {
            if (typeof value === "object") {
                if (isLogError(value) && !memo[0].err) {
                    value = { err: value };
                }
                memo[0] = { ...memo[0], ...value };
            } else {
                memo[1].push(String(value));
            }
            return memo;
        },
        [{}, []] as [Record<string, any>, string[]],
    );
    return redactSecrets({
        ...mergeObject,
        msg: msg.join(" "),
    });
}

const windowGuid = v4();
export function getGuids() {
    if (typeof window === "undefined") {
        return {};
    }
    const key = "_user_guid";
    return {
        permanentGUID: getStorageItem("localStorage", key, v4),
        sessionGUID: getStorageItem("sessionStorage", key, v4),
        windowGUID: windowGuid,
    };
}

function getStorageItem(
    storageName: "localStorage" | "sessionStorage",
    key: string,
    defaultValue: () => string,
) {
    try {
        const storage = window[storageName];
        let item = storage.getItem(key);
        if (!item) {
            storage.setItem(key, (item = defaultValue()));
        }
        return item;
    } catch (ex) {
        console.error(ex);
    }
}

export function getLocation() {
    try {
        return {
            ...pick(location, "hostname", "href", "pathname"),
            ancestorOrigins:
                (location.ancestorOrigins &&
                    Object.values(location.ancestorOrigins).join()) ||
                undefined,
        };
    } catch (ex) {
        console.error(ex);
        return undefined;
    }
}
