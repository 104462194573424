import styled from "styled-components";
import { spacing } from "helpers/conversion-style";
import { Icon } from "components/atoms/icon/icon.component";
import { Modals } from "../modals.component";

export const StyledCashbackHistoryModals = styled(Modals)`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
    border-radius: 0;
    max-height: 100%;
    display: flex;
    flex-direction: column;
`;

export const StyledSectionHead = styled.section`
    padding: ${spacing(16)} 0;
`;

export const StyledButton = styled.button`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({theme}) => theme.gray?.[100]};
    cursor: pointer;
    border: none;
`;

export const StyledTitleBox = styled.div`
    margin-top: ${spacing(16)};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledIcon = styled(Icon)`
    width: 24px;
    min-width: 24px;
    height: 24px;
    stroke: ${({theme}) => theme.text?.default};
    fill: ${({theme}) => theme.text?.default};
`;

export const StyledSectionContent = styled.section`
    padding: ${spacing(16)} 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

export const StyledEmptyBox = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: ${spacing(16)} 0;
`;

export const StyledImageBox = styled.div`
    width: 103px;
    height: 97px;
    margin: 0 auto ${spacing(16)};
    position: relative;
`;

export const StyledButtonList = styled.div<{$columns?: number}>`
    display: grid;
    grid-template-columns: repeat(${({$columns}) => $columns ? $columns : 3}, 40px);
    gap: 8px
`;
