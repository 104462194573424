import styled from "styled-components";
import { StyledTitleBox } from "components/organisms/modals/modals.styled";
import { Typography } from "components/atoms/typography/typography.component";
import { spacing } from "helpers/conversion-style";
import { Modals } from "components/organisms/modals/modals.component";

export const StyledTransferInfoModal = styled(Modals)`
    max-width: 550px;
    display: flex;
    flex-direction: column;
    ${StyledTitleBox} {
        display: none;
    }
`;

export const StyledInfoContent = styled.div`
    padding: ${spacing(16)};
`;

export const StyledTitle = styled(Typography)`
    margin-bottom: ${spacing(32)};
`;

export const StyledDesc = styled(Typography)`
    margin-bottom: ${spacing(48)};
`;
