import moment from "moment";
import { useTranslation } from "next-i18next";
import { useSession } from "next-auth/react";
import { IUser } from "types/next-auth";
import { useState, useEffect, useCallback } from "react";
import { DaterangeType } from "components/organisms/modals/daterange/daterange.types";

export interface IDate {
    start: string;
    end: string;
}

export interface IDateFilterContext {
    type: DaterangeType,
    dateFilter: IDate,
    changeDate: (start: string, end: string) => void;
    changeType: (dateType: DaterangeType) => void;
}

export const useDateFilter = (defaultType: DaterangeType) => {
    const [__] = useTranslation();
    const [ dateFilter, setDateFilter ] = useState<IDate>({
        start: "",
        end: "",
    });
    const [ type, setType ] = useState<DaterangeType>(defaultType);
    const { data: session } = useSession();
    const [ user, setUser ] = useState<IUser>();
    const [ textDateFilter, setTextDateFilter ] = useState<string>("");

    useEffect(() => {
        if(session && !user) {
            setUser(session?.user);
        }
    }, [session, user]);

    useEffect(() => {
        const dateCurrent = new Date();
        switch(type) {
            case DaterangeType.YESTERDAY: {
                const start = new Date(new Date().setDate(dateCurrent.getDate() - 1));
                setTextDateFilter(__("common.date.yesterday"));
                return setDateFilter({
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(start).format("YYYY-MM-DD"),
                });
            };
            case DaterangeType.PREVIOUS_WEEK: {
                const currentDay = dateCurrent.getDay();
                const start = new Date(new Date().setDate(dateCurrent.getDate() - currentDay - 6));
                const end = new Date(new Date().setDate(dateCurrent.getDate() - currentDay));
                setTextDateFilter(__("common.date.previous-week"));
                return setDateFilter({
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD"),
                });
            };
            case DaterangeType.THIS_MONTH: {
                const curentMonth = dateCurrent.getMonth();
                const start = new Date(dateCurrent.getFullYear(), curentMonth, 1);
                const lastDay = moment(new Date(start).setMonth(curentMonth + 1)).diff(moment(start), "days");
                const end = new Date(dateCurrent.getFullYear(), curentMonth, lastDay);
                setTextDateFilter(__("common.date.this-month"));
                return setDateFilter({
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD"),
                });
            };
            case DaterangeType.PREVIOUS_MONTH: {
                const previousMonth = dateCurrent.getMonth()-1;
                const start = new Date(dateCurrent.getFullYear(), previousMonth, 1);
                const lastDay = moment(new Date(start).setMonth(previousMonth + 1)).diff(moment(start), "days");
                const end = new Date(dateCurrent.getFullYear(), previousMonth, lastDay);
                setTextDateFilter(__("common.date.previous-month"));
                return setDateFilter({
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD"),
                });
            };
            case DaterangeType.PAST_QUARTER: {
                const previousQUARTER = dateCurrent.getMonth() > 3 ? Math.floor(dateCurrent.getMonth()/3) : 0;
                setTextDateFilter(__("common.date.past-quarter"));
                if(previousQUARTER === 0) {
                    const previousYear = dateCurrent.getFullYear()-1;
                    return setDateFilter({
                        start: moment([previousYear, 9, 1]).format("YYYY-MM-DD"),
                        end: moment([previousYear, 11, 31]).format("YYYY-MM-DD"),
                    });
                } else {
                    const start = new Date(dateCurrent.getFullYear(), previousQUARTER*3-3-1, 1);
                    const endDay = new Date(dateCurrent.getFullYear(), previousQUARTER*3-1, 1);
                    const lastDay = moment(new Date(endDay).setMonth(endDay.getMonth()+1)).diff(moment(endDay), "days");
                    const end = new Date(dateCurrent.getFullYear(), previousQUARTER*3-1, lastDay);
                    return setDateFilter({
                        start: moment(start).format("YYYY-MM-DD"),
                        end: moment(end).format("YYYY-MM-DD"),
                    });
                }
            };
            case DaterangeType.PAST_YEAR: {
                const previousYear = dateCurrent.getFullYear()-1;
                setTextDateFilter(__("common.date.past-year"));
                return setDateFilter({
                    start: moment([previousYear, 0, 1]).format("YYYY-MM-DD"),
                    end: moment([previousYear, 11, 31]).format("YYYY-MM-DD"),
                });
            };
            case DaterangeType.ALL_TIME: {
                setTextDateFilter(__("common.date.all-time"));
                return setDateFilter({
                    start: moment(user ? user.client.registrationDate : [2020, 0, 1]).format("YYYY-MM-DD"),
                    end: moment().format("YYYY-MM-DD"),
                });
            };
            case DaterangeType.CUSTOM: {
                setTextDateFilter(__("common.date.custom"));
                return setDateFilter((prev) => ({
                    start: moment(prev.start).format("YYYY-MM-DD"),
                    end: moment(prev.end).format("YYYY-MM-DD"),
                }));
            };
        }
    }, [type]);

    const changeDate = useCallback(
        (start: string, end: string) => {
            setDateFilter({
                start,
                end,
            });
        },
        [],
    );

    const changeType = useCallback(
        (dateType: DaterangeType) => {
            setType(dateType);
        },
        [],
    );

    return {
        type,
        dateFilter,
        changeDate,
        changeType,
        textDateFilter,
    };
};
