import styled from "styled-components";
import { Typography } from "components/atoms/typography/typography.component";
import { StyledTitleBox } from "../modals.styled";
import { spacing } from "helpers/conversion-style";
import { Modals } from "../modals.component";

export const StyledAccountDetailsModals = styled(Modals)`
    max-width: 360px;
    overflow: hidden;
    ${StyledTitleBox} {
        display: none;
    }
`;

export const StyledTopBox = styled.div`
    padding: ${spacing(16)} ${spacing(16)} ${spacing(32)};
    height: auto;
    position: relative;
    display: flex;
    align-items: flex-end;
    &:before {
        content: "";
        width: 90px;
        height: 5px;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        background-color: ${({ theme }) => theme.gray["100"]};
        position: absolute;
        z-index: 2;
        border-radius: 6px;
    }
`;

export const StyledTitle = styled(Typography)`
    position: relative;
    z-index: 2;
`;

export const StyledText = styled(Typography)`
    &:not(:last-child) {
        margin-bottom: 16px;
    }
`;

export const StyledContentBox = styled.div`
    padding: ${spacing(16)} ${spacing(16)} ${spacing(32)};
`;

export const StyledButtonBox = styled.div`
    padding: ${spacing(16)};
`;
