import React, { InputHTMLAttributes } from "react";
import { VariantCheckbox, ColorCheckbox } from "./checkbox.types";
import {
    StyledCheckboxItem,
    StyledCheckboxButtonLabel,
    StyledCheckboxButton,
    StyledCheckboxText,
    StyledSwitcher,
    StyledLabelFull,
} from "./checkbox.styled";

export interface ICheckbox extends InputHTMLAttributes<HTMLInputElement> {
    text?: string;
    id: string;
    variant?: VariantCheckbox;
    color?: ColorCheckbox;
    fullClick?: boolean;
    type?: "checkbox" | "radio";
    name?: string;
}

export const CheckboxElement: React.FC<ICheckbox> = ({
    text,
    id,
    variant = VariantCheckbox.DEFAULT,
    color = ColorCheckbox.MUTED,
    fullClick,
    type = "checkbox",
    name,
    ...props
}) => {
    return (
        <StyledCheckboxItem variant={variant}>
            <StyledCheckboxButton
                type={type}
                data-testid="test-checkbox"
                name={name || id}
                id={id}
                {...props}
                variant={variant}
            />
            {fullClick
            ?
                <StyledLabelFull htmlFor={id} variant={variant}>
                    <StyledSwitcher variant={variant}/>
                    <StyledCheckboxText color={color} variant={variant}>
                        {text}
                    </StyledCheckboxText>
                </StyledLabelFull>
            :
                <>
                    <StyledCheckboxButtonLabel htmlFor={id} variant={variant} />
                    <StyledCheckboxText color={color} variant={variant}>
                        {text}
                    </StyledCheckboxText>
                </>
            }
        </StyledCheckboxItem>
    );
};
