import React, { useEffect, useCallback } from "react";

export const useOutsideClick = (
    ref: React.RefObject<HTMLElement | null>,
    callback: () => void,
) => {
    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (
                ref &&
                ref.current &&
                !ref.current.contains(event.target as Element)
            ) {
                callback();
            }
        },
        [ref, callback],
    );

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);
};
