import styled from "styled-components";
import { spacing } from "helpers/conversion-style";
import { Modals } from "../modals.component";
import { StyledButtonClose, StyledTitleBox } from "../modals.styled";

export const StyledPreviewModals = styled(Modals)`
    max-width: ${spacing(360)};
    &:before {
        content: "";
        width: ${spacing(90)};
        height: ${spacing(5)};
        top: ${spacing(8)};
        left: 50%;
        transform: translateX(-50%);
        background-color: ${({ theme }) => theme.gray?.[100]};
        position: absolute;
        z-index: 2;
        border-radius: ${spacing(6)};
    }
    ${StyledButtonClose} {
      width: ${spacing(48)};
      height: ${spacing(48)};
    }
    ${StyledTitleBox} {
      display: flex;
      grid-template-columns: auto;
      align-items: center;
      justify-content: space-between;
      gap: ${spacing(16)};
    }
    
`;

export const StyledPreviewContent = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    max-height: ${spacing(544)};
    height: ${spacing(544)};
    padding: 0;
    iframe {
      display: flex;
      width: 100%;
      height: 100%;
    }
`;
