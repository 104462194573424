import styled from "styled-components";
import { Typography } from "components/atoms/typography/typography.component";
import { spacing } from "helpers/conversion-style";

export const StyledCopyButtonContainer = styled.div`
  z-index: 1;
  position: relative;
  width: ${spacing(24)};
  height: ${spacing(24)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCopyButton = styled.div`
  width: ${spacing(24)};
  height: ${spacing(24)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const StyledCopyIcon = styled.svg`
  width: ${spacing(24)};
  height: ${spacing(24)};
  color: ${({ theme }) => theme.text?.muted};
`;

export const StyledToolTip = styled.div`
  z-index: 2;
  position: absolute;
  display: inline-block;

  bottom: 100%;
  left: 50%;
  margin-bottom: ${spacing(8)};
  transform: translate(-50%);

  min-width: ${spacing(64)};
  min-height: ${spacing(24)};
  border-radius: ${spacing(12)};

  padding: ${spacing(4)} ${spacing(8)};

  background-color: ${({ theme }) => theme.text?.default};
`;

export const StyledToolTipDesc = styled(Typography)`
  color: ${({ theme }) => theme.gray?.[200]};
  text-align: center;
`;
