import styled from "styled-components";
import { spacing } from "helpers/conversion-style";
import { Modals } from "../modals.component";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { Typography } from "components/atoms/typography/typography.component";

export const StyledSignUpModals = styled(Modals)`
    max-width: 550px;
    height: calc(100vh - 100px);
    border-radius: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
`;

export const StyledModalsContent = styled.div`
    padding: ${spacing(62)} ${spacing(16)} ${spacing(16)};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const StyledTitle = styled(Typography)`
    margin-bottom: 68px;
    text-align: center;
    color: ${({theme}) => theme.text?.muted};
`;

export const StyledIconUser = styled.svg`
    width: 109px;
    height: 109px;
    fill: ${({theme}) => theme.gray?.[300]};
    margin: 0 auto 12px;
`;

export const StyledIconUserName = styled.svg`
    width: 165px;
    height: 34px;
    margin: 0 auto;
    stroke: ${({theme}) => theme.text?.muted};
`;

export const StyledButton = styled(ButtonLink)`
    margin-top: auto;
`;
