import { css } from "styled-components";
import { sizes } from "lib/styled-components/variables";
import { typographyStyle, listColor } from "lib/tokens/ui.styled";

export const conversionLetterSpacing = (value: string ) => {
    return `${+(value.replace(/%/gi, ""))/100}em`;
};

export const findStyle = (value: string, obj:any) => {
    try {
        return value.split(".").reduce((acc, itemStr) => (acc[itemStr] && acc[itemStr]),obj);
    } catch ( error: unknown ) {
        console.error(error);
    }
};

export const conversionSize = (value: string ) => {
    const source = value.replace(/[^a-zа-яё.]/gi, "");
    const pathForValue = source[source.length-1] === "." ? source.slice(0, -1) : source;
    if(pathForValue.length > 0) {
        const prop = findStyle(pathForValue, typographyStyle)?.value;
        const mathExpression = value.replace(`{${pathForValue}}`, prop).split(" ");
        switch(mathExpression[1]) {
            case "+": return (Number(mathExpression[0])+Number(mathExpression[2]));
            case "-": return (Number(mathExpression[0])-Number(mathExpression[2]));
            case "*": return (Number(mathExpression[0])*Number(mathExpression[2]));
            case "/": return (Number(mathExpression[0])/Number(mathExpression[2]));
        }
    }else {
        return +value;
    }
};

export const checkedColor = (value: string ) => {
    if(value[0] === "{" || value[value.length-1] === "}") {
        return findStyle(value.replace(/[{}]/gi, "").replace(/core./g, ""), listColor)?.value;
    } else {
        return value;
    }
};

export const checkedFontWeight = (value: string) => {
    if (value.replace(/[^+\d]/g, "").length === 0) {
        switch(value.toLowerCase()) {
            case "thin": return "100";
            case "extralight": return "200";
            case "light": return "300";
            case "normal": return "400";
            case "medium": return "500";
            case "semibold": return "600";
            case "bold": return "700";
            case "extrabold": return "800";
            case "ultrabold": return "900";
        }
    } else {
        return value;
    }
};

export const fluidType = (
    min: number,
    max: number,
    type = "px",
) => {
    return css`
        font-size: ${min}${type};
        @media (min-width: ${sizes.m / 16}em) {
            font-size: calc(${min}${type} + ${max - min} * ((100vw - ${sizes.m / min}${type}) / ${sizes.lg - sizes.m}));
        }
        @media (min-width: ${sizes.lg / 16}em) {
            font-size: ${max}${type};
        }
    `;
};

export const spacing = (value: number) => {
    return value * 0.0625 + "em";
};
