import React from "react";
import { useTranslation } from "next-i18next";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import {
  StyledDeleteModals,
  StyledButtonList,
  StyledDeleteText,
} from "./delete.styled";

export interface IProps {
  id: number;
  name: string;
  onDelete?: (id: number, closeHandler: () => void) => void | Promise<void> | undefined;
  onClose?: () => void | Promise<void> | undefined;
  closeModal: () => void;
}

export const DeleteModals: React.FC<IProps> = ({ onDelete, onClose, closeModal, name, id }) => {
  const [__] = useTranslation();

  const onDeleteHandler = () => {
    if (onDelete !== undefined && typeof onDelete === "function") {
      onDelete(id, onCloseHandler)?.catch((err) => console.error(err));
    }
  };

  const onCloseHandler = () => {
    if (onClose !== undefined && typeof onClose === "function") {
      onClose()?.catch((err) => console.error(err));
    }
    closeModal();
  };

  return (
    <StyledDeleteModals closeModal={onCloseHandler}>
      <StyledDeleteText variant={TypographyTypes.LARGE}>
        {name}
      </StyledDeleteText>
      <StyledButtonList>
        <ButtonLink
          onClick={onDeleteHandler}
          variant={ButtonTypes.DELETE}
          text={__("components.delete")}
        />
        <ButtonLink
          onClick={onCloseHandler}
          variant={ButtonTypes.SECONDARY}
          text={__("components.cancel")}
        />
      </StyledButtonList>
    </StyledDeleteModals>
  );
};
