import styled from "styled-components";
import { StylesToInput } from "styles/basic.styled";
import { Icon } from "components/atoms/icon/icon.component";
import { spacing } from "helpers/conversion-style";

interface IProps {
    error?: boolean;
    small?: boolean;
}

export const StyledSelect = styled.select<IProps>`
    ${StylesToInput};
    height: ${({ small }) => small ? 60 : 78}px;
    width: 100%;
    border: 1px solid ${({theme, error}) => error ? theme.red?.[600] : theme.gray?.[300]};
    padding: ${({ small }) => small ? `${spacing(16)} ${spacing(8)}` : `${spacing(16)} ${spacing(40)} ${spacing(8)} ${spacing(8)}`};
    outline: none;
    margin-bottom: -1px;
    background-color: ${({theme}) => theme.bg?.default};
    color: ${({theme}) => theme.text?.default};
    appearance: none;
    border-radius: ${({ small }) => small && "12px"};
`;

export const StyledSelectLabel = styled.div`
    ${StylesToInput};
    color: ${({theme}) => theme.text?.muted};
    position: absolute;
    top: 4px;
    left: 8px;
`;

export const StyledSelectIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    position: absolute;
    right: 20px;
    bottom: 18px;
    stroke: ${({theme}) => theme.text?.muted};
    pointer-events: none;
`;
