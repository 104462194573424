import { IRows } from "lib/hooks/cashbackBreakdown";
import { SortTypes, SortLevelTypes } from "components/organisms/modals/sort/sort.types";

export const sortDate = (
    data: IRows[],
    type: SortTypes | undefined,
    level: SortLevelTypes,
) => {
    if(data?.length === 0) {
        return [];
    }
    if(type === undefined) {
        return data;
    }

    const filterItem = (arr: IRows) => arr.data.filter((t) => t.key.includes(type))[0]?.value;

    let dataSort: IRows[] = [];

    switch(type) {
        case SortTypes.DATE: {
            dataSort = data.sort((a,b) => {
                const dateA = new Date(filterItem(a));
                const dateB = new Date(filterItem(b));
                if(dateA < dateB) {
                    return 1;
                } else {
                    return -1;
                }
            });
            break;
        };
        case SortTypes.SYMBOL: {
            dataSort = data.sort((a,b) => filterItem(a) > filterItem(b) ? 1 : -1);
            break;
        };
        default: dataSort = data.sort((a,b) => +filterItem(a) < +filterItem(b) ? 1 : -1);
    }

    switch(level) {
        case SortLevelTypes.ASC: return dataSort.reverse();
        case SortLevelTypes.DESC: return dataSort;
        case SortLevelTypes.NONE: return dataSort;
    }
};
