import { createContext, useContext } from "react";
import { useRouter } from "next/router";
import { useCookies } from "react-cookie";
import { LocalesMap } from "lib/i18n-locales.constant";
import { COOKIES_ENDLESS_OPTIONS } from "lib/configuration";

export interface ILanguageContext {
    currentLocale: string;
    locales: Array<{
        text: string;
        value: string;
    }>;
    onLocaleChange: (locale: string) => void;
}

const defaultContextValue: ILanguageContext = {
    currentLocale: "en",
    locales: [],
    onLocaleChange: () => {
        return undefined;
    },
};

const LanguageContext = createContext<ILanguageContext>(defaultContextValue);

export const useLanguage = () => {
    return useContext(LanguageContext);
};

export const LanguageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [, setCookie] = useCookies();
    const { locale, pathname, push } = useRouter();

    const handleLanguageChange = async (language: string) => {
        setCookie("lang", language, COOKIES_ENDLESS_OPTIONS);
        await push(pathname, pathname, {
            locale: language,
        });
    };

    const context: ILanguageContext = {
        onLocaleChange: handleLanguageChange,
        locales: Array.from(LocalesMap).map((item) => ({
            value: item[0],
            text: item[1],
        })),
        currentLocale: locale ? locale : "en",
    };

    return (
        <LanguageContext.Provider value={context}>
            {children}
        </LanguageContext.Provider>
    );
};
