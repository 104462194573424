import { logger } from "../pino.logger";
import process from "process";
import { logError } from "helpers/loggerData";

let intercepted = false;

const logUncaughtException = {
    desc: "Uncaught Exception",
    method: "LOCAL",
    url: "FS",
};

const logUnhandledRejection = {
    desc: "Unhandled Rejection",
    method: "LOCAL",
    url: "FS",
};

export function interceptBrowserUnhandledErrors() {
    const { window } = global;

    if (typeof window === "undefined" || intercepted) {
        return;
    }

    intercepted = true;

    process.on("uncaughtException", (error) => {
      logError(logUncaughtException, error);
    });

    process.on("unhandledRejection", (error) => {
      logError(logUnhandledRejection, error);
    });

    window.addEventListener("error", (event) => {
        try {
            const { error } = event;

            logError(logUncaughtException, error);

            logger.error(error, "unhandledError");

            if (event.preventDefault) {
                event.preventDefault();
            }
            return true;
        } catch (e) {
            console.error(
                "*********** Error during window error event listener:",
                e,
                "Origin event",
                /* istanbul ignore next */
                event,
            );
        }
    });
    window.addEventListener("unhandledrejection", (event) => {
        try {
            const err = (event as any).detail?.reason || event.reason;

            logError(logUnhandledRejection, err);

            logger.error(err, "unhandledPromiseRejection");

            if (event.preventDefault) {
                event.preventDefault();
            }
            return false;
        } catch (e) {
            console.error(
                "*********** Error during window.onunhandledrejection handler:",
                e,
                "Origin event:",
                /* istanbul ignore next */
                event,
            );
        }
    });
}
