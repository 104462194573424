import { FC, useEffect } from "react";
import {
    StyledIncompleteModalTransaction,
    StyledIncompleteModalTransactionBody,
    StyledIncompleteModalTransactionButton,
    StyledIncompleteModalTransactionButtonWrapper,
    StyledIncompleteModalTransactionDescription,
    StyledIncompleteModalTransactionDetailsList,
    StyledIncompleteModalTransactionDetailsListItem,
    StyledIncompleteModalTransactionIcon,
    StyledIncompleteModalTransactionTextWrapper,
    StyledIncompleteModalTransactionTitle,
    StyledIncompleteModalTransactionWrapper,
    StyledPaymentNameWrapper,
} from "./incomplete-transactions.styled";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { useTranslation } from "next-i18next";
import { Typography } from "components/atoms/typography/typography.component";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";
import { useRouter } from "next/router";
import { getAmountWithCurrencySymbol } from "helpers/amount-symbol";
import { useCookies } from "react-cookie";
import { usePostHog } from "posthog-js/react";
import {
    StyledAbbr,
    StyledProviderImage,
} from "components/atoms/form/radio/radio.styled";
import { getAbbr } from "components/atoms/form/radio/radio.component";

export interface IIncompleteTransactionProps {
    closeModal: () => void;
    config?: any;
}

export const IncompleteTransaction: FC<IIncompleteTransactionProps> = ({
    closeModal,
    config,
}) => {
    const [__] = useTranslation("common");
    const { push } = useRouter();
    const [_, setCookie] = useCookies(["incompleteTransaction"]);

    const posthog = usePostHog();

    useEffect(() => {
        posthog?.identify(`${config?.userId}`);
        posthog?.capture("incomplete_transaction_pop_up_shown", {
            incomplete_transaction_id: config?.id,
        });
    }, []);

    const routeToTheAnotherDepositMethod = async () => {
        posthog?.identify(`${config?.userId}`);
        posthog?.capture("incomplete_transaction_pop_up_use_another_provider", {
            incomplete_transaction_id: config?.id,
        });
        await push("/deposit");
        closeModal();
    };

    const closeModalWIthCookie = () => {
        posthog?.identify(`${config?.userId}`);
        posthog?.capture("incomplete_transaction_pop_up_close", {
            incomplete_transaction_id: config?.id,
        });
        setCookie("incompleteTransaction", config.id);
        closeModal();
    };

    const routeToCurrentDepositMethod = async () => {
        posthog?.identify(`${config?.userId}`);
        posthog?.capture("incomplete_transaction_pop_up_use_same_provider", {
            incomplete_transaction_id: config?.id,
        });
        await push(
            {
                pathname: "/deposit",
            },
            undefined,
            { shallow: true },
        );

        window.history.replaceState(
            {
                ...window.history.state,
                isFromIncompleteTransaction: true,
                config,
            },
            "",
        );
        closeModal();
    };

    const name = config.methodName || config.paymentDetails?.displayName;

    const logoIcon: JSX.Element = config.logo ? (
        <StyledProviderImage
            src={`${process.env.CMS_AMEGA_URL}${config.logo}`}
            alt="payment logo"
        />
    ) : (
        <StyledAbbr>{getAbbr(name)}</StyledAbbr>
    );

    return (
        <StyledIncompleteModalTransaction
            closeButton="X"
            closeModal={closeModalWIthCookie}
            isSideModal
        >
            <StyledIncompleteModalTransactionBody>
                <StyledIncompleteModalTransactionWrapper>
                    <StyledIncompleteModalTransactionIcon
                        width="89"
                        height="89"
                        viewBox="0 0 89 89"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M44.6667 88.3333C69.0592 88.3333 88.8333 68.5592 88.8333 44.1667C88.8333 19.7741 69.0592 0 44.6667 0C20.2741 0 0.5 19.7741 0.5 44.1667C0.5 68.5592 20.2741 88.3333 44.6667 88.3333ZM44.6667 26.5C47.1059 26.5 49.0833 24.5226 49.0833 22.0833C49.0833 19.6441 47.1059 17.6667 44.6667 17.6667C42.2274 17.6667 40.25 19.6441 40.25 22.0833C40.25 24.5226 42.2274 26.5 44.6667 26.5ZM49.0833 35.3333V70.6667H40.25V35.3333H49.0833Z"
                            fill="#8050F1"
                        />
                    </StyledIncompleteModalTransactionIcon>
                    <StyledIncompleteModalTransactionTextWrapper>
                        <StyledIncompleteModalTransactionTitle
                            variant={TypographyTypes.H3}
                        >
                            {__("modals.incomplete-transfer.title")}
                        </StyledIncompleteModalTransactionTitle>
                        <StyledIncompleteModalTransactionDescription
                            variant={TypographyTypes.DEFAULT}
                        >
                            {__("modals.incomplete-transfer.description")}
                        </StyledIncompleteModalTransactionDescription>
                    </StyledIncompleteModalTransactionTextWrapper>
                    <StyledIncompleteModalTransactionDetailsList>
                        <StyledIncompleteModalTransactionDetailsListItem>
                            <Typography variant={TypographyTypes.DEFAULT}>
                                {__("modals.incomplete-transfer.amount")}
                            </Typography>
                            <span />
                            <Typography variant={TypographyTypes.H3}>
                                {getAmountWithCurrencySymbol(
                                    config.currency,
                                    config.amount,
                                )}
                            </Typography>
                        </StyledIncompleteModalTransactionDetailsListItem>
                        <StyledIncompleteModalTransactionDetailsListItem>
                            <Typography variant={TypographyTypes.DEFAULT}>
                                {__(
                                    "modals.incomplete-transfer.payment-method",
                                )}
                            </Typography>
                            <span />
                            <StyledPaymentNameWrapper>
                                {logoIcon}
                                <Typography variant={TypographyTypes.DEFAULT}>
                                    {name}
                                </Typography>
                            </StyledPaymentNameWrapper>
                        </StyledIncompleteModalTransactionDetailsListItem>
                    </StyledIncompleteModalTransactionDetailsList>
                </StyledIncompleteModalTransactionWrapper>
                <StyledIncompleteModalTransactionButtonWrapper>
                    <StyledIncompleteModalTransactionButton
                        variant={ButtonTypes.SECONDARY}
                        text={__("modals.incomplete-transfer.another-method")}
                        onClick={routeToTheAnotherDepositMethod}
                    />
                    <StyledIncompleteModalTransactionButton
                        variant={ButtonTypes.PRIMARY}
                        text={__("modals.incomplete-transfer.current-method")}
                        onClick={routeToCurrentDepositMethod}
                    />
                </StyledIncompleteModalTransactionButtonWrapper>
            </StyledIncompleteModalTransactionBody>
        </StyledIncompleteModalTransaction>
    );
};
