import React, { FC } from "react";
import { StyledInfoButton, StyledInfoIcon } from "./info-button.styled";
import { IconTypes } from "components/atoms/icon/icon.types";

export interface InfoButtonProps {
  onClick?: () => void;
}

export const InfoButton: FC<InfoButtonProps> = ({ onClick }) => {
  const onPress = () => {
    if (onClick && typeof onClick === "function") { onClick(); }
  };
  return (
    <StyledInfoButton onClick={onPress}>
      <StyledInfoIcon icon={IconTypes.INFO} />
    </StyledInfoButton>
  );
};
