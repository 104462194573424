import styled from "styled-components";
import { StylesToBase } from "../typography/typography.styled";
import { spacing } from "helpers/conversion-style";

export const StyledRangeBox = styled.div`
    height: 60px;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.gray?.[600]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing(12)};
    position: relative;
`;

export const StyledInputBox = styled.div`
    position: relative;
    width: 130px;
`;

export const StyledLabel = styled.label<{$right?: boolean}>`
    ${StylesToBase};
    color: ${({ theme }) => theme.text?.default};
    position: absolute;
    top: 50%;
    left: ${({$right}) => $right ? "auto" : 0};
    right: ${({$right}) => $right ? 0 : "auto"};
    transform: translateY(-50%);
    pointer-events: none;
    display: flex;
    align-items: center;
    gap: ${spacing(4)};
`;

export const StyledNumber = styled.input<{$right?: boolean}>`
    ${StylesToBase};
    border: none;
    background: none;
    color: ${({ theme }) => theme.text?.default};
    padding-left: ${({$right}) => $right ? 0 : "14px"};
    padding-right: ${({$right}) => $right ? "24px" : 0};
    line-height: 0;
    outline: none;
    opacity: 0;
    width: 100%;
    text-align: ${({$right}) => $right ? "right" : "left"};
    cursor: pointer;
    &:focus {
        opacity: 1;
        + ${StyledLabel} {
            opacity: 0;
        }
    }
`;

export const StyledRange = styled.div`
    position: absolute;
    bottom: -1px;
    left: 10px;
    right: 10px;
    height: 1px;
    z-index: 2;
    .rc-slider {
        padding: 0;
        margin: 0;
        height: 1px;
    }
    .rc-slider-rail,
    .rc-slider-track {
        height: 1px;
    }
    .rc-slider-rail {
        background: none;
    }
    .rc-slider-track {
        background-color: ${({ theme }) => theme.green?.[800]};
    }
    .rc-slider-handle {
        background-color: ${({ theme }) => theme.green?.[800]};
        opacity: 1;
        width: 24px;
        height: 24px;
        border: none;
        margin-top: -12px;
        box-shadow: none;
        &.rc-slider-handle-dragging {
            box-shadow: none;
            border: none;
        }
    }
`;
