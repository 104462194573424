import { getSession } from "next-auth/react";
import { getCookies } from "cookies-next";
import { Logger } from "next-axiom";
import { v4 } from "uuid";

const log = new Logger();
const uuid = v4();

export enum EventType {
  CLIENT = "client",
  SYSTEM = "system",
}

interface DataProps {
  extra?: {
    [key: string]: any;
  };
  eventType?: EventType;
  desc: string;
  method: string;
  url: string;
  email?: string;
  headers?: {
    [key: string]: any;
  };
};

interface BodyProps {
  [key: string]: any;
}

interface SessionProps {
  [key: string]: any;
};

const getError = (error: unknown): string | boolean => {
  if (!error) {
    return false;
  }
  try {
    let errorString = "";
    if (error instanceof Error) {
      errorString = JSON.stringify(error, Object.getOwnPropertyNames(error)) || "";
    } else if (typeof error === "string") {
      const newErrorObject = {
        message: error,
      };
      errorString = JSON.stringify(newErrorObject) || "";
    } else {
      errorString = JSON.stringify(error) || "";
    }

    const errorObject = JSON.parse(errorString);
    const isError = Boolean(Object.keys(errorObject).length > 0);

    return (isError) ? errorString : false;
  }
  catch (err: unknown) {
    return false;
  }
};

const withData = () => {
  const { window } = global;
  const { navigator } = window || {};
  const { languages, userAgent } = navigator || {};

  const { countryCode, country, ip, xForwardedFor, languages: lngs, userAgent: uAgent } = getCookies();

  const headers = {
    ...(languages && { languages }),
    ...(userAgent && { userAgent }),
    ...(countryCode && { countryCode: decodeURIComponent(countryCode) }),
    ...(country && { country: decodeURIComponent(country) }),
    ...(ip && { ip: decodeURIComponent(ip) }),
    ...(lngs && { languages: decodeURIComponent(lngs) }),
    ...(uAgent && { userAgent: decodeURIComponent(uAgent) }),
    ...(xForwardedFor && { xForwardedFor: decodeURIComponent(xForwardedFor) }),
  };

  const isHeaders = Boolean(Object.keys(headers)?.length > 0);

  return {
    ...(isHeaders && ({
      headers: JSON.stringify(headers),
    })),
  };
};

export const logRequest = (data: DataProps, body?: BodyProps) => {
  const { desc, method, url, email, headers, eventType, extra } = data || {};
  if ( ! ( desc && url && method) ) { return; };

  const { password, ...sanitizedBody } = body || {};
  const bodyData = sanitizedBody && Object.keys(sanitizedBody)?.length > 0 ? sanitizedBody : undefined;

  const dataExtension = withData();

  const isHeaders = Boolean(headers && Object.keys(headers)?.length > 0);
  const isBodyData = Boolean(bodyData && Object.keys(bodyData)?.length > 0);
  const isExtra = Boolean(extra && Object.keys(extra)?.length > 0);

  const commonData = {
    uuid,
    method,
    url,
    eventType: eventType || EventType.CLIENT,
    ...(email && { email }),
    ...(dataExtension && dataExtension),
    ...(isHeaders && { headers: JSON.stringify(headers) }),
    ...(isBodyData && { body: JSON.stringify(bodyData) }),
    ...(isExtra && { extra: JSON.stringify(extra) }),
  };

  getSession().then((session) => {
    const { user }: SessionProps = session || {};
    const { client } = user || {};

    const loggerData = {
      ...commonData,
      ...(client && { client: JSON.stringify(client) }),
    };

    log.info(`Request ${desc}: `, loggerData);

  }).catch((error: unknown) => {
    console.error(error);
    const errorData = getError(error);
    const loggerData = {
      ...commonData,
      ...(errorData && { error: errorData }),
    };

    log.error(`Error ${desc}: `, loggerData);
  });
};

export const logResponse = (data: DataProps, response: Response, levelInfo?: boolean) => {
  const { desc, method, url, email, headers, eventType, extra } = data || {};
  if ( ! ( desc && url && method && response ) ) { return; };

  const dataExtension = withData();

  const isHeaders = Boolean(headers && Object.keys(headers)?.length > 0);
  const isExtra = Boolean(extra && Object.keys(extra)?.length > 0);

  const commonData = {
    uuid,
    method,
    url,
    eventType: eventType || EventType.CLIENT,
    ...(email && { email }),
    ...(dataExtension && dataExtension),
    ...(isHeaders && { headers: JSON.stringify(headers) }),
    ...(isExtra && { extra: JSON.stringify(extra) }),
  };

  try {
    const instance = response.clone();

    const { ok, status, statusText } = instance;
    const levelDefault = (ok) ? log.info : log.error;
    const logFunc = levelInfo ? log.info : levelDefault;

    void getSession().then((session) => {
      const { user }: SessionProps = session || {};
      const { client } = user || {};

      const loggerData = {
        ...commonData,
        ok,
        status,
        ...(statusText && { statusText }),
        ...(client && { client: JSON.stringify(client) }),
      };

      void instance?.json().then((json) => {
        const { accessToken, ...sanitizedJSON } = json || {};
        const jsonData = sanitizedJSON && Object.keys(sanitizedJSON)?.length > 0 ? sanitizedJSON : undefined;
        const outputData = {
          ...loggerData,
          ...(typeof json === "boolean"
              ? ({ data: String(json) })
                  : (jsonData && { data: JSON.stringify(jsonData) })),
        };
        logFunc(`Response ${desc}: `, outputData);
      });
    });
  } catch (error: unknown) {
    console.error(error);
    const errorData = getError(error);
    const loggerData = {
      ...commonData,
      ...(errorData && { error: errorData }),
    };

    log.error(`Error ${desc}: `, loggerData);
  };
};

export const logError = (data: DataProps, error: unknown) => {
  const { desc, method, url, email, headers, eventType, extra } = data || {};
  if ( ! ( desc && url && method && error ) ) { return; };

  const dataExtension = withData();

  const isHeaders = Boolean(headers && Object.keys(headers)?.length > 0);
  const isExtra = Boolean(extra && Object.keys(extra)?.length > 0);

  const errorData = getError(error);

  const commonData = {
    uuid,
    method,
    url,
    eventType: eventType || EventType.CLIENT,
    ...(errorData && { error: errorData }),
    ...(email && { email }),
    ...(dataExtension && dataExtension),
    ...(isHeaders && { headers: JSON.stringify(headers) }),
    ...(isExtra && { extra: JSON.stringify(extra) }),
  };

  getSession().then((session) => {
    const { user }: SessionProps = session || {};
    const { client } = user || {};

    const loggerData = {
      ...commonData,
      ...(client && { client: JSON.stringify(client) }),
    };

    log.error(`Error ${desc}: `, loggerData);
  }).catch((err: unknown) => {
    console.error(err);
    const errData = getError(err);
    const loggerData = {
      ...commonData,
      ...(errData && { error: errData }),
    };

    log.error(`Error ${desc}: `, loggerData);
  });
};
