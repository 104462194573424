import React, {
    InputHTMLAttributes,
    ChangeEventHandler,
    useState,
} from "react";
import { IconTypes } from "../../icon/icon.types";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import {
    StyledInput,
    StyledLabel,
    StyledInputWrapper,
    StyledPasswordButton,
    StyledPasswordIcon,
    StyledErrorText,
    StyledDateIcon,
} from "./input.styled";

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    labelText?: string;
    type?: React.HTMLInputTypeAttribute | "repeat";
    error?: any;
    small?: boolean;
    onChange?: ChangeEventHandler;
    onChangeType?: (type: boolean) => void;
}

export const Input = React.forwardRef<HTMLInputElement, IProps>(
    ({ id, labelText, type, onChange, onChangeType, small, error, className, ...props }, ref) => {

    const [passwordTypeInput, setPasswordTypeInput] = useState<boolean>(true);
    const passwordType = passwordTypeInput ? "password" : "text";
    const toggleInputType = () => {
        setPasswordTypeInput(!passwordTypeInput);
        if(onChangeType) {
            onChangeType(passwordTypeInput);
        }
    };

        return (
            <StyledInputWrapper error={Boolean(error)}>
                {labelText && <StyledLabel htmlFor={id}>{labelText}</StyledLabel>}
                <StyledInput
                    ref={ref}
                    small={small}
                    data-testid="input"
                    id={id}
                    type={type === "password" ||  type === "repeat" ? passwordType : type}
                    onChange={onChange}
                    error={Boolean(error)}
                    className={className}
                    {...props}
                />
                <StyledErrorText variant={TypographyTypes.SMALL} error={Boolean(error)}>{error}</StyledErrorText>
                {type === "password" &&
                    <StyledPasswordButton onClick={toggleInputType} data-testid="eye">
                        <StyledPasswordIcon icon={IconTypes.EYE} />
                    </StyledPasswordButton>
                }
                {(type === "date" || type === "datetime-local") &&
                    <StyledDateIcon icon={IconTypes.DATE} />
                }
                {(type === "search") &&
                    <StyledDateIcon icon={IconTypes.SEARCH} />
                }
            </StyledInputWrapper>
        );
    },
);

Input.displayName = "Input";
