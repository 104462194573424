import styled, { css } from "styled-components";
import { spacing } from "helpers/conversion-style";
import { LabelsItemTypes } from "./labels-item.types";
import { Typography } from "../typography/typography.component";

interface IProps {
    type: LabelsItemTypes;
}

const ErrorCSS = css`
    background-color: ${({ theme }) => theme.red?.[200]};
    color: ${({ theme }) => theme.red?.[1000]};
`;

const WarningCSS = css`
    background-color: ${({ theme }) => theme.yellow?.[200]};
    color: ${({ theme }) => theme.yellow?.[1000]};
`;

const SuccessCSS = css`
    background-color: ${({ theme }) => theme.green?.[200]};
    color: ${({ theme }) => theme.green?.[1000]};
`;

const DemoCSS = css`
    background-color: ${({ theme }) => theme.gray?.[100]};
    color: ${({ theme }) => theme.gray?.[1000]};
`;

const AccountDemoCSS = css`
    background-color: ${({ theme }) => theme.components.purple_bg};
    color: ${({ theme }) => theme.components.purple_text};
    border: 1px solid ${({ theme }) => theme.components.purple_border};
`;

const BonusCSS = css`
    background-color: ${({ theme }) => theme.components.success_bg};
    color: ${({ theme }) => theme.components.success_text};
    border: 1px solid ${({ theme }) => theme.components.success_border};
`;

const LiveCSS = css`
    ${SuccessCSS};
`;

export const StyledLabelsItem = styled(Typography)<IProps>`
    padding: ${spacing(4)} ${spacing(8)};
    border-radius: 12px;
    ${({ type }) => {
        switch (type) {
            case LabelsItemTypes.ERROR:
                return ErrorCSS;
            case LabelsItemTypes.WARNING:
                return WarningCSS;
            case LabelsItemTypes.SUCCESS:
                return SuccessCSS;
            case LabelsItemTypes.DEMO:
                return DemoCSS;
            case LabelsItemTypes.LIVE:
                return LiveCSS;
            case LabelsItemTypes.BONUS:
                return BonusCSS;
            case LabelsItemTypes.ACCOUNT_DEMO:
                return AccountDemoCSS;
        }
    }};
    &::first-letter {
        text-transform: uppercase;
    }
`;
