import styled from "styled-components";
import { Icon } from "../icon/icon.component";

export const StyledVerifiedBox = styled.div`
    display: grid;
    align-items: center;
    gap: 4px;
    grid-template-columns: 18px 1fr;
`;

export const StyledLevelButton = styled.button`
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;
    width: 24px;
    height: 24px;
`;

export const StyledLevelBox = styled.div`
    display: grid;
    align-items: center;
    gap: 4px;
    grid-template-columns: 1fr 24px;
`;


export const StyledVerifiedIcon = styled(Icon)<{verified: boolean}>`
    width: 18px;
    height: 18px;
    fill: ${({ theme, verified }) => verified ? theme.components?.success_bg : theme.components?.error_bg};
    color: ${({ theme, verified }) => verified ? theme.components?.success_text : theme.components?.error_text};
`;

export const StyledLevelIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.text?.muted};
`;
