import React, { FC } from "react";
import { useTranslation } from "next-i18next";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import {
    StyledTransferInfoModal,
    StyledInfoContent,
    StyledTitle,
    StyledDesc,
} from "./transfer-info-modals.styled";

export interface IProps {
    closeModal: () => void;
    title: string;
    desc: string;
}

export const TransferInfoModals: React.FC<IProps> = ({
    closeModal,
    title,
    desc,
}) => {
    const [__] = useTranslation();

    return (
        <StyledTransferInfoModal closeModal={closeModal}>
            <StyledInfoContent>
                <StyledTitle variant={TypographyTypes.H2}>{title}</StyledTitle>
                <StyledDesc variant={TypographyTypes.BASE}>{desc}</StyledDesc>
                <ButtonLink
                    text={__("components.close")}
                    variant={ButtonTypes.SECONDARY}
                    onClick={closeModal}
                />
            </StyledInfoContent>
        </StyledTransferInfoModal>
    );
};
