import styled, { css } from "styled-components";
import { Modals } from "../modals.component";
import { media } from "lib/styled-components/variables";
import { Typography } from "components/atoms/typography/typography.component";
import { spacing } from "helpers/conversion-style";

export const StyledNewWalletModalIcon = styled.svg`
    width: 128px;
    height: 128px;
    ${media.smMax`
        width: 86px;
        height: 86px;
    `}
`;

export const StyledNewWalletModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 25px;
    text-align: center;
    row-gap: ${spacing(36)};
    padding-bottom: ${spacing(36)};
`;

export const StyledNewWalletModalTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledNewWalletModalTitle = styled(Typography)``;

export const StyledNewWalletModal = styled(Modals)`
    max-width: 570px;
    overflow: hidden;
    overflow-y: auto;
    ${media.sm`
        border-radius: 16px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        right: unset;
        bottom: unset;
        max-height: calc(100svh - 32px);
    `};
`;
