import { useEffect } from "react";
import { CookieSetOptions } from "universal-cookie";
import { useCookies } from "react-cookie";
import { COOKIES_NAMES } from "lib/configuration";

export const UseCheckUtm = () => {
    const [, setCookie] = useCookies([COOKIES_NAMES.entryPointParams]);

    useEffect(() => {
        const checkUtmHref = window.location.search.substring(1);

        if(checkUtmHref) {
            const utmList = [
                "utm_source",
                "utm_medium",
                "utm_campaign",
                "utm_content",
                "utm_term",
                "gclid",
                "ttclid",
                "fbclid"
            ];
            const href = utmList.filter((i) => checkUtmHref.includes(i));

            if(href.length) {
                const transformationUtm = (text: string) => {
                    const link = text.split("&").filter((i) => utmList.includes(i.split("=")[0]))
                        .reduce((acc, current) => utmList.includes(current.split("=")[0]) ? acc + "&" + current : acc, "",);
                    if (link.length === 0) {
                        return undefined;
                    } else {
                        return link[0] === "&" ? link.slice(1) : link;
                    }
                };
                const options: CookieSetOptions = {
                    expires: (() => {
                        const date = new Date();
                        date.setMonth(date.getMonth() + 1);
                        return date;
                    })(),
                    path: "/",
                    domain: ".amega.finance",
                };

                setCookie(COOKIES_NAMES.entryPointParams, transformationUtm(checkUtmHref), options);
            }
        }


    }, []);
};
