import React from "react";
import { useTranslation } from "next-i18next";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import {
    StyledDepositInfoModals,
    StyledInfoContent,
    StyledTitle,
    StyledText,
} from "./deposit-info-modals.styled";

export interface IProps {
    closeModal: () => void;
    title: string;
    text: string;
}

export const DepositInfoModals: React.FC<IProps> = ({
    closeModal,
    title,
    text,
}) => {
    const [__] = useTranslation();

    return (
        <StyledDepositInfoModals closeModal={closeModal}>
            <StyledInfoContent>
                <StyledTitle variant={TypographyTypes.H2}>{title}</StyledTitle>
                <StyledText variant={TypographyTypes.BASE}>{text}</StyledText>
                <ButtonLink
                    text={__("components.close")}
                    variant={ButtonTypes.SECONDARY}
                    onClick={closeModal}
                />
            </StyledInfoContent>
        </StyledDepositInfoModals>
    );
};
