import styled from "styled-components";
import { spacing } from "helpers/conversion-style";

export const StyledButtonIcon = styled.button<{$hide?: boolean}>`
    cursor: pointer;
    background: none;
    padding: ${spacing(12)} ${spacing(16)};
    border: none;
    display: ${({ $hide }) => $hide ? "none" : "grid"};
    align-items: center;
    grid-template-columns: 24px 1fr;
    gap: 16px;
    color: ${({ theme }) => theme.text?.default};
    width: 100%;
    text-align: left;
`;

export const StyledIcon = styled.svg`
    width: 24px;
    min-width: 24px;
    height: 24px;
    stroke: ${({ theme }) => theme.text?.default};
    fill: ${({ theme }) => theme.text?.default};
`;
