import styled from "styled-components";
import { spacing } from "helpers/conversion-style";
import { StyledTitleBox } from "../modals.styled";
import { Modals } from "../modals.component";

export const StyledReferralsLevelModals = styled(Modals)`
    max-width: 550px;
    padding: ${spacing(16)};
    ${StyledTitleBox} {
        display: none;
    }
    &:before {
        content: "";
        width: 90px;
        height: 5px;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        background-color: ${({ theme }) => theme.gray["100"]};
        position: absolute;
        z-index: 2;
        border-radius: 6px;
    }
`;

export const StyledReferralsLevelContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing(12)};
    margin: ${spacing(32)} 0 ${spacing(48)};
`;
