import React from "react";
import { useTranslation } from "next-i18next";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";
import {
    StyledStatusBenefitsModals,
    StyledStatusBox,
    StyledStatusPending,
    StyledStatusError,
    StyledButtonList,
} from "./benefits-status-modals.styled";
import { IconTypes } from "components/atoms/icon/icon.types";

export enum StatusBenefitsTypes {
    PENDING = "pending",
    DECLINED = "declined",
    DEFAULT = "default",
    APPROVED = "approved",
};

export interface IProps {
    closeModal: () => void;
    status: StatusBenefitsTypes,
}

export const StatusBenefitsModals: React.FC<IProps> = ({
    closeModal,
    status,
}) => {
    const { t } = useTranslation("benefits");

    const handlerSupport = () => {
        window.Intercom("show");
    };

    return (
        <StyledStatusBenefitsModals closeModal={closeModal}>
            {status === StatusBenefitsTypes.PENDING ?
                <StyledStatusBox>
                    <StyledStatusPending icon={IconTypes.PENDING} />
                    <Typography variant={TypographyTypes.H1}>{t("text.modal.pending-title")}</Typography>
                    <Typography variant={TypographyTypes.LARGE}>{t("text.modal.pending-text")}</Typography>
                    <StyledButtonList>
                        <ButtonLink
                            variant={ButtonTypes.BLACK}
                            text={t("text.button-contact")}
                            onClick={handlerSupport}
                        />
                        <ButtonLink
                            variant={ButtonTypes.SECONDARY}
                            text={t("text.button-close")}
                            onClick={closeModal}
                        />
                    </StyledButtonList>
                </StyledStatusBox>
                :
                <StyledStatusBox>
                    <StyledStatusError icon={IconTypes.ERROR} />
                    <Typography variant={TypographyTypes.H1}>{t("text.modal.error-title")}</Typography>
                    <Typography variant={TypographyTypes.LARGE}>{t("text.modal.error-text")}</Typography>
                    <StyledButtonList>
                        <ButtonLink
                            variant={ButtonTypes.BLACK}
                            text={t("text.button-contact")}
                            onClick={handlerSupport}
                        />
                        <ButtonLink
                            variant={ButtonTypes.SECONDARY}
                            text={t("text.button-close")}
                            onClick={closeModal}
                        />
                    </StyledButtonList>
                </StyledStatusBox>
            }
        </StyledStatusBenefitsModals>
    );
};
