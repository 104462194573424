import React from "react";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { ButtonIcon, ButtonTypes } from "components/atoms/button-link/button-link.types";
import {
    StyledSignUpModals,
    StyledModalsContent,
    StyledTitle,
    StyledIconUser,
    StyledIconUserName,
    StyledButton,
} from "./social-signup-modals.styled";

export interface ISocialModals {
    title: string;
    buttonText: string;
    icon: ButtonIcon;
}

export interface IProps extends ISocialModals {
    closeModal: () => void;
}

export const SocialSignUpModals: React.FC<IProps> = ({
    closeModal,
    title,
    buttonText,
    icon,
}) => {
    return (
        <StyledSignUpModals
            closeModal={closeModal}
        >
            <StyledModalsContent>
                <StyledTitle variant={TypographyTypes.H2} >{title}</StyledTitle>
                <StyledIconUser>
                    <use xlinkHref={"/svg/icons-sprite.svg#user"} />
                </StyledIconUser>
                <StyledIconUserName>
                    <use xlinkHref={"/svg/icons-sprite.svg#name-user"} />
                </StyledIconUserName>
                <StyledButton
                    variant={ButtonTypes.SOCIAL}
                    icon={icon}
                    text={buttonText}
                />
            </StyledModalsContent>
        </StyledSignUpModals>
    );
};
