import styled, { css } from "styled-components";
import { typographyStyle } from "lib/tokens/ui.styled";
import { fontSizes } from "lib/styled-components/variables";
import { media } from "lib/styled-components/variables";
import {
    conversionLetterSpacing,
    checkedFontWeight,
} from "helpers/conversion-style";

const generalSettingsToTitle = css`
    margin: 0;
    padding: 0;
    letter-spacing: ${conversionLetterSpacing(
        typographyStyle.letterSpacing.decreased.value,
    )};
    font-weight: ${checkedFontWeight(
        typographyStyle.fontWeights.headings.value,
    )};
    line-height: ${typographyStyle.lineHeights.heading.value};
    font-family: ${typographyStyle.fontFamilies.headings.value}, -apple-system,
        BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
        Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
`;

const generalSettingsToText = css`
    margin: 0;
    padding: 0;
    letter-spacing: ${conversionLetterSpacing(
        typographyStyle.letterSpacing.default.value,
    )};
    font-weight: ${checkedFontWeight(typographyStyle.fontWeights.base.value)};
    line-height: ${typographyStyle.lineHeights.body.value};
    font-family: ${typographyStyle.fontFamilies.body.value}, -apple-system,
        BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
        Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
`;

export const SeparateStylesForH1CSS = css`
    ${generalSettingsToTitle};
    ${fontSizes.H1};
`;

export const StyledH1 = styled.h1`
    ${SeparateStylesForH1CSS};
`;

export const SeparateStylesForH2CSS = css`
    ${generalSettingsToTitle};
    ${fontSizes.H2};
`;

export const StyledH2 = styled.h2`
    ${SeparateStylesForH2CSS};
`;

export const SeparateStylesForH3CSS = css`
    ${generalSettingsToTitle};
    ${fontSizes.H3};
`;

export const StyledH3 = styled.h3`
    ${generalSettingsToTitle};
    ${fontSizes.H3};
`;

export const SeparateStylesForH4CSS = css`
    ${generalSettingsToTitle};
    ${fontSizes.H4};
`;

export const StyledH4 = styled.h4`
    ${SeparateStylesForH4CSS};
`;

export const SeparateStylesForH5CSS = css`
    ${generalSettingsToTitle};
    ${fontSizes.H5};
`;

export const StyledH5 = styled.h5`
    ${SeparateStylesForH5CSS};
`;

export const SeparateStylesForH6CSS = css`
    ${generalSettingsToTitle};
    ${fontSizes.H6};
`;

export const StyledH6 = styled.h6`
    ${SeparateStylesForH6CSS};
`;

export const StylesToHuge = css`
    ${generalSettingsToText};
    ${fontSizes.HUGE};
    letter-spacing: ${conversionLetterSpacing(
        typographyStyle.letterSpacing.decreased.value,
    )};
`;

export const StyledHuge = styled.p`
    ${StylesToHuge};
`;

export const StylesToLarge = css`
    ${generalSettingsToText};
    ${fontSizes.LARGE};
`;

export const StyledLarge = styled.p`
    ${StylesToLarge};
`;

export const StylesToBase = css`
    ${generalSettingsToText};
    ${fontSizes.BASE};
`;

export const StyledBase = styled.p`
    ${StylesToBase};
`;

export const StylesToSmall = css`
    ${generalSettingsToText};
    ${fontSizes.SMALL};
`;

export const StyledSmall = styled.p`
    ${StylesToSmall};
`;

export const StylesToButton = css`
    ${generalSettingsToText};
    font-weight: ${checkedFontWeight(
        typographyStyle.fontWeights.headings.value,
    )};
    ${fontSizes.BUTTON};
`;

export const StyledButton = styled.p`
    ${StylesToButton};
`;

export const StyledBannerTitle = styled.h1`
    font-family: Unbounded, sans-serif, -apple-system;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: -0.4;
`;

export const StyledAccent = styled.p`
    font-family: Unbounded, sans-serif, -apple-system;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: -0.4px;
    text-transform: uppercase;
    margin: 0;
    ${media.mdMax`
        font-size: 28px;
        letter-spacing: -0.3px;
    `}
`;

export const StyledDefault = styled.p`
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.1;
`;
