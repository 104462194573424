import React from "react";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { useTheme } from "components/providers/theme/theme.hooks";
import { ThemeVariant } from "components/providers/theme/theme.constants";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";
import {
    StyledCashbackInfoModals,
    StyledModalsTitleBox,
    StyledModalsTitle,
    StyledModalsContentBox,
    StyledButtonBox,
    StyledTitleImage,
} from "./cashback-info-modals.styled";

export interface IProps {
    closeModal: () => void;
}

export const CashbackInfoModals: React.FC<IProps> = ({
    closeModal,
}) => {
    const [__] = useTranslation();
    const theme = useTheme();

    return (
        <StyledCashbackInfoModals closeModal={closeModal}>
            <StyledModalsTitleBox>
                <StyledModalsTitle
                    $dark={theme.themeVariant === ThemeVariant.Dark}
                    variant={TypographyTypes.H2}
                >
                    {__("cashback.modals.about.title")}
                </StyledModalsTitle>
                <StyledTitleImage>
                    <Image
                        src="/images/cashback-title.png"
                        alt={__("cashback.modals.about.title")}
                        layout="fill"
                    />
                </StyledTitleImage>
            </StyledModalsTitleBox>
            <StyledModalsContentBox>
                <Typography variant={TypographyTypes.BASE}>{__("cashback.modals.about.desc1")}</Typography>
                <Typography variant={TypographyTypes.BASE}>{__("cashback.modals.about.desc2")}</Typography>
                <Typography variant={TypographyTypes.BASE}>{__("cashback.modals.about.desc3")}</Typography>
                <Typography variant={TypographyTypes.BASE}>{__("cashback.modals.about.desc4")}</Typography>
                <Typography variant={TypographyTypes.BASE}>{__("cashback.modals.about.desc5")}</Typography>
                <StyledButtonBox>
                    <ButtonLink
                        text={__("components.close")}
                        variant={ButtonTypes.SECONDARY}
                        onClick={closeModal}
                    />
                </StyledButtonBox>
            </StyledModalsContentBox>
        </StyledCashbackInfoModals>
    );
};
