import React from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { CopyButton } from "components/atoms/copy-button/copy-button.component";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { IconTypes } from "components/atoms/icon/icon.types";
import {
    StyledAccountInfoModals,
    StyledContent,
    StyledText,
    StyledTitle,
    StyledListInfo,
    StyledCopyBox,
    StyledButtonIcon,
    StyledButtonReturn,
} from "./account-info-modals.styled";

export interface IProps {
    closeModal: () => void;
    account: string;
}

interface IInfo {
    key: React.Key,
    title: string,
    value: string,
}

export const AccountInfoModals: React.FC<IProps> = ({
    closeModal,
    account,
}) => {
    const [__] = useTranslation();
    const router = useRouter();

    const listInfo: IInfo[] = [
        {
            key: "login",
            title: __("account.details.modals.account-info.login"),
            value: account.split("-")[1],
        },
    ];

    const handlerGoBack = async () => {
        closeModal();
        await router.push(`/account/${account}`);
    };

    return (
        <StyledAccountInfoModals
            closeModal={closeModal}
            title={__("account.details.modals.account-info.demo")}
            iconTitle={IconTypes.CLOCK}
        >
            <StyledContent>
                <StyledText variant={TypographyTypes.BASE}>{__("account.details.modals.account-info.title")}</StyledText>
                <StyledListInfo>
                    {listInfo.map((item) => (
                        <div key={item.key}>
                            <StyledTitle variant={TypographyTypes.BASE}>{item.title}</StyledTitle>
                            <StyledCopyBox>
                                <Typography variant={TypographyTypes.HUGE}>{item.value}</Typography>
                                <CopyButton data={item.value} />
                            </StyledCopyBox>
                        </div>
                    ))}
                </StyledListInfo>
            </StyledContent>
            <StyledButtonReturn onClick={handlerGoBack}>
                <StyledButtonIcon icon={IconTypes.BACK} />
                {__("account.details.modals.account-info.btn")}
            </StyledButtonReturn>
        </StyledAccountInfoModals>
    );
};
