/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent, useMemo } from "react";
import { useTranslation } from "next-i18next";
import { Radio } from "components/atoms/form/radio/radio.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { RadioTypes } from "components/atoms/form/radio/radio.types";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes, ButtonSize } from "components/atoms/button-link/button-link.types";
import { IconTypes } from "components/atoms/icon/icon.types";
import { DaterangeType } from "../daterange/daterange.types";
import { useDateFilter } from "helpers/use-date-filter";
import { StyledButtonClose, StyledButtonIcon } from "../modals.styled";
import { Input } from "components/atoms/form/input/input.component";
import { StyledInputList } from "components/atoms/form/input/input.styled";
import { CheckboxElement } from "components/atoms/form/checkbox/checkbox.component";
import { VariantCheckbox } from "components/atoms/form/checkbox/checkbox.types";
import { RangeSlider, changeRangeFilter } from "components/atoms/range/range.component";
import { IRowsReferrals } from "lib/hooks/ib/referrals";
import { ReferralsTypes } from "../partnership-referrals/partnership-referrals.types";
import { searchData, filterData } from "helpers/partnership-filter";
import { ibReferrals } from "lib/hooks/ib/referrals";
import { debounce } from "lodash";
import {
    StyledDaterangeModals,
    StyledButtonBox,
    StyledFilterList,
    StyledDateCustom,
    StyledInputDate,
    StyledDaterangeHead,
    StyledDaterangeContent,
    StyledFilter,
    StyledFilterItem,
    StyledText,
    StyledListItem,
} from "../daterange/daterange-modals.styled";

interface IFilter {
    text: string;
    id: DaterangeType;
}

export interface IDataFilter {
    balance: number[];
    commission: number[];
    deposits: number[];
    lots: number[];
    closed: number[];
    referral: ReferralsTypes[];
    state: ReferralsTypes;
    dateType: DaterangeType;
    search: string;
}

export interface IMaxRange {
    balance: number;
    commission: number;
    deposits: number;
    lots: number;
    closed: number;
}

interface IRange {
    key: string;
    text: string;
    max: number;
    value: number[];
    change: (value: number | number[]) => void;
    currency?: string;
    step?: number;
}

interface IList {
    id: ReferralsTypes;
    text: string;
}

interface IProps {
    closeModal: () => void;
    isFilter?: boolean;
    currency: string;
    dataFilterModal: IDataFilter;
    changeFilterModal: (value: IDataFilter) => void;
    max: IMaxRange;
    changeDataFilter: (data: IRowsReferrals[]) => void;
    token: string;
}

export const PartnershipFilterModals: React.FC<IProps> = ({
    closeModal,
    currency,
    dataFilterModal,
    changeFilterModal,
    changeDataFilter,
    max,
    token,
}) => {
    const [__] = useTranslation();
    const [dateReferrals, setDateReferrals] = useState<IRowsReferrals[]>([]);
    const [filterDataReferrals, setFilterDataReferrals] = useState<IRowsReferrals[]>([]);
    const { changeType, changeDate, type, dateFilter } = useDateFilter(dataFilterModal.dateType);
    const [filterModal, setFilterModal] = useState<IDataFilter>({
        balance: dataFilterModal.balance,
        commission: dataFilterModal.commission,
        deposits: dataFilterModal.deposits,
        lots: dataFilterModal.lots,
        closed: dataFilterModal.closed,
        referral: dataFilterModal.referral,
        state: dataFilterModal.state,
        dateType: dataFilterModal.dateType,
        search: dataFilterModal.search,
    });

    useEffect(() => {
        if (dateFilter.start !== "" && dateFilter.end !== "") {
            void ibReferrals(
                token,
                0,
                dateFilter.start,
                dateFilter.end,
            ).then((data) => {
                if (data) {
                    setDateReferrals(data.rows);
                    const dataSearch = searchData(data.rows, filterModal.search);
                    setFilterDataReferrals(filterData(dataSearch, filterModal));
                }
            });
        }
    }, [dateFilter]);

    // Filter Search
    const changeDataSearch = (text: string) => {
        const dataSearch = searchData(dateReferrals, text);
        setFilterDataReferrals(filterData(dataSearch, filterModal));
    };
    const debouncedFilter = useMemo(() => debounce(changeDataSearch, 1000), [dateReferrals, filterModal]);
    const changeSearch = (text: string) => {
        setFilterModal((prev) => ({ ...prev, search: text }));
        debouncedFilter(text);
    };

    // Filter Balance
    const changeBalance = (value: number | number[]) => {
        setFilterModal((prev) => ({ ...prev, balance: changeRangeFilter(value) }));
        const dataSearch = searchData(dateReferrals, filterModal.search);
        const filter = filterModal;
        filter.balance = changeRangeFilter(value);
        setFilterDataReferrals(filterData(dataSearch, filter));
    };

    // Filter Commission
    const changeCommission = (value: number | number[]) => {
        setFilterModal((prev) => ({ ...prev, commission: changeRangeFilter(value) }));
        const dataSearch = searchData(dateReferrals, filterModal.search);
        const filter = filterModal;
        filter.commission = changeRangeFilter(value);
        setFilterDataReferrals(filterData(dataSearch, filter));
    };

    // Filter Deposits
    const changeDeposits = (value: number | number[]) => {
        setFilterModal((prev) => ({ ...prev, deposits: changeRangeFilter(value) }));
        const dataSearch = searchData(dateReferrals, filterModal.search);
        const filter = filterModal;
        filter.deposits = changeRangeFilter(value);
        setFilterDataReferrals(filterData(dataSearch, filter));
    };

    // Filter Lots
    const changeLots = (value: number | number[]) => {
        setFilterModal((prev) => ({ ...prev, lots: changeRangeFilter(value) }));
        const dataSearch = searchData(dateReferrals, filterModal.search);
        const filter = filterModal;
        filter.lots = changeRangeFilter(value);
        setFilterDataReferrals(filterData(dataSearch, filter));
    };

    // Filter Closed
    const changeClosed = (value: number | number[]) => {
        setFilterModal((prev) => ({ ...prev, closed: changeRangeFilter(value) }));
        const dataSearch = searchData(dateReferrals, filterModal.search);
        const filter = filterModal;
        filter.closed = changeRangeFilter(value);
        setFilterDataReferrals(filterData(dataSearch, filter));
    };

    // Filter State
    const changeState = (value: ReferralsTypes) => {
        setFilterModal((prev) => ({ ...prev, state: value }));
        const dataSearch = searchData(dateReferrals, filterModal.search);
        const filter = filterModal;
        filter.state = value;
        setFilterDataReferrals(filterData(dataSearch, filter));
    };

    // Filter Referrals
    const changeReferrals = (id: ReferralsTypes) => {
        const dataSearch = searchData(dateReferrals, filterModal.search);
        const filter = filterModal;
        if (filterModal.referral.includes(id)) {
            if (filterModal.referral.length === 1) {
                return;
            }
            const data = filterModal.referral.filter((i) => i !== id);
            setFilterModal((prev) => ({
                ...prev,
                referral: data,
            }));
            filter.referral = data;
            setFilterDataReferrals(filterData(dataSearch, filter));
        } else {
            if (id === ReferralsTypes.ALL) {
                setFilterModal((prev) => ({
                    ...prev,
                    referral: [id],
                }));
                filter.referral = [id];
                setFilterDataReferrals(filterData(dataSearch, filter));
            } else {
                const data = [...filterModal.referral.filter((i) => i !== ReferralsTypes.ALL), id];
                setFilterModal((prev) => ({
                    ...prev,
                    referral: data,
                }));
                filter.referral = data;
                setFilterDataReferrals(filterData(dataSearch, filter));
            }
        }
    };

    const listFilter: IFilter[] = [
        {
            text: __("common.date.yesterday"),
            id: DaterangeType.YESTERDAY,
        },
        {
            text: __("common.date.previous-week"),
            id: DaterangeType.PREVIOUS_WEEK,
        },
        {
            text: __("common.date.this-month"),
            id: DaterangeType.THIS_MONTH,
        },
        {
            text: __("common.date.previous-month"),
            id: DaterangeType.PREVIOUS_MONTH,
        },
        {
            text: __("common.date.past-quarter"),
            id: DaterangeType.PAST_QUARTER,
        },
        {
            text: __("common.date.past-year"),
            id: DaterangeType.PAST_YEAR,
        },
        {
            text: __("common.date.all-time"),
            id: DaterangeType.ALL_TIME,
        },
        {
            text: __("common.date.custom"),
            id: DaterangeType.CUSTOM,
        },
    ];

    const handlerResetFilter = () => {
        changeType(DaterangeType.ALL_TIME);
        setFilterDataReferrals(dateReferrals);
        setFilterModal({
            balance: [0, max.balance],
            commission: [0, max.commission],
            deposits: [0, max.deposits],
            lots: [0, max.lots],
            closed: [0, max.closed],
            referral: [ReferralsTypes.ALL],
            state: ReferralsTypes.ALL,
            dateType: DaterangeType.ALL_TIME,
            search: "",
        });
    };

    const handlerResult = () => {
        changeFilterModal(filterModal);
        changeDataFilter(filterDataReferrals);
        closeModal();
    };

    const referralList: IList[] = [
        {
            id: ReferralsTypes.ALL,
            text: __("modals.partnership.all"),
        },
        {
            id: ReferralsTypes.LEVEL_1,
            text: __("modals.partnership.1"),
        },
        {
            id: ReferralsTypes.LEVEL_2,
            text: __("modals.partnership.2"),
        },
        {
            id: ReferralsTypes.LEVEL_3,
            text: __("modals.partnership.3"),
        },
        {
            id: ReferralsTypes.LEVEL_4,
            text: __("modals.partnership.4"),
        },
        {
            id: ReferralsTypes.LEVEL_5,
            text: __("modals.partnership.5"),
        },
    ];

    const stateList: IList[] = [
        {
            id: ReferralsTypes.ALL,
            text: __("modals.partnership.all"),
        },
        {
            id: ReferralsTypes.VERIFIED,
            text: __("modals.partnership.verified"),
        },
        {
            id: ReferralsTypes.UNVERIFIED,
            text: __("modals.partnership.unverified"),
        },
    ];

    const rangeList: IRange[] = [
        {
            key: "balance",
            text: __("modals.partnership.balance"),
            max: max.balance,
            value: filterModal.balance,
            currency,
            change: changeBalance,
        },
        {
            key: "commission",
            text: __("modals.partnership.commission"),
            max: max.commission,
            value: filterModal.commission,
            step: 0.01,
            currency,
            change: changeCommission,
        },
        {
            key: "deposits",
            text: __("modals.partnership.deposits"),
            max: max.deposits,
            value: filterModal.deposits,
            currency,
            change: changeDeposits,
        },
        {
            key: "lots",
            text: __("modals.partnership.lots"),
            max: max.lots,
            value: filterModal.lots,
            step: 0.01,
            change: changeLots,
        },
        {
            key: "closed",
            text: __("modals.partnership.closed"),
            max: max.closed,
            value: filterModal.closed,
            currency,
            change: changeClosed,
        },
    ];

    return (
        <StyledDaterangeModals closeModal={closeModal}>
            <StyledDaterangeHead>
                <ButtonLink
                    variant={ButtonTypes.SECONDARY}
                    text={__("modals.partnership.reset-filter")}
                    size={ButtonSize.MINI}
                    onClick={handlerResetFilter}
                />
                <StyledButtonClose onClick={closeModal}>
                    <StyledButtonIcon icon={IconTypes.CLOSE} />
                </StyledButtonClose>
            </StyledDaterangeHead>
            <StyledDaterangeContent>
                <StyledFilter>
                    <StyledFilterItem>
                        <StyledText variant={TypographyTypes.BASE}>{__("modals.partnership.name")}</StyledText>
                        <StyledInputList>
                            <Input
                                type="search"
                                small
                                value={filterModal.search}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => changeSearch(event.target.value)}
                            />
                        </StyledInputList>
                    </StyledFilterItem>
                    <StyledFilterItem>
                        <StyledText variant={TypographyTypes.BASE}>{__("modals.partnership.referral-level")}</StyledText>
                        <StyledListItem>
                            {referralList.map((item) => (
                                <CheckboxElement
                                    key={item.id}
                                    id={`referral-${item.id}`}
                                    name="referral"
                                    checked={filterModal.referral.includes(item.id)}
                                    text={item.text}
                                    onChange={() => changeReferrals(item.id)}
                                />
                            ))}
                        </StyledListItem>
                    </StyledFilterItem>
                    <StyledFilterItem>
                        <StyledText variant={TypographyTypes.BASE}>{__("modals.partnership.state")}</StyledText>
                        <StyledListItem>
                            {stateList.map((item) => (
                                <CheckboxElement
                                    key={item.id}
                                    id={`state-${item.id}`}
                                    name="state"
                                    checked={filterModal.state === item.id}
                                    text={item.text}
                                    variant={VariantCheckbox.CIRCLE}
                                    onChange={() => changeState(item.id)}
                                />
                            ))}
                        </StyledListItem>
                    </StyledFilterItem>
                    {rangeList.map((item) => (
                        <StyledFilterItem key={item.key}>
                            <StyledText variant={TypographyTypes.BASE}>{item.text}</StyledText>
                            <RangeSlider
                                min={0}
                                max={item.max}
                                step={item.step}
                                value={item.value}
                                currency={item.currency}
                                onChange={item.change}
                            />
                        </StyledFilterItem>
                    ))}
                </StyledFilter>
                <StyledFilterList>
                    {
                        listFilter.map((item) => (
                            <Radio
                                key={item.id}
                                id={item.id}
                                type="radio"
                                variant={RadioTypes.PAYMENT}
                                name="filter"
                                text={item.text}
                                checked={item.id === type}
                                onChange={() => {
                                    changeType(item.id);
                                    setFilterModal((prev) => ({ ...prev, dateType: item.id }));
                                }}
                            />
                        ))
                    }
                </StyledFilterList>
                {type === DaterangeType.CUSTOM &&
                    <StyledDateCustom>
                        <StyledInputDate
                            labelText={__("modals.partnership.start-date")}
                            type="date"
                            value={dateFilter.start}
                            onChange={
                                (event: ChangeEvent<HTMLInputElement>) =>
                                    changeDate(event.target.value, dateFilter.end)
                            }
                        />
                        <StyledInputDate
                            labelText={__("modals.partnership.end-date")}
                            type="date"
                            value={dateFilter.end}
                            onChange={
                                (event: ChangeEvent<HTMLInputElement>) =>
                                    changeDate(dateFilter.start, event.target.value)
                            }
                        />
                    </StyledDateCustom>
                }
                <StyledButtonBox>
                    <ButtonLink
                        variant={ButtonTypes.PRIMARY}
                        text={`${__("modals.partnership.show-results")} (${filterDataReferrals.length})`}
                        onClick={handlerResult}
                    />
                </StyledButtonBox>
            </StyledDaterangeContent>
        </StyledDaterangeModals>
    );
};
