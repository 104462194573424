import React from "react";
import { IconTypes } from "./icon.types";

export interface IProps {
    className?: string;
    icon: IconTypes;
}

export const Icon: React.FC<IProps> = ({ className, icon }) => {
    return (
        <svg className={className}>
            <use xlinkHref={`/svg/icons-sprite.svg#${icon}`} />
        </svg>
    );
};
