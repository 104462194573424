import styled, { keyframes, css } from "styled-components";
import { PreloaderHeightType } from "./preloader-box.type";
import { media } from "lib/styled-components/variables";

interface IProps {
    $animation: boolean;
    $height: PreloaderHeightType;
}

const fadeInPreloader = keyframes`
    0% {
        transform: scale(0.7);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

const fadeOutPreloader = keyframes`
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0.7);
        opacity: 0;
    }
`;

const fadeInContent = keyframes`
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;

const fadeOutContent = keyframes`
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(-50px);
        opacity: 0;
    }
`;

export const StyledPreloaderBox = styled.div<IProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${({ $height }) => {
        switch ($height) {
            case PreloaderHeightType.DEFAULT:
                return "100svh";
            case PreloaderHeightType.HEADER:
                return "calc(100svh - 73px)";
            case PreloaderHeightType.BLOCK:
                return "auto";
        }
    }};
    ${({ $height }) => {
        switch ($height) {
            case PreloaderHeightType.BLOCK:
                return css`
                    flex-grow: 1;
                `;
        }
    }};
    animation: 0.5s ${({ $animation }) => $animation ? fadeOutPreloader : fadeInPreloader} linear forwards;
    ${media.md<IProps>`
        min-height: ${({ $height }) => {
            switch ($height) {
                case PreloaderHeightType.DEFAULT:
                    return "100svh";
                case PreloaderHeightType.HEADER:
                    return "100svh";
                case PreloaderHeightType.BLOCK:
                    return "auto";
            }
        }};
    `}
`;

export const StyledChildrenBox = styled.div<IProps>`
    display: flex;
    flex-direction: column;
    min-height: ${({ $height }) => {
        switch ($height) {
            case PreloaderHeightType.DEFAULT:
                return "100svh";
            case PreloaderHeightType.HEADER:
                return "calc(100svh - 73px)";
        }
    }};
    ${({ $height }) => {
        switch ($height) {
            case PreloaderHeightType.BLOCK:
                return css`
                    flex-grow: 1;
                `;
        }
    }};
    animation: 0.5s ${({ $animation }) => $animation ? fadeInContent : fadeOutContent} linear forwards;
    ${media.md<IProps>`
        min-height: ${({ $height }) => {
            switch ($height) {
                case PreloaderHeightType.DEFAULT:
                    return "100svh";
                case PreloaderHeightType.HEADER:
                    return "100svh";
            }
        }};
    `}
`;
