import React from "react";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { useTheme } from "components/providers/theme/theme.hooks";
import { ThemeVariant } from "components/providers/theme/theme.constants";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";
import {
    StyledDisclaimerModals,
    StyledTopBox,
    StyledContentBox,
    StyledButtonBox,
    StyledImageBox,
    StyledTitle,
    StyledText,
} from "./disclaimer.styled";

export interface IProps {
    closeModal: () => void;
}

export const DisclaimerModals: React.FC<IProps> = ({ closeModal }) => {
    const [__] = useTranslation();
    const theme = useTheme();

    return (
        <StyledDisclaimerModals
            closeModal={closeModal}
        >
            <StyledTopBox>
                <StyledTitle
                    variant={TypographyTypes.H2}
                    $dark={theme.themeVariant === ThemeVariant.Dark}
                >
                    {__("modals.verify-profile.title")}
                </StyledTitle>
                <StyledImageBox>
                    <Image
                        src="/images/verify-title.png"
                        alt={__("modals.verify-profile.title")}
                        layout="fill"
                    />
                </StyledImageBox>
            </StyledTopBox>
            <StyledContentBox>
                <StyledText variant={TypographyTypes.BASE}>
                    {__("modals.verify-profile.desc1")}
                </StyledText>
                <StyledText variant={TypographyTypes.BASE}>
                    {__("modals.verify-profile.desc2")}
                </StyledText>
            </StyledContentBox>
            <StyledButtonBox>
                <ButtonLink
                    variant={ButtonTypes.SECONDARY}
                    text={__("components.close")}
                    onClick={closeModal}
                />
            </StyledButtonBox>
        </StyledDisclaimerModals>
    );
};
