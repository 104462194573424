import React from "react";
import { useTranslation } from "next-i18next";
import { useLanguage } from "components/providers/language/language.provider";
import { TOption } from "./language-modals.types";
import { IconTypes } from "components/atoms/icon/icon.types";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import {
    StyledLanguageModals,
    StyledLanguageList,
    StyledLanguageItem,
    StyledLanguageIcon,
    StyledLanguageText,
} from "./language-modals.styled";

export interface IProps {
    closeModal: () => void;
}

export const LanguageModals: React.FC<IProps> = ({ closeModal }) => {
    const [__] = useTranslation();
    const language = useLanguage();

    const handleChooseElement = (newValue?: TOption[number]) => {
        if (newValue) {
            language?.onLocaleChange?.(newValue.value);
        }
    };

    return (
        <StyledLanguageModals
            closeModal={closeModal}
            title={__("components.choose-language")}
        >
            <StyledLanguageList>
                {language?.locales?.length ? (
                    language?.locales.map((el) => (
                        <StyledLanguageItem
                            key={el.value}
                            active={el.value === language.currentLocale}
                            onClick={() => handleChooseElement(el)}
                        >
                            <StyledLanguageText variant={TypographyTypes.BASE}>{el.text}</StyledLanguageText>
                            <StyledLanguageIcon
                                icon={IconTypes.CHECKBOX_INVERSE}
                                active={el.value === language.currentLocale}
                            />
                        </StyledLanguageItem>
                    ))
                ) : (
                    <StyledLanguageItem>
                        <StyledLanguageText variant={TypographyTypes.BASE}>{__("components.no-data")}</StyledLanguageText>
                    </StyledLanguageItem>
                )}
            </StyledLanguageList>
        </StyledLanguageModals>
    );
};
