import React, { FC, useState, useEffect } from "react";
import { StyledCopyButtonContainer, StyledCopyButton, StyledCopyIcon, StyledToolTip, StyledToolTipDesc } from "./copy-button.styled";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { copyText } from "helpers/wtn/app-copy";

export interface CopyButtonProps {
  data: string;
  onCopy?: () => void;
  onClick?: () => void;
}

const timeOut = 3000;

export const CopyButton: FC<CopyButtonProps> = ({ data, onCopy, onClick }) => {
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    const timeOutCallback = () => {
      if (!copy) { return; }
      setCopy(false);
    };
    const timeOutId = setTimeout(timeOutCallback, timeOut);
    return () => clearTimeout(timeOutId);
  }, [copy]);

  const icon = copy ? "copied" : "default";

  const onPress = async () => {
    if (!data || copy) { return; }
    if (onClick && typeof onClick === "function") { onClick(); }

    try {
      await navigator.clipboard.writeText(data);
      copyText(data);
      setCopy(true);

      if (onCopy && typeof onCopy === "function") { onCopy(); }

    } catch (error: unknown) {
      console.error(error);
      setCopy(false);
    }
  };

  return data ? (
    <StyledCopyButtonContainer>
      {copy && (
        <StyledToolTip>
          <StyledToolTipDesc variant={TypographyTypes.SMALL}>Copied</StyledToolTipDesc>
        </StyledToolTip>
      )}
      <StyledCopyButton onClick={onPress}>
        <StyledCopyIcon>
          <svg>
            <use xlinkHref={`/svg/icons-copy.svg#${icon}`} />
          </svg>
        </StyledCopyIcon>
      </StyledCopyButton>
    </StyledCopyButtonContainer>
  ) : null;
};
