export enum ReferralsTypes {
    ALL = "all",
    VERIFIED = "verified",
    UNVERIFIED = "unverified",
    LEVEL_1 = "direct",
    LEVEL_2 = "2",
    LEVEL_3 = "3",
    LEVEL_4 = "4",
    LEVEL_5 = "5",
}
