import styled, { css, keyframes } from "styled-components";
import { Icon } from "../icon/icon.component";
import { Typography } from "../typography/typography.component";
import { spacing } from "helpers/conversion-style";

const itemAnimation = keyframes`
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;

export const StyledRecentItem = styled.div<{$animation?: number}>`
    margin: 0 ${spacing(-16)};
    border-bottom: 1px solid ${({ theme }) => theme.gray?.[200]};
    opacity: ${({ $animation }) => $animation ? 0 : 1};
    ${({ $animation }) => $animation && css`
        animation: 0.3s ${itemAnimation} linear forwards;
        animation-delay: ${$animation}00ms;
    `}
`;

export const StyledRecentItemHead = styled.div`
    display: grid;
    padding: ${spacing(12)} ${spacing(16)};
    grid-template-columns: 1fr 40px;
    gap: 4px;
`;

export const StyledButton = styled.button<{view: boolean}>`
    width: 40px;
    height: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    padding: 0;
    transition: 0.3s ease-in-out;
    transform: rotate(${({ view }) => view ? 180 : 0}deg);
    cursor: pointer;
`;

export const StyledHeadTitle = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing(4)};
    gap: 4px;
`;

export const StyledTextMuted = styled(Typography)`
    color: ${({ theme }) => theme.text?.muted};
`;

export const StyledButtonIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    stroke: ${({ theme }) => theme.text?.muted};
`;

export const StyledRecentContent = styled.div<{view: boolean}>`
    transition: 0.3s ease-in-out;
    max-height: ${({ view }) => view ? 300 : 0}px;
    padding-bottom: ${({ view }) => view ? spacing(12) : 0};
    position: relative;
    overflow: hidden;
`;

export const StyledInfoItem = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 4px;
    padding: ${spacing(8)} ${spacing(16)};
`;

export const StyledInfoLine = styled.div`
    flex: 1;
    border-bottom: 1px solid  ${({ theme }) => theme.gray?.[100]};
`;
