import React, { useState, useEffect } from "react";
import Image from "next/image";
import { useModals } from "components/providers/modals/modals.provider";
import { IUser } from "types/next-auth";
import { ReferralsItem } from "components/atoms/referrals-item/referrals-item.component";
import { useTranslation } from "next-i18next";
import { useSession } from "next-auth/react";
import { StyledContainer } from "styles/basic.styled";
import { IconTypes } from "components/atoms/icon/icon.types";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { DaterangeType } from "../daterange/daterange.types";
import { SortTypes } from "../sort/sort.types";
import { IRowsReferrals } from "lib/hooks/ib/referrals";
import { SortModals, ISortList } from "../sort/sort-modals.component";
import { PartnershipFilterModals, IDataFilter } from "../partnership-filter/partnership-filter.component";
import { ReferralsTypes } from "./partnership-referrals.types";
import { useSort } from "helpers/use-sort";
import { sortDate } from "helpers/sort-data";
import { ICSVHeader, ICSVPartnership } from "../csv/csv-modal.component";
import { IData } from "lib/hooks/cashbackBreakdown";
import { PreloaderBox } from "components/organisms/preloader-box/preloader-box.compoonent";
import { PreloaderHeightType } from "components/organisms/preloader-box/preloader-box.type";
import {
    StyledSectionHead,
    StyledButton,
    StyledIcon,
    StyledTitleBox,
    StyledButtonList,
    StyledEmptyBox,
    StyledImageBox,
    StyledSectionContent,
} from "components/organisms/modals/cashback-history/cashback-history-modals.styled";
import { StyledReferralsModals } from "./partnership-referrals-modals.styled";

export interface IProps {
    closeModal: () => void;
    dataFull: IRowsReferrals[];
    currency: string;
}

export const PartnershipReferralsModals: React.FC<IProps> = ({
    closeModal,
    dataFull,
    currency,
}) => {
    const [__] = useTranslation();
    const { data: session } = useSession();
    const [user, setUser] = useState<IUser>();
    const [filterData, setFilterData] = useState<IRowsReferrals[]>(dataFull);
    const [sortData, setSortData] = useState<IRowsReferrals[]>(dataFull);
    const [isSortModals, setSortModals] = useState<boolean>(false);
    const [isDaterangeModals, setDaterangeModals] = useState<boolean>(false);
    const [filterModal, setFilterModal] = useState<IDataFilter>({
        balance: [0, 0],
        commission: [0, 0],
        deposits: [0, 0],
        lots: [0, 0],
        closed: [0, 0],
        referral: [ReferralsTypes.ALL],
        state: ReferralsTypes.ALL,
        dateType: DaterangeType.ALL_TIME,
        search: "",
    });
    const [maxRange, setMaxRange] = useState({
        balance: 0,
        commission: 0,
        deposits: 0,
        lots: 0,
        closed: 0,
    });
    const { sort, changeSort, sortLevel } = useSort();
    const { openCSVModal } = useModals();

    useEffect(() => {
        if (session && !user) {
            setUser(session?.user);
        }
    }, [session, user]);

    useEffect(() => {
        const maxSearch = (typeMax: SortTypes) =>
            sortDate(dataFull, typeMax, sortLevel)[0]?.data
                .filter((t) => t.key.includes(typeMax))[0].value;
        const max = {
            balance: Math.ceil(+maxSearch(SortTypes.BALANCE)),
            commission: Math.ceil(+maxSearch(SortTypes.COMMISSION)),
            deposits: Math.ceil(+maxSearch(SortTypes.DEPOSIT)),
            lots: Math.ceil(+maxSearch(SortTypes.TOTAL_TRADED_LOTS)),
            closed: Math.ceil(+maxSearch(SortTypes.PL_CLOSED)),
        };
        setMaxRange(max);
        setFilterModal((prev) => ({
            ...prev,
            balance: [0, max.balance],
            commission: [0, max.commission],
            deposits: [0, max.deposits],
            lots: [0, max.lots],
            closed: [0, max.closed],
        }));
    }, [dataFull]);

    useEffect(() => {
        if (!sort) {
            setSortData(sortDate(filterData, SortTypes.COMMISSION, sortLevel));
        } else {
            setSortData(sortDate(filterData, sort, sortLevel));
        }
    }, [filterData, sort, sortLevel]);

    const sortList: ISortList[] = [
        {
            id: SortTypes.BALANCE,
            text: __("modals.partnership.balance"),
        },
        {
            id: SortTypes.COMMISSION,
            text: __("modals.partnership.commission"),
        },
        {
            id: SortTypes.DEPOSIT,
            text: __("modals.partnership.deposit"),
        },
        {
            id: SortTypes.WITHDRAWALS,
            text: __("modals.partnership.withdrawals"),
        },
        {
            id: SortTypes.TOTAL_TRADED_LOTS,
            text: __("modals.partnership.total-traded-lots"),
        },
        {
            id: SortTypes.PL_CLOSED,
            text: __("modals.partnership.p&l-closed"),
        },
        {
            id: SortTypes.VERIFIED,
            text: __("modals.partnership.verified"),
        },
    ];

    const openModalCSV = () => {
        const csvDataHeader: ICSVHeader[] = [
            { label: "Level", key: "level" },
            { label: "First Name", key: "firstName" },
            { label: "Last Name", key: "lastName" },
            { label: "Balance", key: "balance" },
            { label: "Commission", key: "commission" },
            { label: "Deposits", key: "deposits" },
            { label: "Withdrawals", key: "withdrawals" },
            { label: "Total Traded Lots", key: "totalTradedLots" },
            { label: "Referrals Closed", key: "referrals_pl_closed" },
            { label: "Verified", key: "verified" },
            { label: "Country", key: "country" },
        ];
        const searchValue = (text: string, arr: IData[]) => {
            const value = arr.filter((item) => item.key.includes(text))[0]?.value;
            if (value) {
                return value;
            } else {
                return "";
            }
        };
        const csvDownload: ICSVPartnership[] = sortData.map((item) => {
            const level = searchValue("level", item.data);
            const firstName = searchValue("firstName", item.data);
            const lastName = searchValue("lastName", item.data);
            const balance = +searchValue("balance", item.data);
            const commission = +searchValue("commission", item.data);
            const deposits = +searchValue("deposits", item.data);
            const withdrawals = +searchValue("withdrawals", item.data);
            const lots = +searchValue("totalTradedLots", item.data);
            const closed = +searchValue("referrals_pl_closed", item.data);
            const verified = Boolean(+searchValue("isVerified", item.data));
            const country = searchValue("country", item.data);
            return {
                level, firstName, lastName, balance,
                commission, deposits, withdrawals,
                lots, closed, verified, country,
            };
        });
        openCSVModal(
            __("modals.partnership.partnership"),
            csvDownload,
            csvDataHeader,
        );
    };

    return (
        <StyledReferralsModals isOverlay={false}>
            <StyledSectionHead>
                <StyledContainer>
                    <StyledButton onClick={closeModal}>
                        <StyledIcon icon={IconTypes.BACK} />
                    </StyledButton>
                    <StyledTitleBox>
                        <Typography variant={TypographyTypes.H1}>{__("modals.partnership.referrals")}</Typography>
                        <StyledButtonList>
                            <StyledButton onClick={() => setDaterangeModals(true)}>
                                <StyledIcon icon={IconTypes.FILTER} />
                            </StyledButton>
                            <StyledButton onClick={() => setSortModals(true)}>
                                <StyledIcon icon={IconTypes.SORT} />
                            </StyledButton>
                            <StyledButton onClick={openModalCSV}>
                                <StyledIcon icon={IconTypes.DOTS_MENU} />
                            </StyledButton>
                        </StyledButtonList>
                    </StyledTitleBox>
                </StyledContainer>
            </StyledSectionHead>
            <StyledSectionContent>
                {
                    sortData ?
                        <StyledContainer>
                            <PreloaderBox visibility={!isSortModals} height={PreloaderHeightType.BLOCK}>
                                {sortData.map((item, index) => (
                                    <ReferralsItem
                                        key={`${item.data[1].value.replace(/ /g, "")}-${index}`}
                                        data={item.data}
                                        index={index > 10 ? index + 1 : 0}
                                        currency={currency}
                                    />
                                ))}
                            </PreloaderBox>
                        </StyledContainer>
                        :
                        <StyledEmptyBox>
                            <StyledContainer>
                                <StyledImageBox>
                                    <Image
                                        src="/svg/cashback-rewards.svg"
                                        alt={__("modals.partnership.no-cashback")}
                                        layout="fill"
                                    />
                                </StyledImageBox>
                                <Typography variant={TypographyTypes.LARGE}>
                                    {__("modals.partnership.no-cashback")}
                                </Typography>
                            </StyledContainer>
                        </StyledEmptyBox>
                }
            </StyledSectionContent>
            {isDaterangeModals &&
                <PartnershipFilterModals
                    currency={currency}
                    changeDataFilter={setFilterData}
                    closeModal={() => setDaterangeModals(false)}
                    dataFilterModal={filterModal}
                    changeFilterModal={(value: IDataFilter) => setFilterModal(value)}
                    max={maxRange}
                    token={user ? user?.accessToken : ""}
                />
            }
            {isSortModals &&
                <SortModals
                    closeModal={() => setSortModals(false)}
                    data={sortList}
                    changeSort={changeSort}
                    current={sort}
                    sortLevel={sortLevel}
                />
            }
        </StyledReferralsModals>
    );
};
