import React from "react";
import Image from "next/image";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { Typography } from "components/atoms/typography/typography.component";
import { useTranslation } from "next-i18next";
import {
    StyledCookiesModals,
    StyledCookiesContent,
    StyledCookiesImage,
    StyledCookiesLink,
} from "./cookies-modals.styled";

export interface IProps {
    closeModal: () => void;
}

export const CookiesModals: React.FC<IProps> = ({ closeModal }) => {
    const [__] = useTranslation();

    const cookiesText = __(
        "We use cookies to enhance your experience across our products, by continuing you agree to our {sep}Cookie Policy",
    ).split("{sep}");

    return (
        <StyledCookiesModals
            closeModal={closeModal}
            closeButton={__("components.got-it")}
            isOverlay={false}
        >
            <StyledCookiesContent>
                <StyledCookiesImage>
                    <Image
                        src="/images/modals/cookies.png"
                        alt="cookies"
                        layout="fill"
                    />
                </StyledCookiesImage>
                <Typography variant={TypographyTypes.SMALL}>
                    {cookiesText[0]}
                    <StyledCookiesLink
                        href="https://www.amega.finance/cookie-policy?loadIn=defaultBrowser"
                        target="_blank"
                    >
                        {cookiesText[1]}
                    </StyledCookiesLink>
                    .
                </Typography>
            </StyledCookiesContent>
        </StyledCookiesModals>
    );
};
