import styled, { keyframes } from "styled-components";

const preloaderAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const StyledPreloader = styled.svg`
    width: 96px;
    height: 96px;
    animation: 1.5s ${preloaderAnimation} linear infinite;
    margin: 0 auto;
`;
