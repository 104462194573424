import styled from "styled-components";
import { StyledTitleBox } from "../modals.styled";
import { Typography } from "components/atoms/typography/typography.component";
import { spacing } from "helpers/conversion-style";
import { media } from "lib/styled-components/variables";
import { Modals } from "../modals.component";

export const StyledConnectModals = styled(Modals)`
    max-width: 620px;
    overflow: hidden;
    overflow-y: auto;
    text-align: center;
    ${StyledTitleBox} {
        display: none;
    }
    ${media.sm`
        border-radius: 18px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        right: auto;
        padding: ${spacing(36)} ${spacing(48)};
        height: auto;
        min-height: fit-content;
    `}
    ${media.smMax`
        padding: ${spacing(32)} ${spacing(20)};
        border-radius: 24px 24px 0 0;
    `}
`;

export const StyledConnectImage = styled.div`
    width: 128px;
    height: 128px;
    position: relative;
    margin: 0 auto ${spacing(36)};
    ${media.smMax`
        width: 96px;
        height: 96px;
        margin-bottom: ${spacing(24)};
    `}
`;

export const StyledConnectTitle = styled(Typography)`
    margin-bottom: ${spacing(36)};
    ${media.smMax`
        margin-bottom: ${spacing(24)};
    `}
`;

export const StyledConnectText = styled(Typography)`
    margin-bottom: ${spacing(74)};
    ${media.smMax`
        margin-bottom: ${spacing(68)};
    `}
`;
