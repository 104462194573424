import styled from "styled-components";
import { spacing } from "helpers/conversion-style";
import { CustomLink } from "components/atoms/custom-link/custom-link.component";
import { Modals } from "../modals.component";

export const StyledCookiesModals = styled(Modals)`
    max-width: 328px;
    padding: ${spacing(12)};
    border-radius: 16px;
    background-color: ${({theme}) => theme.bg?.defaultSemiTransparent};
    border: 1px solid ${({theme}) => theme.gray?.[200]};
    filter: drop-shadow(0 2px 16px ${({theme}) => theme.popup?.default});
    backdrop-filter: blur(16px);
    bottom: 16px;
    z-index: 10;
`;

export const StyledCookiesContent = styled.div`
    display: grid;
    grid-template-columns: 30px 1fr;
    gap: 8px;
    align-items: start;
    margin-bottom: 12px;
`;

export const StyledCookiesImage = styled.div`
    width: 30px;
    height: 30px;
    position: relative;
`;

export const StyledCookiesLink = styled(CustomLink)`
    text-decoration: underline;
`;
