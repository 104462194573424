export enum VariantCheckbox {
    DEFAULT = "default",
    BIG = "big",
    CIRCLE = "circle",
    CHECK = "check",
}

export enum ColorCheckbox {
    MUTED = "muted",
    DEFAULT = "default",
}
