import React, { useRef, useEffect } from "react";
import QRCode from "qrcode";
import { useTranslation } from "next-i18next";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { useTheme } from "components/providers/theme/theme.hooks";
import { ThemeVariant } from "components/providers/theme/theme.constants";
import { CustomLink } from "components/atoms/custom-link/custom-link.component";
import {
    StyledQrLinkModals,
    StyledQrLinkContent,
    StyledQrCode,
} from "./qr-link-modals.styled";

export interface IProps {
    closeModal: () => void;
    link: string;
}

export const QrLinkModals: React.FC<IProps> = ({
    closeModal,
    link,
}) => {
    const [__] = useTranslation();
    const theme = useTheme();
    const canvasRef = useRef(null);

    useEffect(() => {
        QRCode.toCanvas(
            canvasRef.current,
            link || " ",
            (error) => error && console.error(error)
        );
    }, [link]);

    return (
        <StyledQrLinkModals
            closeModal={closeModal}
            title={__("components.expand-network")}
        >
            <StyledQrLinkContent>
                <StyledQrCode dark={theme.themeVariant === ThemeVariant.Dark}>
                    <canvas ref={canvasRef} />
                </StyledQrCode>
                <Typography variant={TypographyTypes.SMALL}>
                    <CustomLink href={link+"?loadIn=defaultBrowser"} target="__blank">{link.split("://")[1]}</CustomLink>
                </Typography>
            </StyledQrLinkContent>
        </StyledQrLinkModals>
    );
};
