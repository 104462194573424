export enum IconTypes {
    IB_ACCOUNT = "ib-account",
    CASHBACK_ACCOUNT = "cashback-account",
    INVERSE = "inverse",
    IMAGE = "image",
    LOGO = "logo",
    GLOBE = "globe",
    CLOSE = "close",
    EYE = "eye",
    CHECKBOX_INVERSE = "checkbox-inverse",
    DOTS_MENU = "dots-menu",
    HOME = "home",
    CASHBACK = "cashback",
    PARTNERSHIP = "partnership",
    HUB = "hub",
    BACK = "back",
    ARROW = "arrow",
    THEME_DEFAULT = "theme-default",
    THEME_DARK = "theme-dark",
    OK = "ok",
    COPY = "copy",
    COPY_ALT = "copy-alt",
    PLUS = "plus",
    WITHDRAW = "withdraw",
    TRANSFER = "transfer",
    TRADE = "trade",
    HISTORY = "history",
    TOTAL_BALANCE = "total-balance",
    PROFIT_LOSS = "profit-loss",
    TOTAL_EQUITY = "total-equity",
    WALLETS_MAIN = "wallets-main",
    WALLETS_PRICE = "wallets-price",
    WALLETS_IB = "wallets-ib",
    ARROW_CIRCLE = "arrow-circle",
    ANDROID = "android",
    IOS = "ios",
    EDIT = "edit",
    DATE = "date",
    UPLOAD = "upload",
    DELETE = "delete",
    DELETE_ALT = "delete-alt",
    DOWNLOAD = "download",
    INFO = "info",
    STATUS_SUCCESS = "status-success",
    STATUS_WAITING = "status-waiting",
    STATUS_ERROR = "status-error",
    STATUS_PENDING = "status-pending",
    MORE = "more",
    CLOCK = "clock",
    DOT_OUTLINE = "dot-outline",
    FILTER = "filter",
    SORT = "sort",
    CSV = "csv",
    CLOCK_INVERSE = "clock-inverse",
    COMMISSION_BREAKDOWN = "commission-breakdown",
    COMMISSION_ACCOUNTS = "commission-accounts",
    COMMISSION_CLIENTS = "commission-clients",
    DOT_VERIFIED = "dot-verified",
    EXTERNAL = "external",
    EXTERNAL_ALT = "external-alt",
    SHARE_ALT = "share-alt",
    QR_ALT = "qr-alt",
    SORT_DOWN = "sort-down",
    SEARCH = "search",
    ZERO_DEPOSIT = "zero-deposit",
    FLEXIBLE = "flexible-deposit",
    BONUS_DEPOSIT = "bonus-deposit",
    CIRCLE_GRAPHIC = "circle-graphic",
    STANDARD_ACCOUNT = "standart-account",
    DEMO_ACCOUNT = "demo-account",
    RAW_ACCOUNT = "raw-account",
    ISLAMIC_ACCOUNT = "islamic-account",
    SECURE = "secure",
    SUCCESS = "success",
    PENDING = "pending",
    ERROR = "error",
    ERROR_BUTTON = "error-button",
    TRANSFER_ARROW = "transfer-arrow",
    DEPOSIT_ARROW = "deposit-arrow",
    EMAIL_SUCCESS = "email-success",
    EMAIL_ERROR = "email-error",
    TRY_OUT_DEMO = "try-out-demo",
    COMPLETE_VERIFICATION = "complete-verification",
    ADD_MONEY = "add-money",
    PRO_TRADING_CONDITIONS = "pro-trading-conditions",
    MONEY_BAG = "money-bag",
    CUSTOMER_SUPPORT = "customer-support",
    LEVERAGE = "leverage",
    LOW_SPREADS = "low-spreads",
    UNLIMITED_CASHBACK = "unlimited-cashback",
    ACCOUNT_RAW = "account-raw",
    ACCOUNT_STANDARD = "account-standart",
    ACCOUNT_ISLAMIC = "account-islamic",
    ACCOUNT_DEMO = "account-demo",
    TRANSFER_MONEY = "transfer-money",
    CHANGE_TRADING = "change-trading",
    CHANGE_LEVERAGE = "change-leverage",
    DEMO_CONTEST = "demo-contest",
    ONE_STANDARD = "one-standart",
}

export interface IPaymentIcon {
    name: string;
    width: number;
    height: number;
}

export const IconPaymentTypes = {
    ID54: {
        name: "sticpay",
        width: 94,
        height: 20,
    },
    ID62: {
        name: "dusupay",
        width: 86,
        height: 20,
    },
    ID49: {
        name: "dusupay",
        width: 86,
        height: 20,
    },
    ID52: {
        name: "neteller",
        width: 102,
        height: 20,
    },
    ID41: {
        name: "perfectmoney",
        width: 0,
        height: 0,
    },
    ID46: {
        name: "skrill",
        width: 56,
        height: 20,
    },
} as any;
