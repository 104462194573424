import React from "react";
import { useTranslation } from "next-i18next";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { StyledContainer } from "styles/basic.styled";
import { IconTypes } from "components/atoms/icon/icon.types";
import {
    StyledCSVModals,
    StyledCSVButton,
    StyledCSVIcon,
} from "./csv-modal.styled";
import moment from "moment";

export interface ICSVCashback {
    account: number;
    symbol: string;
    source: string;
    lots: number;
    closeTime: string;
    amount: number;
    currency: string;
}

export interface ICSVPartnership {
    level: string | number;
    firstName: string;
    lastName: string;
    balance: number;
    commission: number;
    deposits: number;
    withdrawals: number;
    lots: number;
    closed: number;
    verified: boolean;
    country: string;
}

export interface ICSVHistory {
    account: string;
    amount: string;
    currency: string;
    date: string;
    status: string;
    type: string;
}

export interface ICSVHeader {
    key: string;
    label: string;
}

interface IProps {
    closeModal: () => void;
    title: string;
    data: ICSVCashback[] | ICSVPartnership[] | ICSVHistory[];
    headerData: ICSVHeader[];
}

export const CSVModals: React.FC<IProps> = ({
    closeModal,
    title,
    data,
    headerData,
}) => {
    const [__] = useTranslation();

    return (
        <StyledCSVModals closeModal={closeModal} title={title}>
            <StyledContainer>
                <StyledCSVButton
                    data={data}
                    headers={headerData}
                    filename={`export_${moment(new Date()).format("YYYY-MM-DD HH_mm_ss")}.csv`}
                >
                    <StyledCSVIcon icon={IconTypes.CSV} />
                    <Typography variant={TypographyTypes.BASE}>{__("modals.export-to-csv")}</Typography>
                </StyledCSVButton>
            </StyledContainer>
        </StyledCSVModals>
    );
};
