import React, { useState, useEffect } from "react";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { useModals } from "components/providers/modals/modals.provider";
import { StyledContainer } from "styles/basic.styled";
import { IRows, IData } from "lib/hooks/cashbackBreakdown";
import { IconTypes } from "components/atoms/icon/icon.types";
import { RecentItem } from "components/atoms/recent-item/recent-item.component";
import { cashabackFilterDate } from "helpers/cashaback-filter-date";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { DaterangeType } from "../daterange/daterange.types";
import { DaterangeModals } from "../daterange/daterange-modals.component";
import { SortTypes } from "../sort/sort.types";
import { SortModals, ISortList } from "../sort/sort-modals.component";
import { useDateFilter } from "helpers/use-date-filter";
import { useSort } from "helpers/use-sort";
import { sortDate } from "helpers/sort-data";
import { ICSVHeader, ICSVCashback } from "../csv/csv-modal.component";
import { PreloaderBox } from "components/organisms/preloader-box/preloader-box.compoonent";
import { PreloaderHeightType } from "components/organisms/preloader-box/preloader-box.type";
import {
    StyledCashbackHistoryModals,
    StyledSectionHead,
    StyledButton,
    StyledIcon,
    StyledTitleBox,
    StyledSectionContent,
    StyledEmptyBox,
    StyledImageBox,
    StyledButtonList,
} from "./cashback-history-modals.styled";

export interface IProps {
    closeModal: () => void;
    data: IRows[];
}

export const CashbackHistoryModals: React.FC<IProps> = ({
    closeModal,
    data,
}) => {
    const [__] = useTranslation();
    const [ historyData, setHistoryData ] = useState<IRows[]>(data);
    const [ sortData, setSortData ] = useState<IRows[]>(data);
    const [ isDaterangeModals, setDaterangeModals ] = useState<boolean>(false);
    const [ isSortModals, setSortModals ] = useState<boolean>(false);
    const { changeType, changeDate, type, dateFilter } = useDateFilter(DaterangeType.ALL_TIME);
    const { sort, changeSort, sortLevel } = useSort();
    const { openCSVModal } = useModals();

    useEffect(() => {
        setHistoryData(cashabackFilterDate(data, dateFilter.start, dateFilter.end));
    }, [data, type, dateFilter]);

    useEffect(() => {
        if(!sort) {
            setSortData(sortDate(historyData, SortTypes.BALANCE, sortLevel));
        } else {
            setSortData(sortDate(historyData, sort, sortLevel));
        }
    }, [historyData, sort, sortLevel]);

    const sortList: ISortList[] = [
        {
            id: SortTypes.DATE,
            text: __("cashback.date"),
        },
        {
            id: SortTypes.SYMBOL,
            text: __("cashback.symbol"),
        },
        {
            id: SortTypes.LOTS,
            text: __("cashback.lots"),
        },
        {
            id: SortTypes.AMOUNT,
            text: __("cashback.amount"),
        },
    ];

    const openModalCSV = () => {
        const csvDataHeader: ICSVHeader[] = [
            { label: "Account", key: "account" },
            { label: "Symbol", key: "symbol" },
            { label: "Source", key: "source" },
            { label: "Lots", key: "lots" },
            { label: "Close Time", key: "closeTime" },
            { label: "Amount", key: "amount" },
            { label: "Currency", key: "currency" },
        ];
        const searchValue = (text: string, arr: IData[]) => {
            const value = arr.filter((item) => item.key.includes(text))[0]?.value;
            if(value) {
                return value;
            } else {
                return "";
            }
        };
        const csvDownload: ICSVCashback[] = historyData.map((item) => {
            const account = +searchValue("_account_", item.data);
            const symbol = searchValue("_symbol_", item.data);
            const source = searchValue("_ticket_", item.data);
            const lots = +searchValue("_lots_", item.data);
            const closeTime = searchValue("_closetime_", item.data);
            const amount = +searchValue("_amount_", item.data);
            const currency = searchValue("_currency_", item.data);
            return {account,symbol,source,lots,closeTime,amount,currency};
        });
        openCSVModal(
            __("cashback.page-title"),
            csvDownload,
            csvDataHeader,
        );
    };

    return (
        <StyledCashbackHistoryModals isOverlay={false}>
            <StyledSectionHead>
                <StyledContainer>
                    <StyledButton onClick={closeModal}>
                        <StyledIcon icon={IconTypes.BACK} />
                    </StyledButton>
                    <StyledTitleBox>
                        <Typography variant={TypographyTypes.H1}>{__("cashback.history")}</Typography>
                        <StyledButtonList>
                            <StyledButton onClick={() => setDaterangeModals(true)}>
                                <StyledIcon icon={IconTypes.FILTER}/>
                            </StyledButton>
                            <StyledButton onClick={() => setSortModals(true)}>
                                <StyledIcon icon={IconTypes.SORT} />
                            </StyledButton>
                            <StyledButton onClick={openModalCSV}>
                                <StyledIcon icon={IconTypes.DOTS_MENU} />
                            </StyledButton>
                        </StyledButtonList>
                    </StyledTitleBox>
                </StyledContainer>
            </StyledSectionHead>
            <StyledSectionContent>
            {
                sortData.length > 0 ?
                    <StyledContainer>
                        <PreloaderBox visibility={!isSortModals} height={PreloaderHeightType.BLOCK}>
                            {sortData.map((item, index) => (
                                <RecentItem
                                    key={item.data[0].value}
                                    data={item.data}
                                    index={index+1}
                                />
                            ))}
                        </PreloaderBox>
                    </StyledContainer>
                    :
                    <StyledEmptyBox>
                        <StyledContainer>
                            <StyledImageBox>
                                <Image
                                    src="/svg/separate/payment-details.svg"
                                    alt={__("cashback.no-rewards")}
                                    layout="fill"
                                />
                            </StyledImageBox>
                            <Typography variant={TypographyTypes.LARGE}>{__("cashback.no-rewards")}</Typography>
                        </StyledContainer>
                    </StyledEmptyBox>
            }
            </StyledSectionContent>
            {isDaterangeModals &&
                <DaterangeModals
                    type={type}
                    dateFilter={dateFilter}
                    changeDate={changeDate}
                    changeType={changeType}
                    closeModal={() => setDaterangeModals(false)}
                />
            }
            {isSortModals &&
                <SortModals
                    closeModal={() => setSortModals(false)}
                    data={sortList}
                    changeSort={changeSort}
                    current={sort}
                    sortLevel={sortLevel}
                />
            }
        </StyledCashbackHistoryModals>
    );
};
