import React from "react";
import Image from "next/image";
import { useTheme } from "components/providers/theme/theme.hooks";
import { ThemeVariant } from "components/providers/theme/theme.constants";
import { useTranslation } from "next-i18next";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";
import {
    StyledPartnershipInfoModals,
    StyledModalsTitleBox,
    StyledModalsTitle,
    StyledModalsContentBox,
    StyledButtonBox,
    StyledTextItemBox,
    StyledList,
    StyledTitleImage,
} from "./partnership-info-modals.styled";

export interface IProps {
    closeModal: () => void;
}

export const PartnershipInfoModals: React.FC<IProps> = ({
    closeModal,
}) => {
    const [__] = useTranslation();
    const theme = useTheme();

    const textList = [
        {
            key: "affiliate_partnership",
            text: [
                __("modals.partnership.affiliate-partnership"),
            ],
        },
        {
            key: "institutional_partnership",
            text: [
                __("modals.partnership.institutional-partnership"),
            ],
        },
        {
            key: "strategic_alliances",
            text: [
                __("modals.partnership.strategic-alliances"),
            ],
            list: [
                __("modals.partnership.strategic-alliances-1"),
                __("modals.partnership.strategic-alliances-2"),
                __("modals.partnership.strategic-alliances-3"),
            ],
        },
    ];

    return (
        <StyledPartnershipInfoModals closeModal={closeModal}>
            <StyledModalsTitleBox>
                <StyledTitleImage>
                    <Image
                        src="/images/partnership-title.png"
                        alt="Partnership background image"
                        layout="fill"
                    />
                </StyledTitleImage>
                <StyledModalsTitle
                    $dark={theme.themeVariant === ThemeVariant.Dark}
                    variant={TypographyTypes.H2}
                >
                    {__("modals.partnership.about-partnership")}
                </StyledModalsTitle>
            </StyledModalsTitleBox>
            <StyledModalsContentBox>
                {textList.map((item) => (
                    <div key={item.key}>
                        <StyledTextItemBox $indentation={Boolean(!item.list)}>
                            {item.text.map((text, index) =>
                                <Typography
                                    key={`text-${index}`}
                                    variant={TypographyTypes.BASE}
                                >
                                    {text}
                                </Typography>
                            )}
                            {item.list &&
                                <StyledList>
                                    {
                                        item.list.map((li, index) => (
                                            <li key={`list-${index}`}>
                                                <Typography variant={TypographyTypes.SMALL}>
                                                    {li}
                                                </Typography>
                                            </li>
                                        ))
                                    }
                                </StyledList>
                            }
                        </StyledTextItemBox>
                    </div>
                ))}
                <StyledButtonBox>
                    <ButtonLink
                        text={__("components.close")}
                        variant={ButtonTypes.SECONDARY}
                        onClick={closeModal}
                    />
                </StyledButtonBox>
            </StyledModalsContentBox>
        </StyledPartnershipInfoModals>
    );
};
