import { statusTokenSignOut } from "helpers/statusTokenSignOut";
import { logRequest, logResponse, logError } from "helpers/loggerData";

export interface IData {
    key: string;
    value: string;
}

export interface IRowsReferrals {
    data: IData[];
}

export interface IReferals {
    canExportToCsv: boolean;
    canFilter: boolean;
    columns: any[];
    rows: IRowsReferrals[],
    totals: any[];
}

export const ibReferrals = (
    token: string,
    offset: number,
    from: string,
    to: string,
    limit?: number,
): Promise<IReferals> => {
    const logData = {
        desc: "IB Referrals",
        method: "POST",
        url: `${process.env.NEXT_PUBLIC_API}/client-api/ib/referrals`,
    };
    const { url, method } = logData;

    const body = {
        from,
        to,
        currency: "USD",
        tableConfig: {
            filters: [
                {
                    field: "string",
                    modificator: "Equals",
                    value: "string",
                },
            ],
            segment: {
                limit: limit ? limit :500,
                offset,
            },
            sorting: {
                field: "string",
                direction: "DESC",
            },
            csv: false,
            withTotals: false,
        },
    };

    logRequest(logData, body);

    return fetch(url, {
      method,
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
    .then((response) => {
      logResponse(logData, response);

      void statusTokenSignOut(response.status);

      return response.json();
    })
    .then((json) => json)
    .catch((error: unknown) => {
      console.error(error);
      logError(logData, error);
    });
};

export const ibReferralsAccounts = (
    token: string,
    offset: number,
): Promise<IReferals> => {
    const logData = {
        desc: "IB Referrals Accounts",
        method: "POST",
        url: `${process.env.NEXT_PUBLIC_API}/client-api/ib/referrals/accounts`,
    };
    const { url, method } = logData;

    const body = {
        referralId: "0",
        tableConfig: {
            filters: [
                {
                    field: "string",
                    modificator: "Equals",
                    value: "string",
                },
            ],
            segment: {
                limit: 500,
                offset,
            },
            sorting: {
                field: "string",
                direction: "DESC",
            },
            csv: false,
            withTotals: false,
        },
    };

    logRequest(logData, body);

    return fetch(url, {
      method,
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
    .then((response) => {
      logResponse(logData, response);

      void statusTokenSignOut(response.status);

      return response.json();
    })
    .then((json) => json)
    .catch((error: unknown) => {
      console.error(error);
      logError(logData, error);
    });
};
