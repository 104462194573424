import React from "react";
import { IconTypes } from "components/atoms/icon/icon.types";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import {
    StyledTitleBox,
    StyledTitleContainer,
    StyledTitleButton,
    StyledTitleLink,
    StyledButtonIcon,
} from "./title-box.styled";

interface IProps {
    title: string;
    onBack?: () => void;
    href?: string;
    type: "link" | "button";
}

export const TitleBox: React.FC<IProps> = ({ title, onBack, href, type }) => {
    return (
        <StyledTitleBox>
            <StyledTitleContainer>
                <Typography variant={TypographyTypes.H4}>{title}</Typography>
                {type === "link"
                    ?
                        <StyledTitleLink href={href ? href : ""}>
                            <StyledButtonIcon icon={IconTypes.CLOSE} />
                        </StyledTitleLink>
                    :
                        <StyledTitleButton onClick={onBack}>
                            <StyledButtonIcon icon={IconTypes.CLOSE} />
                        </StyledTitleButton>
                }
            </StyledTitleContainer>
        </StyledTitleBox>
    );
};
