import styled, { css } from "styled-components";
import { spacing } from "helpers/conversion-style";
import { Modals } from "../modals.component";

export const StyledQrLinkModals = styled(Modals)`
    max-width: 550px;
    &:before {
        content: "";
        width: 90px;
        height: 5px;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        background-color: ${({ theme }) => theme.gray?.["100"]};
        position: absolute;
        z-index: 2;
        border-radius: 6px;
    }
`;

export const StyledQrCode = styled.div<{dark: boolean;}>`
    padding: ${spacing(16)};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.gray?.["200"]};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    position: relative;
    margin: 0 auto ${spacing(12)};
    ${({dark}) => dark && css`
        background-color: ${({theme}) => theme.text?.default};
    `}
`;

export const StyledQrLinkContent = styled.div`
    padding: ${spacing(12)} ${spacing(16)} ${spacing(24)};
    text-align: center;
    color: ${({ theme }) => theme.text?.muted};
`;
