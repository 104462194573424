import { useState, useEffect } from "react";

export interface IDevice {
    appId: string;
    appVersion: string | number;
    appVersionCode: string;
    installationId: string;
    language: string;
    model: string;
    os: string;
    osVersion: string;
    platform: string;
    timeZone: string;
}

export const useCheckDevice = () => {
    const [ device, setDevice ] = useState<IDevice | undefined>();
    const [ mobile, setMobile ] = useState<boolean | undefined>();

    useEffect(() => {
        if(mobile === undefined) {
            window.WTN?.deviceInfo().then((data: any) => {
                if(data) {
                    setMobile(true);
                    setDevice(data);
                } else {
                    setMobile(false);
                }
            }).catch(()=> {
                setMobile(false);
            });
        }
    }, [mobile]);

    return { device, mobile };
};
