import pino, { levels, LoggerOptions } from "pino";
import { getGuids, getLocation, mergeLogEvent } from "./transport.utils";
type TransmitType = NonNullable<
    NonNullable<LoggerOptions["browser"]>["transmit"]
>;

export interface TrasportOptions {
    level?: pino.Level | number;
    enabled?: boolean;
}

export abstract class AbstractTransport implements TransmitType {
    private static TRANSMIT_ONLY_BINDINGS: Record<string, unknown>;

    readonly level: string;
    get levelValue() {
        return levels.values[this.level] || 0;
    }

    readonly enabled?: boolean;

    constructor({ level = "warn", enabled = true }: TrasportOptions) {
        this.level =
            typeof level === "number" ? levels.labels[level] || "warn" : level;

        this.enabled = enabled;
    }

    send = (level: pino.Level, logEvent: pino.LogEvent) => {
        if (typeof window === "undefined" || !this.enabled) {
            return;
        }
        try {
            if (!AbstractTransport.TRANSMIT_ONLY_BINDINGS) {
                AbstractTransport.TRANSMIT_ONLY_BINDINGS = {
                    COMMIT_SHORT_SHA: process.env.REVISION,
                    GUIDS: getGuids(),
                };
            }

            const mergedLogEvent = mergeLogEvent(logEvent, {
                ...AbstractTransport.TRANSMIT_ONLY_BINDINGS,
                location: getLocation(),
            });

            this.sendMerged(mergedLogEvent, level);
        } catch (ex) {
            console.error(ex);
        }
    };

    abstract sendMerged(
        mergedLogEvent: Record<string, unknown>,
        level: pino.Level,
    ): void;
}
