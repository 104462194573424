import React from "react";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { ButtonIconTypes } from "./button-icon.types";
import {
    StyledButtonIcon,
    StyledIcon,
} from "./button-icon.styled";

export interface IProps {
    text: string;
    icon: ButtonIconTypes;
    onClick: () => void;
    hide?: boolean;
}

export const ButtonIcon: React.FC<IProps> = ({ text, icon, onClick, hide }) => {
    return (
        <StyledButtonIcon onClick={onClick} data-testid="button-icon" $hide={hide}>
            <StyledIcon>
                <use xlinkHref={`/svg/button-sprite.svg#${icon}`} />
            </StyledIcon>
            <Typography variant={TypographyTypes.BASE}>{text}</Typography>
        </StyledButtonIcon>
    );
};
