import Cookies from "universal-cookie";
import { AbstractTransport, TrasportOptions } from "../transport.class";

export interface HttpTransportOptions extends TrasportOptions {
    url: string | undefined;
}

export class HttpTransport extends AbstractTransport {
    readonly url: string | undefined;

    constructor({ url, ...props }: HttpTransportOptions) {
        super({ ...props, enabled: props.enabled && !!url });
        this.url = url;
    }

    sendMerged(logEvent: Record<string, unknown>): void {
        if (this.url) {
            void fetch(this.url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Xsrf-Token": new Cookies().get("XSRF-TOKEN", {
                        doNotParse: true,
                    }),
                },
                body: JSON.stringify(logEvent),
            }).catch((reason) => {
                console.error(reason);
            });
        }
    }
}
