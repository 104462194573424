import { IRows } from "lib/hooks/cashbackBreakdown";

export const cashabackFilterDate = (
    data: IRows[],
    start: string,
    end: string,
) => {
    if(data?.length === 0) {
        return [];
    }
    const dataFilter = data.filter((item) => {
        const time = item.data.filter((t) => t.key.includes("_closetime_"))[0].value?.split(" ")[0];
        if(+new Date(start) <= +new Date(time) && +new Date(end) >= +new Date(time)) {
            return item;
        }
    });
    return dataFilter;
};
