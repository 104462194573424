import React from "react";
import { useTranslation } from "next-i18next";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { ButtonTypes } from "components/atoms/button-link/button-link.types";
import {
    StyledReferralsLevelModals,
    StyledReferralsLevelContent,
} from "./referrals-level-modals.styled";

export interface IProps {
    closeModal: () => void;
}

export const ReferralsLevelModals: React.FC<IProps> = ({
    closeModal,
}) => {
    const [__] = useTranslation();

    return (
        <StyledReferralsLevelModals
            closeModal={closeModal}
        >
            <Typography variant={TypographyTypes.H2}>{__("modals.referral.title")}</Typography>
            <StyledReferralsLevelContent>
                <Typography variant={TypographyTypes.BASE}>{__("modals.referral.desc1")}</Typography>
                <Typography variant={TypographyTypes.BASE}>{__("modals.referral.desc2")}</Typography>
            </StyledReferralsLevelContent>
            <ButtonLink
                variant={ButtonTypes.SECONDARY}
                text={__("components.close")}
                onClick={closeModal}
            />
        </StyledReferralsLevelModals>
    );
};
