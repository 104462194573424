import {
    ServerStyleSheet,
    StyleSheetManager,
    StylisPlugin,
    ThemeProvider as StyledThemeProvider,
} from "styled-components";
import rtlPlugin from "stylis-plugin-rtl";
import { useDir } from "../i18next/hooks/use-dir.hook";
import { ThemePropsType } from "../theme/theme.constants";
import { useTheme } from "../theme/theme.hooks";
import { GlobalStyled } from "./global.styled";

declare module "styled-components" {
    export interface DefaultTheme extends ThemePropsType { }
}

const StyledProvider: React.FC<
    React.PropsWithChildren<{
        sheet: ServerStyleSheet | undefined;
    }>
> = ({ sheet, children }) => {
    const { themeProps } = useTheme();
    const dir = useDir();

    return (
        <StyleSheetManager
            sheet={sheet}
            stylisPlugins={
                dir === "rtl"
                    ? [rtlPlugin as unknown as StylisPlugin]
                    : undefined
            }
        >
            <StyledThemeProvider theme={themeProps}>
                <>
                    <GlobalStyled />
                    {children}
                </>
            </StyledThemeProvider>
        </StyleSheetManager>
    );
};

export default StyledProvider;
