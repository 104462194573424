import { createGlobalStyle, css } from "styled-components";
import { typographyStyle } from "lib/tokens/ui.styled";
import { fluidType } from "helpers/conversion-style";
import styledNormalize from "styled-normalize";

const globalStylesCss = css`
    ${styledNormalize};
    html,
    body,
    #root,
    #__next {
        min-height: 100%;
        height: 100%;
        font-family: ${typographyStyle.fontFamilies.headings.value}, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        line-height: ${typographyStyle.lineHeights.body.value};
        color: ${({theme}) => theme.text?.default};
        background-color: ${({theme}) => theme.bg?.page};
        ${fluidType(
            +typographyStyle.fontSizes.mobile.base.value,
            +typographyStyle.fontSizes.desktop.base.value,
        )};
    }
    #__next {
      display: flex;
      flex-direction: column;
    }
    a {
        color: inherit;
        text-decoration: none;
    }

    a:not([href]) {
        cursor: pointer;
    }

    * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        outline: none;
    }

    .pay-modal {
        color: #1B1F24;
    }
`;
export const GlobalStyled = createGlobalStyle`${globalStylesCss};` as any;
