import styled, { css } from "styled-components";
import { StyledTitleBox } from "../modals.styled";
import { spacing } from "helpers/conversion-style";
import { Modals } from "../modals.component";
import { IconTypes } from "components/atoms/icon/icon.types";
import { Icon } from "components/atoms/icon/icon.component";
import { Typography } from "components/atoms/typography/typography.component";

export const StyledInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing(16)};
  padding-bottom: ${spacing(8)};
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.text?.default};
`;

export const StyledDesc = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.text?.default};
`;

export const StyledMenuBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 ${spacing(16)};
`;

export const StyledButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing(32)} ${spacing(16)} ${spacing(16)};
`;

export const StyledInfoBox = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-grow: 0;
  
  & > div {
    flex-grow: 1;
  }
`;

export const StyledMenuModal = styled(Modals)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 360px;
    overflow: hidden;
    max-height: calc(100% - 16px);

    ${StyledTitleBox} {
        display: none;
    }
`;

export const StyledTopBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${spacing(16)};
    padding: ${spacing(48)} ${spacing(16)} ${spacing(24)};

    &:before {
      content: "";
      width: 90px;
      height: 5px;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${({ theme }) => theme.gray?.[100]};
      position: absolute;
      z-index: 2;
      border-radius: ${spacing(6)};
    }
`;

export const StyledIconBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing(16)};
`;

export const StyledAccountIcon = styled(Icon)<{$type: IconTypes}>`
  width: ${spacing(48)};
  min-width: ${spacing(48)};
  border-radius: 50%;
  height: ${spacing(48)};
  ${({ $type }) => {
      switch ($type) {
          case IconTypes.ONE_STANDARD:
                return css`
                    fill: #D0A11B;
                    color: #FEF7E1;
                `;
          case IconTypes.TRY_OUT_DEMO:
              return css`
                  fill: ${({theme}) => theme.gray?.[800]};
                  color: ${({theme}) => theme.bg.page};
              `;
          case IconTypes.ACCOUNT_STANDARD:
              return css`
                  fill: ${({theme}) => theme.purple?.[800]};
                  color: ${({theme}) => theme.purple?.[100]};
              `;
          case IconTypes.ACCOUNT_RAW:
              return css`
                  fill: ${({theme}) => theme.red?.[800]};
                  color: ${({theme}) => theme.red?.[200]};
              `;
          case IconTypes.ACCOUNT_ISLAMIC:
              return css`
                  fill: ${({theme}) => theme.green?.[800]};
                  color: ${({theme}) => theme.green?.[100]};
              `;
      }
  }};
`;

export const StyledBonuxBox = styled.div`
    display: flex;
    align-items: center;
    gap: ${spacing(8)};
`;

export const StyledButtonClose = styled.button`
  position: absolute;
  top: ${spacing(16)};
  right: ${spacing(16)};
  border: none;
  width: ${spacing(48)};
  height: ${spacing(48)};
  background-color: ${({ theme }) => theme.gray?.[100]};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonIcon = styled(Icon)`
  width: ${spacing(24)};
  min-width: ${spacing(24)};
  height: ${spacing(24)};
  stroke: ${({ theme }) => theme.text?.default};
`;
