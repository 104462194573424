import styled, { css } from "styled-components";
import { StyledTitleBox } from "../modals.styled";
import { spacing } from "helpers/conversion-style";
import { Modals } from "../modals.component";
import { IconTypes } from "components/atoms/icon/icon.types";
import { Icon } from "components/atoms/icon/icon.component";

export const StyledMenuModal = styled(Modals)`
    max-width: 360px;
    overflow: hidden;
    ${StyledTitleBox} {
        display: none;
    }
`;

export const StyledTopBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${spacing(16)};
    padding: ${spacing(16)} ${spacing(16)} ${spacing(32)};

    &:before {
      content: "";
      width: 90px;
      height: 5px;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${({ theme }) => theme.gray?.[100]};
      position: absolute;
      z-index: 2;
      border-radius: ${spacing(6)};
    }
`;

export const StyledIconBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing(8)};
`;

export const StyledAccountIcon = styled(Icon)<{$type: IconTypes}>`
  width: ${spacing(48)};
  min-width: ${spacing(48)};
  height: ${spacing(48)};
  border-radius: 50%;
  ${({ $type }) => {
      switch ($type) {
          case IconTypes.TRY_OUT_DEMO:
              return css`
                  fill: ${({theme}) => theme.gray?.[800]};
                  color: ${({theme}) => theme.bg.page};
              `;
          case IconTypes.ACCOUNT_STANDARD:
              return css`
                  fill: ${({theme}) => theme.purple?.[800]};
                  color: ${({theme}) => theme.purple?.[100]};
              `;
          case IconTypes.ACCOUNT_RAW:
              return css`
                  fill: ${({theme}) => theme.red?.[800]};
                  color: ${({theme}) => theme.red?.[200]};
              `;
          case IconTypes.ACCOUNT_ISLAMIC:
              return css`
                  fill: ${({theme}) => theme.green?.[800]};
                  color: ${({theme}) => theme.green?.[100]};
              `;
      }
  }};
`;

export const StyledButtonClose = styled.button`
  border: none;
  width: ${spacing(48)};
  height: ${spacing(48)};
  background-color: ${({ theme }) => theme.gray?.[100]};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonIcon = styled(Icon)`
  width: ${spacing(24)};
  min-width: ${spacing(24)};
  height: ${spacing(24)};
  stroke: ${({ theme }) => theme.text?.default};
`;

export const StyledMenuBox = styled.div`
  padding-bottom: ${spacing(16)};
`;

export const StyledMenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(16)};
  height: ${spacing(48)};
`;

export const StyledMenuLink = styled.a`
  display: flex;
  padding: ${spacing(8)} ${spacing(16)};
  flex-grow: 1;
  align-items: center;
  gap: ${spacing(16)};
  height: ${spacing(48)};
`;

export const StyledMenuIcon = styled(Icon)`
  display: flex;
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.text?.muted};
`;
