import pino from "pino";
import { MultiTransport } from "./transport/multi/multi.transport";
import { HttpTransport } from "./transport/http/http.transport";
import { LogstashTransport } from "./transport/logstash/logstash.transport";

export const logger = pino({
    level: (() => {
        if (process.env.APP_ENV === "dev" && typeof window !== "undefined") {
            return "trace";
        }
        return "warn";
    })(),
    browser: {
        transmit: new MultiTransport(
            new HttpTransport({
                url: `${process.env.NEXT_PUBLIC_API_PREFIX}${process.env.NEXT_PUBLIC_CONTROLLER_PROXY_LOGGER}`,
                enabled:
                    process.env.NEXT_PUBLIC_PROXY_LOGGER_ENABLED === "true",
            }),
            new LogstashTransport({
                url: process.env.NEXT_PUBLIC_LOGSTASH_URL,
                enabled:
                    process.env.NEXT_PUBLIC_LOGSTASH_LOGGER_ENABLED === "true",
            }),
        ),
    },
});
