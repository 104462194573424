import React from "react";
import { useTranslation } from "next-i18next";
import { RadioTypes } from "components/atoms/form/radio/radio.types";
import { Radio } from "components/atoms/form/radio/radio.component";
import { IconTypes } from "components/atoms/icon/icon.types";
import { SortTypes, SortLevelTypes } from "./sort.types";
import {
    StyledSortModals,
} from "./sort-modals.styled";

export interface ISortList {
    id: SortTypes,
    text: string,
}

interface IProps {
    closeModal: () => void;
    data: ISortList[];
    changeSort: (id: SortTypes) => void;
    sortLevel: SortLevelTypes;
    current?: SortTypes;
}

export const SortModals: React.FC<IProps> = ({
    closeModal,
    data,
    changeSort,
    current,
    sortLevel,
}) => {
    const [__] = useTranslation();

    return (
        <StyledSortModals closeModal={closeModal} title={__("transactions.sort-by")}>
            {
                data.map((item) => (
                    <Radio
                        key={item.id}
                        id={item.id}
                        type="radio"
                        variant={RadioTypes.BUTTON}
                        name="sort"
                        text={item.text}
                        checked={item.id === current}
                        onClick={() => changeSort(item.id)}
                        onChange={() => changeSort(item.id)}
                        icon={IconTypes.SORT_DOWN}
                        level={sortLevel}
                    />
                ))
            }
        </StyledSortModals>
    );
};
